import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Text from 'UIKit/Text'
import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import { InhouseGameType } from 'config/types/game'
import { InhouseGameProbilityFairBetInfo } from 'views/InhouseGame/config/types'
import PlinkoGameContent from './PlinkoGameContent'
import LimboGameContent from './LimboGameContent'
import DiceGameContent from './DiceGameContent'
import WheelGameContent from './WheelGameContent'
import MineGameContent from './MineGameContent'

const ProbilityFairSession: React.FC<{
  inhouseGameType: InhouseGameType
  inhouseBetId: number
  betDetails?: InhouseGameProbilityFairBetInfo
}> = ({ inhouseGameType, inhouseBetId, betDetails }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleContent = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <Box>
      <Flex onClick={toggleContent} alignItems="center" justifyContent="space-between" style={{ cursor: 'pointer' }}>
        <Text color="textSubtle" fontSize="1em" fontWeight={600}>
          <Trans>Provable Fairness</Trans>
        </Text>
        <StyledExpansitionIconContainer $Open={isExpanded}>
          <Icons.ExpandIcon fill={colors.textSubtle} />
        </StyledExpansitionIconContainer>
      </Flex>

      {isExpanded && (
        <Box>
          {inhouseGameType === InhouseGameType.Plinko && (
            <PlinkoGameContent inhouseBetId={inhouseBetId} betDetails={betDetails as any} />
          )}
          {inhouseGameType === InhouseGameType.Limbo && (
            <LimboGameContent inhouseBetId={inhouseBetId} betDetails={betDetails as any} />
          )}
          {inhouseGameType === InhouseGameType.Dice && (
            <DiceGameContent inhouseBetId={inhouseBetId} betDetails={betDetails as any} />
          )}
          {inhouseGameType === InhouseGameType.Wheel && (
            <WheelGameContent inhouseBetId={inhouseBetId} betDetails={betDetails as any} />
          )}
          {inhouseGameType === InhouseGameType.Mine && (
            <MineGameContent inhouseBetId={inhouseBetId} betDetails={betDetails as any} />
          )}
        </Box>
      )}
    </Box>
  )
}

const StyledExpansitionIconContainer = styled.div<{ $Open: boolean }>`
  transform: ${({ $Open }) => (!$Open ? 'rotate(180deg)' : '')};
`

export default ProbilityFairSession
