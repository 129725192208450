import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import FormControl from 'UIKit/FormControl'
import FormInput from 'UIKit/FormControl/FormInput'
import { StyledControlContainer } from 'UIKit/FormControl/styled'
import { InputLabel, InputMessage, StyledInput } from 'UIKit/Input/styled'
import Link from 'UIKit/Link'
import { NextLinkFromReactRouter } from 'UIKit/NextLink'
import OpenEffect from 'UIKit/OpenEffect'
import Text from 'UIKit/Text'
import TokenInput from 'UIKit/TokenInput'
import BigNumber from 'bignumber.js'
import FormValidator from 'config/constants/formValidator'
import { NETWORK_MAP } from 'config/constants/network'
import { BIG_ZERO } from 'config/constants/number'
import { PaymentMethodEnum } from 'config/constants/payment'
import { RouteConfig } from 'config/constants/route'
import { HUSD_TOKEN } from 'config/constants/tokens'
import { PaymentMethod, Token } from 'config/types'
import { ValidationError } from 'config/types/validator'
import useForm from 'hooks/useForm'
import { RowMiddle } from 'layout/Components/Row'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'state'
import { useListNetworks, useTokenPaymentMethod, useValidateWithdrawToken } from 'state/app/hooks'
import { useAuthBy, useUserInfo } from 'state/auth/hooks'
import { useAllWageringBonusesHaveAmount } from 'state/bonus/hooks'
import { useTokenAppBalance } from 'state/profile/hooks'
import { useTokenSelectedExcludeFiat } from 'state/session/hooks'
import { addPendingWithdrawTransaction } from 'state/transaction/action'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import { getBalanceTokenKey } from 'utils'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { isTonChain, isXrpChain } from 'utils/network'
import { checkTokenEqual, getNetworkType, validateUserAddressByNetwokType } from 'utils/token'
import GameInfoMessage from 'views/GameDetails/GameInfoMessage'

import HUSDInfo from '../../HUSDInfo'
import PaymentTokenSelect from '../../PaymentTokenSelect'
import TraditionalWithdrawButton from './TraditionalWithdrawButton'
import WalletWithdrawButton from './WalletWithdrawButton'
import { useWithdrawAddressNotAllowed, useWithdrawInfo } from './hooks'

const WithdrawInputErrorMessages = {
  receiveAddress: {
    [ValidationError.Required]: 'Address is required',
    [ValidationError.WrongAddressFormat]: 'Your address is invalid',
    [ValidationError.Equal]:
      'Withdrawals to deposit wallet addresses are not allowed. Please enter a different address.',
  },
  withdrawValue: {
    [ValidationError.Insufficient]: 'Insufficient balance',
    [ValidationError.LessThan]: 'Amount must be greater than minimum withdraw amount',
  },
}

interface WithdrawModalProps {
  onDismiss: () => void
  initialToken?: Token
}

const Withdraw: React.FC<WithdrawModalProps> = ({ onDismiss, initialToken }) => {
  const dispatch = useAppDispatch()

  const { username } = useUserInfo()
  const { isAuthByEmail, isAuthByTelegram, isAuthByWallet } = useAuthBy()

  const { wallet } = useAppSelector((state) => state.auth)

  const { t } = useTranslation()

  const playToken = useTokenSelectedExcludeFiat()
  const networks = useListNetworks()
  const [selectedToken, setSelectedToken] = useState<Token>(initialToken || playToken)
  const { addressNotAllowed, isFetching } = useWithdrawAddressNotAllowed(selectedToken)

  const method = useTokenPaymentMethod(selectedToken)

  const isValidWithdrawToOwnerAddress = useMemo(() => {
    if (method === PaymentMethod.TransferToken) return false
    if (wallet && wallet.address) {
      const networkType = getNetworkType(selectedToken.network)
      return validateUserAddressByNetwokType(wallet.address, networkType)
    }

    return false
  }, [selectedToken])

  const { withdrawFee, lockAmount, selectedTokenMinWithdrawAmount = 0 } = useWithdrawInfo(selectedToken)

  const totalMinWithdrawAmount = withdrawFee.plus(selectedTokenMinWithdrawAmount)

  const tokenBalances = useAppSelector((state) => state.profile.balances)
  const selectTokenAmount = useTokenAppBalance(selectedToken)
  const bonuses = useAllWageringBonusesHaveAmount()
  const bonusAmount = useMemo(() => {
    if (selectedToken === HUSD_TOKEN) return BIG_ZERO
    return bonuses
      .filter((bonus) => checkTokenEqual(bonus.bonusAmount.token, selectedToken))
      .reduce((result, value) => result.plus(value.bonusAmount.amount), new BigNumber(0))
  }, [bonuses, selectedToken])

  const withdrawalAmount = useMemo(() => selectTokenAmount?.amount?.minus(lockAmount), [selectTokenAmount, lockAmount])

  const { states, controls, validateAll, isValid, validate } = useForm({
    value: {
      validators: [FormValidator.lt(totalMinWithdrawAmount), FormValidator.max(withdrawalAmount)],
      value: '',
    },
    memo: {
      validators: [],
      value: '',
    },
    receiveAddress: {
      validators: isValidWithdrawToOwnerAddress
        ? [FormValidator.required]
        : [
            FormValidator.required,
            FormValidator.address(selectedToken.network),
            FormValidator.notEqual(addressNotAllowed),
          ],
      value: !isValidWithdrawToOwnerAddress ? '' : wallet?.address || username,
    },
  })

  useEffect(() => {
    if (isValidWithdrawToOwnerAddress) {
      controls.receiveAddress.clear()
      controls.receiveAddress.onValueChanged(wallet?.address || username)
    } else {
      controls.receiveAddress.clear()
    }
  }, [isValidWithdrawToOwnerAddress, getNetworkType(selectedToken.network)])

  useEffect(() => {
    if (states.value.isDirty && !Number.isNaN(withdrawFee.toNumber())) {
      validate('value')
    }
  }, [withdrawFee])

  const fetchTokenBalanceFn = useCallback(
    async (token: Token) => {
      return Promise.resolve(new BigNumber(tokenBalances[getBalanceTokenKey(token.network, token.code)] || 0))
    },
    [tokenBalances],
  )

  const onWithdrawSuccess = async (txnCode: string) => {
    dispatch(addPendingWithdrawTransaction({ code: txnCode }))
    controls.value.onValueChanged('')
  }

  const { isBlackList, isLogicLocked } = useValidateWithdrawToken(selectedToken)

  const [allowComment, typeComment] = useMemo(() => {
    if (isTonChain(selectedToken.network)) {
      return [true, 'string']
    }
    if (isXrpChain(selectedToken.network)) {
      return [true, 'number']
    }

    return [false, '']
  }, [selectedToken])

  return (
    <OpenEffect openType="grow" duration={0.4}>
      <StyledWrapper flexDirection="column" width="100%" height="100%" justifyContent="space-between">
        <Flex flex="1 1" flexDirection="column">
          <PaymentTokenSelect
            token={selectedToken}
            onTokenSelect={setSelectedToken}
            fetchTokenBalanceFn={fetchTokenBalanceFn}
            networks={networks}
            from={PaymentMethodEnum.Withdraw}
          />
          {isBlackList ? (
            <GameInfoMessage
              variant="error"
              maxWidth="100% !important"
              my="12px"
              message={t('Withdraw with {{tokenName}}({{network}}) has been suspended.', {
                tokenName: selectedToken.name,
                network: NETWORK_MAP[selectedToken.network].networkInfo.displayName,
              })}
            />
          ) : isLogicLocked ? (
            <GameInfoMessage
              variant="error"
              maxWidth="100% !important"
              my="12px"
              message={t(
                '{{tokenName}}({{network}}) withdraw suspended for development. Please contact CS for assistance.',
                {
                  tokenName: selectedToken.name,
                  network: NETWORK_MAP[selectedToken.network].networkInfo.displayName,
                },
              )}
            />
          ) : (
            <Flex flex="1 1" flexDirection="column" justifyContent="space-between">
              {selectedToken === HUSD_TOKEN ? (
                <HUSDInfo />
              ) : (
                <>
                  {!isValidWithdrawToOwnerAddress && (
                    <StyledFormControl
                      mt="16px"
                      state={states.receiveAddress}
                      label={t('Address')}
                      formatErrorMessage={(errors) => WithdrawInputErrorMessages.receiveAddress[errors[0]]}
                    >
                      <FormInput
                        tabIndex={1}
                        control={controls.receiveAddress}
                        placeholder={t('Address')}
                        type="address"
                        name="address"
                      />
                    </StyledFormControl>
                  )}

                  {allowComment && (
                    <>
                      <StyledFormControl
                        mt="16px"
                        state={states.memo}
                        label={t('Destination Tag (Optional)')}
                        formatErrorMessage={() => false}
                      >
                        <FormInput
                          tabIndex={1}
                          control={controls.memo}
                          placeholder={t('Destination Tag')}
                          type={typeComment}
                          name="memo"
                        />
                      </StyledFormControl>
                    </>
                  )}

                  {bonusAmount.gt(0) && (
                    <StyledReminderBox mt="16px">
                      <Box>
                        <Icons.InfoCircleIcon width="16px" fill={colors.textSubtle} />
                      </Box>
                      <Box ml="5px">
                        <Text fontSize="12px" lineHeight="14.63px" color="textSubtle">
                          <Trans>
                            Please kindly remember to complete the wagering process in order to receive your bonus
                            before proceeding with your withdrawal.
                          </Trans>
                        </Text>

                        <Flex mt="4px" mb="4px">
                          <Text fontSize="12px" lineHeight="14.63px" color="textTertiary">
                            <Trans>Your Bonus</Trans>
                          </Text>
                          <Text fontSize="12px" lineHeight="14.63px" ml="5px" color="textTertiary">
                            {getFullDisplayBalance(bonusAmount, 0, 5)}
                          </Text>
                          <Text fontSize="12px" lineHeight="14.63px" ml="2px" color="textTertiary">
                            {selectedToken.name}
                          </Text>
                        </Flex>

                        <Box onClick={() => onDismiss()}>
                          <StyledTextButton
                            as={NextLinkFromReactRouter}
                            to={RouteConfig.BonusCenter}
                            alignItems="center"
                          >
                            <Text mr="5px" fontSize="12px" color="#6C727E">
                              <Trans>Details</Trans>
                            </Text>
                            <Icons.ChevronRightIcon width="4.5px" fill={colors.textTertiary} />
                          </StyledTextButton>
                        </Box>
                      </Box>
                    </StyledReminderBox>
                  )}

                  <StyledControlContainer mt="16px" state={states.value}>
                    <StyledLabel>
                      <InputLabel mb="0px !important">
                        <Trans>Amount</Trans>
                      </InputLabel>
                      <InputLabel mb="0px !important">
                        <Trans>Available</Trans>: {getFullDisplayBalance(withdrawalAmount, 0, 5)} {selectedToken?.name}
                      </InputLabel>
                    </StyledLabel>
                    <TokenInput
                      tabIndex={1}
                      value={states.value.value}
                      token={selectedToken}
                      errors={states.value.errors}
                      validators={controls.value.validators}
                      onErrorChanged={controls.value.onErrorChanged}
                      onValueChanged={controls.value.onValueChanged}
                      max={withdrawalAmount}
                    />
                    <Flex flexDirection={['column']} justifyContent="space-between">
                      {WithdrawInputErrorMessages.withdrawValue[states.value.errors[0]] && (
                        <InputMessage textAlign="right">
                          {states.value.errors[0] !== ValidationError.LessThan && (
                            <Trans>{WithdrawInputErrorMessages.withdrawValue[states.value.errors[0]]}</Trans>
                          )}
                          {states.value.errors[0] === ValidationError.LessThan &&
                            t('The min withdrawal amount is {{ totalMinWithdraw }} {{ selectedToken }}', {
                              totalMinWithdraw: totalMinWithdrawAmount?.toString(),
                              selectedToken: selectedToken.name,
                            })}
                        </InputMessage>
                      )}

                      {lockAmount.gt(0) && (
                        <RowMiddle mt="16px">
                          <Text fontSize="12px" color="textSubtle">
                            <Trans
                              i18nKey="lockBonusAmountWithdrawTitle"
                              components={{
                                highlight_tag: (
                                  <StyledBonusCenterLink
                                    as={Link}
                                    onClick={() => {
                                      onDismiss()
                                    }}
                                    href={RouteConfig.BonusCenter}
                                  />
                                ),
                              }}
                            />{' '}
                            <Text fontSize="12px" lineHeight="normal" color="textSubtle" as="span">
                              {getFullDisplayBalance(lockAmount, 0, 5)} {selectedToken.name}
                            </Text>
                          </Text>
                        </RowMiddle>
                      )}

                      <RowMiddle mt="6px">
                        <Text fontSize="12px" color="textSubtle" mr="4px" lineHeight="normal">
                          <Trans>Fee</Trans>
                        </Text>
                        <Text fontSize="12px" color="textSubtle" as="span" lineHeight="normal">
                          {Number.isNaN(withdrawFee.toNumber()) ? '...' : withdrawFee.toNumber()} {selectedToken?.name}
                        </Text>
                      </RowMiddle>
                    </Flex>
                  </StyledControlContainer>

                  {states.value.value && Number(states.value.value) > withdrawFee.toNumber() && (
                    <Flex mt="30px" justifyContent="space-between" height="16px">
                      <>
                        <Text fontSize={['12px', '12px', '14px']} color="primary" bold>
                          <Trans>You will get</Trans>:
                        </Text>

                        <Text fontSize={['12px', '12px', '14px']} color="primary" bold>
                          {getFullDisplayBalance(
                            new BigNumber(states.value.value).minus(withdrawFee),
                            0,
                            selectedToken.decimals,
                          )}{' '}
                          {selectedToken.name}
                        </Text>
                      </>
                    </Flex>
                  )}
                </>
              )}

              {selectedToken !== HUSD_TOKEN && (
                <Box width="100%" mt="30px" mb="10px">
                  {isAuthByEmail || isAuthByTelegram ? (
                    <TraditionalWithdrawButton
                      width="100%"
                      fee={withdrawFee.toString()}
                      selectedToken={selectedToken}
                      toAddress={states.receiveAddress.value}
                      validateAll={validateAll}
                      onSuccess={onWithdrawSuccess}
                      value={states.value.value}
                      memo={states.memo.value}
                      disabled={!isValid || !states.value.value || isFetching}
                      tabIndex={1}
                    />
                  ) : (
                    isAuthByWallet && (
                      <WalletWithdrawButton
                        width="100%"
                        selectedToken={selectedToken}
                        validateAll={validateAll}
                        onSuccess={onWithdrawSuccess}
                        value={states.value.value}
                        disabled={!isValid || !states.value.value}
                        toAddress={states.receiveAddress.value}
                        memo={states.memo.value}
                        tabIndex={1}
                      />
                    )
                  )}
                </Box>
              )}
            </Flex>
          )}
        </Flex>
      </StyledWrapper>
    </OpenEffect>
  )
}

const StyledTextButton = styled(Flex)`
  cursor: pointer;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.colors.textAlt1};
  }

  &:hover {
    ${Text} {
      color: ${({ theme }) => theme.colors.text} !important;
    }

    svg {
      fill: ${({ theme }) => theme.colors.text};
    }
  }
`

const StyledReminderBox = styled(Flex)`
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  padding: 8px;
`

const StyledWrapper = styled(Flex)``

const StyledLabel = styled(InputLabel)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledFormControl = styled(FormControl)`
  ${StyledInput} {
    height: 46px;
  }
`

const StyledBonusCenterLink = styled(Text)`
  display: inline-block;
  font-size: 12px;
  color: ${({ theme: { colors } }) => colors.strokeAlt3};
  line-height: normal;
  text-decoration: underline;
  font-weight: 500;
`

export default Withdraw
