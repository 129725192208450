import Input from 'UIKit/Input'
import React from 'react'
import StyledToggle, { Handle } from './StyledToggle'
import { ToggleProps } from './types'

const Toggle: React.FC<ToggleProps> = ({
  value,
  defaultColor = 'text',
  checkedBackgroundColor = 'primary',
  checkedColor = 'text',
  backgroundColor = 'primary',
  width = '40px',
  isBlur = false,
  ...props
}) => {
  const isChecked = !!value

  return (
    <StyledToggle
      $checked={isChecked}
      $backgroundColor={backgroundColor}
      $checkedColor={checkedColor}
      $checkedBackgroundColor={checkedBackgroundColor}
      $defaultColor={defaultColor}
      width={width}
      $disabled={{ ...props }.disabled}
      $isBlur={isBlur}
    >
      <Input value={value} type="checkbox" {...props} />

      <Handle />
    </StyledToggle>
  )
}

export default Toggle
