import Flex from 'UIKit/Box/Flex'
import Button from 'UIKit/Button'
import Modal, { ModalBody, ModalProps } from 'UIKit/Modal'
import Text from 'UIKit/Text'
import { Transaction } from 'config/types/transaction'
import { useCountDown } from 'hooks/useCountdown'
import useModal from 'hooks/useModal'
import { usePrivateSocket } from 'hooks/usePrivateSocket'
import { ColumnCenter } from 'layout/Components/Column'
import { RowCenter } from 'layout/Components/Row'
import { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useAuth } from 'state/auth/hooks'
import styled from 'styled-components'
import base from 'theme/base'
import { colors } from 'theme/colors'
import DepositTransactionDetailsModal from 'views/Profile/Transactions/components/Deposit/DepositTransactionDetailsModal'

const PaymentMethodBlockingModal: React.FC<ModalProps<{ paymentFiatTransaction: Transaction }>> = ({
  data: { paymentFiatTransaction },
  onDismiss,
}) => {
  const countdownTime = new Date(
    new Date(paymentFiatTransaction.createTime).setMinutes(
      new Date(paymentFiatTransaction.createTime).getMinutes() + 3,
    ),
  )
  const shutdownTimeTextRef = useCountDown(countdownTime.getTime(), (s, m, h, d) => {
    return `<div class='${d === 0 ? 'highlight' : ''}' >
      <div class='${h === 0 ? 'container' : ''}' > <div class='${m === 0 ? 'highlight' : ''}'>${m
      .toString()
      .padStart(2, '0')}</div> <span>:</span> <div>${s.toString().padStart(2, '0')}</div>`
  })

  const [onPresentDepositDetails] = useModal(DepositTransactionDetailsModal)
  const { isSigned } = useAuth()
  const socket = usePrivateSocket()

  useEffect(() => {
    if (!socket || !isSigned) return
    socket.on('deposit.confirmed', (data) => {
      if (data?.txn_hash === paymentFiatTransaction.txnHash) onDismiss()
    })

    return () => {
      if (socket) {
        socket.off('deposit.confirmed', () => {})
      }
    }
  }, [socket, isSigned, paymentFiatTransaction])

  return (
    <Modal onDismiss={onDismiss}>
      <ModalBody padding="24px 44px 40px 44px" mt="24px">
        <Text fontWeight={600} fontSize="16px" letterSpacing={-0.32} lineHeight="normal">
          <Trans>Deposit in progress</Trans>
        </Text>

        <ColumnCenter mt="20px">
          <ColumnCenter>
            <Text color="textSubtle" fontSize="16px" lineHeight="normal" fontWeight={400} letterSpacing={-0.32}>
              <Trans>
                You just made a deposit using this payment method. Please complete previous transaction or change a
                payment method.
              </Trans>
            </Text>

            <RowCenter
              background={colors.backgroundAlt5}
              borderRadius={base.radii.small}
              padding="12px 20px"
              m="20px 16px"
            >
              <Text
                fontSize="12px"
                color="textSubtle"
                fontWeight={600}
                lineHeight="normal"
                letterSpacing={-0.24}
                mr="4px"
              >
                <Trans>Retry in</Trans>
              </Text>
              <StyledCountdownContent ref={shutdownTimeTextRef} />
            </RowCenter>
          </ColumnCenter>

          <Button
            width="100%"
            variant="primary"
            maxWidth={'432px !important'}
            onClick={() => {
              onPresentDepositDetails({
                transaction: paymentFiatTransaction,
                bonusInfo: null,
              })
              onDismiss()
            }}
          >
            <Text fontSize="14px" fontWeight={600} letterSpacing={-0.28} lineHeight="normal">
              <Trans>Deposit Details</Trans>
            </Text>
          </Button>
        </ColumnCenter>
      </ModalBody>
    </Modal>
  )
}

const StyledCountdownContent = styled(Flex)`
  color: ${({ theme: { colors } }) => colors.textTertiary};
  font-size: 14px;
  font-weight: 600;
  line-height: normal;

  .container {
    display: flex;
    align-items: center;
  }

  div {
    text-align: center;
  }

  span {
    width: 2px;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.text};
    margin: 0 4px;
  }
`

export default PaymentMethodBlockingModal
