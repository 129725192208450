import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import styled from 'styled-components'
import { LayoutProps, layout } from 'styled-system'

const Td: React.FC<BoxProps & { width?: string; colSpan?: number }> = ({
  children,
  width,
  colSpan,
  display,
  ...props
}) => (
  <StyledTd style={{ width }} display={display} colSpan={colSpan}>
    <StyledCell {...props} width="100%">
      {children}
    </StyledCell>
  </StyledTd>
)

const StyledCell = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 12px;
  border-left: ${({ theme: { colors } }) => `0.25px solid ${colors.stroke}`};
  border-right: ${({ theme: { colors } }) => `0.25px solid ${colors.stroke}`};
`
const StyledTd = styled.td<LayoutProps>`
  &:first-child ${StyledCell}:not(.loader) {
    border-right: none;
  }

  &:last-child ${StyledCell}:not(.loader) {
    border-left: none;
  }

  ${layout}
`

export default styled(Td)``
