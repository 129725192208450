import Flex from 'UIKit/Box/Flex'
import Button from 'UIKit/Button'
import Text from 'UIKit/Text'
import Toggle from 'UIKit/Toggle'
import useModal from 'hooks/useModal'
import { RowCenter } from 'layout/Components/Row'
import { Trans } from 'react-i18next'
import { useUserSettings } from 'state/profile/hooks'
import styled from 'styled-components'
import FiatModal from '.'
import FiatCurrencyDisplay from './FiatCurrencyDisplay'

const FiatSetting = () => {
  const { updateSetting, userSettings } = useUserSettings()
  const [onPresent] = useModal(FiatModal)

  return (
    <Flex alignItems="center" height="40px" width="100%">
      <Flex padding="0px 8px" alignItems="center" justifyContent="space-between" width="100%">
        <Text fontSize="14px" bold color="#fff">
          <Trans>Display in Fiat</Trans>
        </Text>
        <RowCenter>
          <FiatBtnStyled
            onClick={() => onPresent()}
            disabled={!userSettings.isDisplayInFiat}
            className={!userSettings.isDisplayInFiat ? 'disable-fiat-btn' : ''}
          >
            <FiatCurrencyDisplay
              fiat={userSettings.selectedFiatCurrency}
              imgSize={16}
              textStyle={{ fontSize: '12px', fontWeight: '600px' }}
            />
          </FiatBtnStyled>
          <Toggle
            defaultColor="textDisable"
            checkedColor="textTertiary"
            backgroundColor="modalHeaderBackground"
            checkedBackgroundColor="success"
            value={userSettings.isDisplayInFiat}
            onValueChanged={(value) => {
              updateSetting({
                isDisplayInFiat: value,
              })
            }}
          />
        </RowCenter>
      </Flex>
    </Flex>
  )
}

const FiatBtnStyled = styled(Button)`
  background: ${({ theme }) => theme.colors.backgroundAlt5};
  padding: 0px;
  width: 72px;
  height: 24px;
  padding: 6px 4px;
  margin-right: 8px;
  &.disable-fiat-btn {
    opacity: 0.5;
    background: ${({ theme }) => theme.colors.backgroundAlt5};
  }
`
export default FiatSetting
