import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Button from 'UIKit/Button'
import Heading from 'UIKit/Heading'
import Modal, { ModalProps } from 'UIKit/Modal/Modal'
import ModalBody from 'UIKit/Modal/components/ModalBody'
import Text from 'UIKit/Text'
import useDebounceCallback from 'hooks/useDebounceCallback'
import useDetectKeyboardOpen from 'hooks/useDetectKeyboardOpen'
import useQueryParam from 'hooks/useQueryParam'
import { useRouter } from 'hooks/useRouter'
import React, { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useAppDispatch } from 'state'
import { setAuthModalOpen } from 'state/app/actions'
import { useAuth } from 'state/auth/hooks'
import styled from 'styled-components'
import Introduction from './components/Introduction'
import SwitchMethod from './components/SwitchMethod'
import SwitchPage from './components/SwitchPage'
import Template, { Content, Footer, Header } from './components/Template'
import TraditionalAuth from './components/Traditional'
import WalletAuth from './components/Wallet'
import { AuthModalMethodEnums, AuthModalPageEnums } from './types'
import SocialAuth from './components/Social'

export interface AuthModalProps {
  page?: AuthModalPageEnums
  method?: AuthModalMethodEnums
}
const Authentication: React.FC<ModalProps<AuthModalProps>> = ({ onDismiss, data: { page, method } }) => {
  const { t } = useTranslation()
  const isKeyboardOpen = useDetectKeyboardOpen()
  const { isSigned } = useAuth()
  const debouceCallback = useDebounceCallback()
  const dispatch = useAppDispatch()

  const { removeParam } = useQueryParam()

  const router = useRouter()
  const afterSignInTargetRef = useRef(router.query?.goto && router.query.goto.toString())

  const [currentPage, setCurrentPage] = useState(page || AuthModalPageEnums.LOG_IN)
  const [currentMethod, setCurrentMethod] = useState(method || AuthModalMethodEnums.WEB3)
  const [isShowMoreWallet, setIsShowMoreWallet] = useState<boolean>(false)
  const [isSelectedWallet, setSelectedWallet] = useState<boolean>(false)

  const isLoginPage = currentPage === AuthModalPageEnums.LOG_IN

  const title = isLoginPage ? t('Welcome back!') : t('Welcome to our community')

  useEffect(() => {
    if (isSigned) {
      if (afterSignInTargetRef.current) router.push(afterSignInTargetRef.current)
      onDismiss()
    }
  }, [isSigned])

  useEffect(() => {
    if (router.query?.goto) {
      debouceCallback(() => removeParam('goto'), 100)
    }
  }, [router])

  useEffect(() => {
    dispatch(setAuthModalOpen({ isOpen: true }))
    return () => {
      dispatch(setAuthModalOpen({ isOpen: false }))
    }
  }, [])

  return (
    <Container id="popup-authen" onDismiss={onDismiss}>
      <ModalBody height="100%">
        <Flex height="100%">
          <Introduction title={title} page={isLoginPage ? AuthModalPageEnums.LOG_IN : AuthModalPageEnums.SIGN_UP} />

          <MainContent>
            <Template>
              {!isSelectedWallet && (
                <Header>
                  <Heading scale="md">{isLoginPage ? <Trans>Log in with</Trans> : <Trans>Sign up with</Trans>}</Heading>
                  <Button id={'button-cancel'} variant="text" display={['flex', 'flex', 'none']} onClick={onDismiss}>
                    <Text color="textSubtle" fontSize="14px">
                      <Trans>Cancel</Trans>
                    </Text>
                  </Button>
                </Header>
              )}

              <StyledContent>
                {!isShowMoreWallet && !isSelectedWallet && (
                  <SwitchMethod mb="24px" method={currentMethod} onMethodChange={setCurrentMethod} />
                )}

                {currentMethod === AuthModalMethodEnums.WEB3 && (
                  <WalletAuth
                    page={currentPage}
                    isShowMore={isShowMoreWallet}
                    setShowMore={setIsShowMoreWallet}
                    setSelectedWallet={setSelectedWallet}
                  />
                )}

                {/* TODO REVIEW */}
                <TraditionalAuth
                  page={currentPage}
                  hide={currentMethod !== AuthModalMethodEnums.TRADITIONAL}
                  setPage={setCurrentPage}
                />
              </StyledContent>

              {!isKeyboardOpen && !isShowMoreWallet && !isSelectedWallet && (
                <>
                  <SocialAuth page={currentPage} onSwitchPage={setCurrentPage} />

                  <StyledFooter mt="32px">
                    <SwitchPage presentedPage={currentPage} onSwitchPage={setCurrentPage} />
                  </StyledFooter>
                </>
              )}
            </Template>
          </MainContent>
        </Flex>
      </ModalBody>
    </Container>
  )
}

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  flex: 1 1;
  overflow: overlay;
`

const StyledFooter = styled(Footer)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const Container = styled(Modal)`
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 0px;
  padding: 0;
  width: 100%;
  height: var(--screen-height);
  max-height: var(--screen-height) !important;

  max-width: unset !important;

  .modal-closebutton {
    pointer-events: none;
    display: none;
    top: 24px;
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    max-height: min(715px, calc(var(--screen-height) - 24px)) !important;
    max-width: 512px !important;
    border-radius: ${({ theme }) => theme.radii.extraLarge};

    .modal-closebutton {
      pointer-events: auto;
      display: flex;
      z-index: 1;
    }
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.md} {
    min-width: 800px;
  }
`

const MainContent = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  margin-left: 0;
  box-sizing: border-box;
  position: relative;
  overflow: auto;

  ${({ theme: { mediaQueries } }) => mediaQueries.md} {
    max-width: 57%;
    min-width: 57%;

    overflow: hidden;
    margin-left: 0px;
    border-radius: ${({ theme }) => theme.radii.extraLarge};
    background: ${({ theme }) => theme.colors.cardBackground};
  }
`

export default Authentication
