import { BoxProps } from 'UIKit/Box/types'
import TableCell from 'UIKit/Table/TableCell'
import TableHeader from 'UIKit/Table/TableHeader'
import TableRow from 'UIKit/Table/TableRow'
import Text from 'UIKit/Text'
import { TextProps } from 'UIKit/Text/types'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

export const HEIGHT_ROW = 52

export const TableCellHistory = styled(TableCell)`
  border: none !important;
  padding: 0 8px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 12px;
  }
`

export const TableRowHistory = styled(TableRow)`
  border: none !important;
  border-radius: ${({ theme }) => theme.radii.tiny};
  font-size: 14px;

  &:hover {
    td {
      background: rgba(255, 255, 255, 0.04);
    }
  }
`

export const TableHeaderValueHistory: React.FC<
  { key: string; width: string | string[]; label: string; containerProps?: BoxProps } & TextProps
> = ({ key, width, label, display, containerProps, ...props }) => (
  <TableHeader
    style={{
      background: 'transparent',
    }}
    key={key}
    padding={['8px', '8px', '12px']}
    width={width}
    display={display}
    {...containerProps}
  >
    <Text
      letterSpacing="-0.28px"
      textAlign="left"
      fontSize="14px"
      lineHeight="20px"
      color="textAlt1"
      fontWeight={700}
      {...props}
    >
      <Trans>{label}</Trans>
    </Text>
  </TableHeader>
)

export const TableRowValueHistory = styled(Text)<{ symbol?: boolean; lineHeight?: any }>`
  font-size: ${({ fontSize }) => fontSize || '1em'};
  line-height: ${({ lineHeight = '20px' }) => lineHeight};

  color: ${({ theme, color }) => theme.colors[color] || color || theme.colors.textTertiary};
  word-break: break-all;

  ${({ symbol }) =>
    symbol &&
    css`
      color: ${({ theme }) => theme.colors.textAlt1};
      white-space: nowrap;
      word-break: keep-all;
    `}
`
