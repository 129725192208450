import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Button from 'UIKit/Button'
import Image from 'UIKit/Image'
import Link from 'UIKit/Link'
import CopySuccessLoader from 'UIKit/Loader/CopySuccessLoader'
import { ModalProps } from 'UIKit/Modal'
import Text from 'UIKit/Text'
import { BetDetails } from 'config/types/profile'
import useCopy from 'hooks/useCopy'
import { ColumnCenter } from 'layout/Components/Column'
import { RowCenter } from 'layout/Components/Row'
import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import theme from 'theme'
import { getBetShare } from 'utils/urlHelper'
import { getReferralShareUrl } from 'views/Referral/utils'
import { TransactionWrapperModal, WrapperContainerTransactionDetail } from '../styled'

const BetTransactionShareModal: React.FC<
  ModalProps<{
    bet: BetDetails
  }>
> = ({ onDismiss, data }) => {
  const { bet } = data
  const { copied, handleCopy } = useCopy()
  const betTransactionUrl = useMemo(() => getBetShare(bet.betId), [bet.betId])
  const content = 'HunnyPlay.io. Hey, you really should check this Bet Details.'

  return (
    <TransactionWrapperModal
      maxWidth="420px !important"
      minWidth="420px !important"
      onDismiss={onDismiss}
      title=""
      p="24px"
    >
      <WrapperContainerTransactionDetail my="24px">
        <Flex alignItems="center">
          <Box width={48} height={48}>
            <Image src="/images/crown-icon.png" width={48} height={48} alt="crown-image" />
          </Box>
          <Box ml="8px">
            <Text color="textTertiary" fontWeight={600} mb="4px" fontSize="14px" lineHeight="20px">
              <Trans>Winning tastes sweet!</Trans>
            </Text>
            <Text color="textAlt1" fontWeight={500} fontSize="12px">
              <Trans>{bet.game.name}</Trans>
            </Text>
          </Box>
        </Flex>
      </WrapperContainerTransactionDetail>

      <WrapperContainerTransactionDetail>
        <RowCenter>
          <ColumnCenter>
            <Text color="textSubtle" fontWeight={600} fontSize="14px" pb="8px">
              <Trans>Share on social media</Trans>
            </Text>

            <StyledRowCenter my="32px">
              <Link
                href={getReferralShareUrl({
                  content,
                  appSiteUrl: betTransactionUrl,
                  type: 'telegram',
                })}
                external
              >
                <Box width={48} height={48}>
                  <Image src="/images/social/telegram-social-icon.png" width={48} height={48} alt="telegram-icon" />
                </Box>
              </Link>

              <Link
                href={getReferralShareUrl({
                  content,
                  appSiteUrl: betTransactionUrl,
                  type: 'twitter',
                })}
                external
              >
                <Box width={48} height={48}>
                  <Image src="/images/social/twitter-icon.png" width={48} height={48} alt="x-icon" />
                </Box>
              </Link>

              <Link
                href={getReferralShareUrl({
                  content,
                  appSiteUrl: betTransactionUrl,
                  type: 'reddit',
                })}
                external
              >
                <Box width={48} height={48}>
                  <Image src="/images/social/reddit-social-icon.png" width={48} height={48} alt="reddit-icon" />
                </Box>
              </Link>

              <Link
                href={getReferralShareUrl({
                  content,
                  appSiteUrl: betTransactionUrl,
                  type: 'facebook',
                })}
                external
              >
                <Box width={48} height={48}>
                  <Image src="/images/social/facebook-social-icon.png" width={48} height={48} alt="facebook-icon" />
                </Box>
              </Link>
            </StyledRowCenter>
          </ColumnCenter>
        </RowCenter>

        <Box position="relative" mt="8px">
          <Button
            width="100%"
            style={{
              background: 'rgba(255, 255, 255, 0.12)',
              borderRadius: theme.radii.tiny,
            }}
            height={['32px', content, '40px']}
            onClick={() => {
              handleCopy(betTransactionUrl)
            }}
          >
            <Text
              small
              bold
              style={{
                position: 'relative',
              }}
            >
              <Trans>Copy Link</Trans>
              <Box
                display={copied ? 'block' : 'none'}
                position="absolute"
                right="-60%"
                top="50%"
                style={{
                  transform: 'translate(-50%,-40%)',
                }}
              >
                <CopySuccessLoader />
              </Box>
            </Text>
          </Button>
        </Box>
      </WrapperContainerTransactionDetail>
    </TransactionWrapperModal>
  )
}

const StyledRowCenter = styled(RowCenter)`
  gap: 40px;
`

export default BetTransactionShareModal
