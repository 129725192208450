import Flex from 'UIKit/Box/Flex'
import Button from 'UIKit/Button'
import Modal, { ModalBody, ModalProps } from 'UIKit/Modal'
import Text from 'UIKit/Text'
import Column from 'layout/Components/Column'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icons } from 'svgs'

const WarningLoginUnLinkModal: React.FC<
  ModalProps<{
    callbackSignUp: () => void
  }>
> = ({ onDismiss, data: { callbackSignUp } }) => {
  const { t } = useTranslation()

  return (
    <Modal
      minWidth={['100% !important', '', '440px !important']}
      maxWidth={['100% !important', '', '440px !important']}
      onDismiss={onDismiss}
    >
      <ModalBody p="24px 24px 32px">
        <Flex>
          <Button onClick={onDismiss} pl="0 !important" height="16px" variant="text">
            <Icons.ArrowLeftIcon />
          </Button>
          <Text bold fontSize="18px">
            {t('How to Log in')}
          </Text>
        </Flex>

        <Column mt="24px" mb="36px">
          <Text lineHeight={1.6} fontWeight={500} small color="textTertiary">
            {t(
              "This Telegram account hasn't connected to any account. You can sign-up new account or connect your Hunnyplay account to this telegram account by log-in with the original log-in method and select connect to Telegram in your profile.",
            )}
          </Text>
        </Column>

        <Button
          onClick={() => {
            callbackSignUp()
            onDismiss()
          }}
          style={{ borderRadius: '4px' }}
        >
          <Text bold small>
            {t('Sign up')}
          </Text>
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default WarningLoginUnLinkModal
