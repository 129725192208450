import Box from 'UIKit/Box/Box'
import Button from 'UIKit/Button'
import CircleLoader from 'UIKit/Loader/CircleLoader'
import Text from 'UIKit/Text'
import { ChainIdEnum } from 'config/constants/network'
import { USER_BANNED_ERROR_CODE } from 'config/constants/server'
import tokens from 'config/constants/tokens'
import { TipDetails } from 'config/types/payment'
import useModal from 'hooks/useModal'
import { useRequest } from 'hooks/useRequest'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PaymentService from 'services/PaymentService'
import { PrepareTraditionalTipPayload } from 'services/types'
import { useAppSelector } from 'state'
import { useUserSettings } from 'state/profile/hooks'
import styled from 'styled-components'
import { transformHunnyRequest } from 'utils/requestHelper'
import { HunnyToast } from 'utils/toastify'
import EmailVerificationModal from 'views/Authentication/components/Traditional/modals/EmailVerificationModal'

export interface ConfirmTipButtonProps {
  details: TipDetails
  onConfirmed: (data: TipDetails) => void
}

const TraditionalConfirmTipButton: React.FC<ConfirmTipButtonProps> = ({ details, onConfirmed }) => {
  const [handlePresentEmailVerificationModal] = useModal(EmailVerificationModal)
  const { email } = useAppSelector((state) => state.auth)
  const [submiting, setSubmiting] = useState<boolean>(false)
  const { t } = useTranslation()
  const { execute } = useRequest()
  const {
    userSettings: { isEnable2FAForTip },
  } = useUserSettings()

  const handleSendTip = async () => {
    setSubmiting(true)
    const parsedPrepareTipPayload: PrepareTraditionalTipPayload = {
      amount: details.amount,
      currency: tokens[ChainIdEnum[details.network]]?.[details.currency],
      message: details.message,
      uid: details.receiver.uid,
    }

    const verification = await execute(PaymentService.prepareTraditionalTip(parsedPrepareTipPayload))

    if (verification.code === USER_BANNED_ERROR_CODE) {
      HunnyToast.error(
        t(
          'This feature has been temporarily suspended on your account. Please reach out to Customer Service for further details.',
        ),
      )
      return onConfirmed(null)
    }

    if (!verification?.data) return setSubmiting(false)
    handlePresentEmailVerificationModal(
      {
        email,
        isUse2FA: isEnable2FAForTip,
        initialVerifiedResult: verification.data,
        verifiedHunnyRequestFn: () =>
          transformHunnyRequest(PaymentService.prepareTraditionalTip(parsedPrepareTipPayload), (res) => res?.data),
        async onSubmit(verification, otp, totpAuthCode) {
          const response = await PaymentService.traditionalSendTip({
            ...parsedPrepareTipPayload,
            otp,
            verificationCode: verification.token,
            totpAuthCode,
          })
          if (response.code === 'success' && response?.data) {
            onConfirmed(response.data)
          }
          return response
        },
      },
      true,
    )

    setSubmiting(false)
  }

  return (
    <StyledConfirmButton onClick={handleSendTip} disabled={submiting}>
      {submiting && (
        <Box mr="12px">
          <CircleLoader />
        </Box>
      )}
      <Text fontSize="14px" letterSpacing={-0.28} fontWeight={600}>
        <Trans>Send Tip</Trans>
      </Text>
    </StyledConfirmButton>
  )
}

const StyledConfirmButton = styled(Button)`
  width: 100%;
  border-radius: ${({ theme: { radii } }) => radii.tiny};
  margin-bottom: 16px;
  height: 48px !important;
`

export default TraditionalConfirmTipButton
