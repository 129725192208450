import Box from 'UIKit/Box/Box'
import { FlexProps } from 'UIKit/Box/types'
import Image from 'UIKit/Image'
import OpenEffect from 'UIKit/OpenEffect'
import useDebounce from 'hooks/useDebounce'
import React, { useCallback, useState } from 'react'

import styled from 'styled-components'
import { delayed } from 'utils'
import { forkjoinRequest } from 'utils/requestHelper'
import { MINE_GAME_SIZE } from 'views/InhouseGame/Mine/config'
import { MineBlock, MineBlockResult } from 'views/InhouseGame/Mine/config/types'
import { useMineBlockOpenedListener, useOpenMineBlock, useUpdateMineBlockResult } from 'views/InhouseGame/Mine/hooks'

const MineGameBlock: React.FC<
  {
    isSelected: boolean
    toggleSelection: (id: number) => void
    autoMode: boolean
    blockId: number
    block: MineBlock
    disabled?: boolean
    volumeEnable?: boolean
    viewMode?: boolean
    openBlock: (id: number) => Promise<{ isDiamond: boolean }>
  } & FlexProps
> = ({
  block,
  blockId,
  autoMode,
  viewMode,
  isSelected,
  toggleSelection,
  openBlock,
  volumeEnable,
  disabled,
  ...props
}) => {
  const [isOpening, setIsOpening] = useState(null)
  const isOpeningDebounced = useDebounce(isOpening, 300)
  const hightlight = block.isUserOpened
  const result = block.value

  const submitBlock = useOpenMineBlock()
  const update = useUpdateMineBlockResult()

  useMineBlockOpenedListener((_blockId: number) => {
    if (blockId !== _blockId || viewMode) return
    open()
  })

  const open = useCallback(async () => {
    if (result !== MineBlockResult.Unknow || isOpening) return
    setIsOpening(true)

    const [res] = await forkjoinRequest([openBlock(blockId), delayed(500)])
    setIsOpening(false)
    if (res) update(blockId, res.isDiamond ? MineBlockResult.Diamond : MineBlockResult.Mine, { volumeEnable })
  }, [blockId, result, openBlock, isOpening, volumeEnable])

  return (
    <StyledBlockContainer
      onClick={() => {
        if (disabled || viewMode) return
        if (autoMode) {
          toggleSelection(blockId)
          return
        }

        submitBlock(blockId)
      }}
      width={[`calc(${100 / MINE_GAME_SIZE}% - 4px)`, '', `calc(${100 / MINE_GAME_SIZE}% - 8px)`]}
      minWidth={[`calc(${100 / MINE_GAME_SIZE}% - 4px)`, '', `calc(${100 / MINE_GAME_SIZE}% - 8px)`]}
      mx={['2px', '', '4px']}
      my={['2px', '', '4px']}
      position="relative"
      {...props}
      zIndex={result === MineBlockResult.Mine && hightlight ? 1 : 0}
    >
      <StyledBlock
        className={`${isOpening && 'opening'} ${result !== MineBlockResult.Unknow && !isOpening && 'hide'} ${
          autoMode && isSelected && 'selected'
        }`}
        width="100%"
        paddingBottom={['calc(100% - 4px)', '', 'calc(100% - 8px)']}
      />

      {result !== MineBlockResult.Unknow && !isOpening && (!isOpeningDebounced || !hightlight || autoMode) && (
        <StyledBlockResult
          className={` ${autoMode && isSelected && 'selected'}`}
          openType="zoom"
          width="100%"
          paddingBottom={['calc(100% - 4px)', '', 'calc(100% - 8px)']}
          position="relative"
        >
          <Box
            position="absolute"
            width={hightlight ? '70%' : '50%'}
            top="50%"
            left="50%"
            style={{ transform: 'translate(-50%, -50%)', opacity: hightlight ? 1 : 0.4 }}
          >
            {result === MineBlockResult.Diamond && (
              <Image src="/images/inhouse-game/mines/diamond.png" width={256} height={256} />
            )}
            {result === MineBlockResult.Mine && (
              <Box width="100%" position="relative" style={{ transform: 'rotate(45deg)', pointerEvents: 'none' }}>
                <Image src="/images/inhouse-game/mines/mine.png" width={256} height={256} />
                {hightlight && (
                  <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    zIndex={2}
                    width="250%"
                    style={{ transform: 'translate(-50%, -50%)', opacity: 0.15 }}
                  >
                    <Image src="/images/inhouse-game/mines/mine-effect.gif" width={256} height={256} />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </StyledBlockResult>
      )}
    </StyledBlockContainer>
  )
}

const StyledBlock = styled(Box)`
  background: rgba(71, 83, 104, 0.37);
  box-shadow: 0 4px rgba(71, 83, 104, 0.2);
  border-radius: ${({ theme }) => theme.radii.large};
  transition: 0.05s;
  cursor: pointer;

  &:active {
    transform: translateY(0px) !important;
  }

  &.hide {
    animation: zoom-out 0.3s;
    transform: scale(0);
    pointer-events: none;
    opacity: 0;

    @keyframes zoom-out {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      100% {
        transform: scale(0);
        opacity: 0;
      }
    }
  }

  &.selected {
    transform: scale(1) !important;
    opacity: 1 !important;

    background: rgba(44, 97, 190, 0.723);
    box-shadow: 0 8px rgba(44, 97, 190, 0.423);
  }

  &.opening {
    animation: pulse 0.8s infinite;
    transform: scale(1);
    pointer-events: none;
    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      20% {
        transform: scale(1.12);
      }
      60% {
        transform: scale(1);
      }
      80% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    box-shadow: 0 8px rgba(71, 83, 104, 0.2);
  }
`

const StyledBlockContainer = styled(Box)`
  animation-timing-function: cubic-bezier(0.87, -0.41, 0.19, 1.44);
  animation-name: display;
  animation-duration: 400ms;

  @keyframes display {
    0% {
      opacity: 0;
      transform: scale(0);
    }
  }

  &:hover {
    ${StyledBlock} {
      background: rgba(71, 83, 104, 0.6);
      transform: translateY(-5px);
    }

    ${StyledBlock}.selected {
      background: rgba(44, 97, 190, 0.9);
    }
  }
`

const StyledBlockResult = styled(OpenEffect)`
  background: #1e242e;
  border-radius: ${({ theme }) => theme.radii.large};
  transition: 0.05s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &.selected {
    background: #1e242eb8;
  }
`
const MemorizedMineGameBlock = React.memo(MineGameBlock)
export default MemorizedMineGameBlock
