import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import Button from 'UIKit/Button'
import { ButtonProps } from 'UIKit/Button/types'
import { Checkbox } from 'UIKit/Checkbox'
import FormControl from 'UIKit/FormControl'
import Avatar from 'UIKit/Image/Avatar'
import { InputContainer, InputMessage, StyledInput } from 'UIKit/Input/styled'
import Modal, { ModalBody, ModalHeader, ModalProps } from 'UIKit/Modal'
import Table from 'UIKit/Table'
import TableHeader from 'UIKit/Table/TableHeader'
import { TableCellHistory, TableRowHistory } from 'UIKit/Table/TableHistory'
import Text from 'UIKit/Text'
import TokenLogo from 'UIKit/TokenLogo'
import getThemeValue from 'UIKit/util'
import { DISPLAY_AMOUNT } from 'config/constants'
import { ReferralBalanceTokenAmount } from 'config/types/referral'
import { FieldState } from 'hooks/useForm'
import Column from 'layout/Components/Column'
import { RowCenter, RowMiddle } from 'layout/Components/Row'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import styled, { css } from 'styled-components'
import { Icons } from 'svgs'
import { revalidateHunnyLevel } from 'utils'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { getTokenName } from 'utils/token'
import { ReferralSortBy } from '../types'

export const StyledCardBorder = styled(Column)`
  height: 100%;
  flex: 1;

  border-radius: ${({ theme }) => theme.radii.tiny};
  border: 1px solid ${({ theme }) => theme.colors.modalHeaderBackground};
`

export const StyledPanel = styled(Column)`
  padding: 20px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.backgroundAlt7};
`

export const StyledStroke = styled(Box)<{ axisX?: boolean }>`
  ${({ axisX = true }) =>
    axisX
      ? css`
          width: 100%;
          min-height: 1px;
          max-height: 2px;
        `
      : css`
          min-width: 1px;
          max-width: 2px;
          height: 100%;
        `}

  background: ${({ theme }) => theme.colors.modalHeaderBackground};
`

export const WrapCircle = styled(RowCenter)`
  background: ${({ theme }) => theme.colors.backgroundAlt5};
  border-radius: 50%;
`

export const CurrencyRow: React.FC<{ tokenAmount: ReferralBalanceTokenAmount } & BoxProps> = ({
  tokenAmount,
  ...props
}) => {
  return (
    <RowMiddle {...props}>
      <RowCenter minWidth="16px">
        <TokenLogo token={tokenAmount.token} size={16} />
      </RowCenter>
      <Text small color="textTertiary" lineHeight={1.45} mx="4px">
        {getFullDisplayBalance(tokenAmount.amount, 0, DISPLAY_AMOUNT)}
      </Text>
      <Text small lineHeight={1.45} color="textAlt1">
        {getTokenName(tokenAmount.token)}
      </Text>
    </RowMiddle>
  )
}

export const ReferralButton = styled(Button)<ButtonProps & { bold?: boolean }>`
  padding: 9px 12px;
  height: auto;
  width: 100%;
  min-height: ${({ minHeight = '32px' }) => minHeight};

  border-radius: ${({ theme }) => theme.radii.tiny};
  background: ${({ theme, background }) => getThemeValue(`colors.${background}`, background)(theme)};
  font-size: 14px;
  letter-spacing: -0.28px;
  font-weight: 600;
`

export const ReferralColumnScroll: React.FC<React.PropsWithChildren<BoxProps>> = ({
  maxHeight,
  children,
  ...props
}) => {
  return (
    <Column overflowX="hidden" overflowY="scroll" maxHeight={maxHeight} {...props}>
      {children}
    </Column>
  )
}

export const ReferralTable = styled(Table)`
  ${TableHeader} {
  }
  ${TableRowHistory} {
    ${TableCellHistory} {
      height: 68px;
    }
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    ${TableHeader} {
    }
    ${TableRowHistory} {
      ${TableCellHistory} {
      }
    }
  }
`
export const ReferralWrapListItem = styled(Column)`
  padding: 12px 8px;
`

export const ReferralWrapList = styled(Column)`
  ${ReferralWrapListItem}:not(:last-child) {
    border-radius: ${({ theme }) => theme.radii.small};
    border-bottom: 1px solid ${({ theme }) => theme.colors.modalHeaderBackground};

    margin-bottom: 8px;
  }
`

export const ReferralTag = styled(Box)`
  padding: 4px 6px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.backgroundAlt5};
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.strokeAlt3};
`

export const StyledEditInputIcon = styled(Icons.EditInputIcon)<{ size?: number }>`
  path {
    fill: ${({ theme }) => theme.colors.textAlt1};
  }
  transform: scale(${({ size = 24 }) => size / 24});
`

export const StyledInfoCircleIcon = styled(Icons.InfoIcon).attrs({ width: 16, height: 16 })<{ size?: number }>`
  fill: ${({ theme }) => theme.colors.textAlt1};
  transform: scale(${({ size = 16 }) => size / 16});

  &:focus {
    outline: none;
  }
`

export const StyledProfileIcon = styled(Icons.ProfileIcon)<{ size?: number }>`
  path {
    fill: ${({ theme }) => theme.colors.textTertiary};
  }
  transform: scale(${({ size = 24 }) => size / 24});
`

export const StyledProfilesIcon = styled(Icons.ProfilesIcon)<{ size?: number }>`
  path {
    fill: ${({ theme }) => theme.colors.textTertiary};
  }
  transform: scale(${({ size = 16 }) => size / 16});
`

export const ReferralSVG = styled(Box)<{ size?: number; color?: string; rotate?: string | number }>`
  svg {
    path {
      fill: ${({ theme, color }) => getThemeValue(`colors.${color}`, color)(theme)};
    }
  }

  ${({ size = 24, rotate = 1 }) => css`
    transform: scale(${size / 24}) rotate(${rotate});
  `}
`

export const ReferralModal: React.FC<React.PropsWithChildren<ModalProps & BoxProps & { title?: string }>> = ({
  title,
  children,
  ...props
}) => {
  return (
    <Modal {...props}>
      <ModalHeader wrapBackground="transparent" px="24px">
        <Text bold letterSpacing="-0.28px">
          <Trans>{title}</Trans>
        </Text>
      </ModalHeader>
      <ModalBody p="0 24px 40px">{children}</ModalBody>
    </Modal>
  )
}

export const CheckBoxDefaultCampaign: React.FC<
  {
    value: boolean
    onValueChanged: (value: any, event?: any) => void
    onSubmit: () => void
  } & BoxProps
> = ({ value, onValueChanged, onSubmit, ...props }) => {
  const { t } = useTranslation()

  return (
    <RowMiddle {...props}>
      <Checkbox
        width="18px"
        height="18px"
        value={value}
        onValueChanged={onValueChanged}
        onKeyDown={(event) => {
          if (event?.key === 'Enter' && onSubmit && typeof onSubmit === 'function') {
            event.stopPropagation()
            event.preventDefault()
            onSubmit()
          }
        }}
        tabIndex={1}
      />
      <Text fontSize="12px" color="textTertiary" mx="4px">
        <Trans>Set as a default campaign</Trans>
      </Text>

      <StyledInfoCircleIcon data-tooltip-id="create-referral-set-default" size={14} />
      <Tooltip place="bottom" id="create-referral-set-default">
        <Text color="textTertiary" maxWidth={['100%', '', '200px']} small>
          {t('The default campaign value means you can quickly copy its link from the main dashboard')}
        </Text>
      </Tooltip>
    </RowMiddle>
  )
}

export const StyledFormControlReferral = styled(FormControl)<{ state: FieldState; isAccept?: boolean }>`
  ${InputMessage} {
    margin-top: 6px;
    line-height: 1.2;
  }
  ${InputContainer} {
    max-width: 100%;

    ${StyledInput} {
      height: 48px;
    }

    input {
      &::placeholder {
        text-transform: initial !important;
      }
      ::-webkit-input-placeholder {
        text-transform: initial;
      }

      :-moz-placeholder {
        text-transform: initial;
      }

      ::-moz-placeholder {
        text-transform: initial;
      }

      :-ms-input-placeholder {
        text-transform: initial;
      }
    }

    ${({ state, theme, isAccept }) =>
      state.errors.length === 0 && state.isDirty && !state.isValidating && isAccept
        ? css`
            background-color: transparent !important;
            border-color: ${theme.colors.success} !important;
          `
        : ''}
  }
`

const StyledLever = styled(RowCenter)<{ size: 'normal' | 'small' }>`
  position: absolute;
  left: 50%;
  bottom: 0;

  text-align: center;

  border-style: solid;
  border-color: ${({ theme }) => theme.colors.modalHeaderBackground};

  background: linear-gradient(250deg, #f953c6 -15.74%, #b91d73 124.92%);

  color: ${({ theme }) => theme.colors.text};
  font-style: normal;
  font-weight: 600;

  ${({ size }) =>
    size === 'normal'
      ? css`
          min-width: 20px;
          min-height: 15px;
          padding: 2px 6px;
          font-size: 10px;
          border-radius: 50%;
          transform: translate(-50%, 50%);
          border-width: 2px;
        `
      : css`
          min-width: 14px;
          min-height: 8px;
          padding: 1px 3px;
          font-size: 8px;
          border-radius: ${({ theme }) => theme.radii.small};

          border-width: 1px;
          transform: translate(-50%, 60%);
        `}
`

export const ReferralAvatarDisplay: React.FC<{ level: number; avatar: string; size: 'normal' | 'small' }> = ({
  level,
  avatar,
  size = 'normal',
}) => {
  const avatarSize = size === 'normal' ? 40 : 20

  return (
    <Box position="relative" overflow="unset" minWidth={avatarSize}>
      <Avatar src={avatar} width={avatarSize} height={avatarSize} />
      {typeof level === 'number' && <StyledLever size={size}>{revalidateHunnyLevel(level)}</StyledLever>}
    </Box>
  )
}

const StyledTab = styled(Button)<{ $active: boolean }>`
  padding: 8px 20px;
  height: 32px;
  width: 100%;
  min-width: max-content;

  line-height: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  border-radius: var(--Base, 4px);
  border: 1px solid rgba(255, 255, 255, 0.1);

  ${({ $active }) =>
    $active
      ? css`
          background: rgba(255, 255, 255, 0.14);
        `
      : css`
          background: rgba(255, 255, 255, 0.04);
        `}

  svg {
    transition: ${({ theme }) => theme.transitions.fast};
    fill: ${({ theme, $active }) => ($active ? `${theme.colors.success} !important` : theme.colors.text)};
  }

  &:hover:not(:disabled):not(:active) {
    transform: scale(1);
  }

  ${Text} {
    transition: ${({ theme }) => theme.transitions.fast};
    color: ${({ theme, $active }) => ($active ? `${theme.colors.text} !important` : theme.colors.textSubtle)};
    white-space: pre !important;
    font-weight: 600;
    font-size: 14px;
  }
`

const StyledTabs = styled(RowMiddle)`
  max-width: 300px;
  width: 100%;
`

export const SortReferral: React.FC<
  {
    selectedSort: ReferralSortBy | null
    onSelectSort: (sortBy: ReferralSortBy) => void
    registered?: boolean
  } & BoxProps
> = ({ selectedSort, onSelectSort, registered, ...props }) => {
  const handleSelectSort = (val) => {
    if (val !== selectedSort) {
      onSelectSort(val)
    }
  }
  return (
    <StyledTabs {...props}>
      <StyledTab
        mr="8px"
        onClick={() => handleSelectSort(ReferralSortBy.CREATED)}
        $active={selectedSort === ReferralSortBy.CREATED}
        value={ReferralSortBy.CREATED}
      >
        <Text fontWeight="500 !important" small lineHeight="14px">
          {registered ? <Trans>Registered date</Trans> : <Trans>Created date</Trans>}
        </Text>
      </StyledTab>
      <StyledTab
        onClick={() => handleSelectSort(ReferralSortBy.PROFIT)}
        $active={selectedSort === ReferralSortBy.PROFIT}
        value={ReferralSortBy.PROFIT}
      >
        <Text fontWeight="500 !important" small lineHeight="14px">
          <Trans>Profit</Trans>
        </Text>
      </StyledTab>
    </StyledTabs>
  )
}
