import Button from 'UIKit/Button'
import Modal, { ModalBody, ModalProps } from 'UIKit/Modal'
import Text from 'UIKit/Text'
import { RouteConfig } from 'config/constants/route'
import { TipDetails } from 'config/types/payment'
import { TipTransactionFilterTypeEnums } from 'config/types/transaction'
import { useCloseAllModal } from 'hooks/useModal'
import { useRouter } from 'hooks/useRouter'
import { RowCenter, RowMiddle } from 'layout/Components/Row'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import TipDetailInfoes from './TipDetailInfoes'

interface TipDetailModalProps {
  details: TipDetails
  target: TipTransactionFilterTypeEnums
  title?: string
  activeDirectSendForm?: boolean
}

const TipDetailModal: React.FC<ModalProps<TipDetailModalProps>> = ({
  onDismiss,
  data: { details, target, title, activeDirectSendForm },
}) => {
  const router = useRouter()
  const handleCloseAllModal = useCloseAllModal()

  return (
    <Modal p="24px" width={['100%', '', '', '380px !important']} minWidth="unset !important" onDismiss={onDismiss}>
      <StyledModalBody overflowY="visible">
        <RowMiddle position="relative" top="-6px" onClick={onDismiss}>
          <Text fontSize="16px" letterSpacing={-0.32} lineHeight="normal" fontWeight={600} ml="4px">
            <Trans>Tip Details</Trans>
          </Text>
        </RowMiddle>

        <TipDetailInfoes details={details} target={target} title={title} />

        {activeDirectSendForm && (
          <StyledConfirmButton onClick={onDismiss}>
            <Text fontSize="14px" letterSpacing={-0.28} fontWeight={600}>
              <Trans>Send more</Trans>
            </Text>
          </StyledConfirmButton>
        )}
        <RowCenter
          justifyContent="flex-end"
          alignItems="center"
          onClick={() => {
            router.push({
              pathname: RouteConfig.TipTransactionHistory,
            })
            handleCloseAllModal()
          }}
          style={{
            cursor: 'pointer',
            lineHeight: '24px',
          }}
        >
          <Text fontSize="14px" color="textSubtle" letterSpacing={-0.28} fontWeight={300} mx="auto">
            <Trans>View transaction history</Trans>
          </Text>
        </RowCenter>
      </StyledModalBody>
    </Modal>
  )
}

const StyledConfirmButton = styled(Button)`
  width: 100%;
  border-radius: ${({ theme: { radii } }) => radii.tiny};
  margin-bottom: 16px;
`

const StyledModalBody = styled(ModalBody)`
  overflow-y: unset !important;
`

export default TipDetailModal
