import { ChainIdEnum, FIAT_NETWORK, NETWORK_MAP, SYSTEM_NETWORK_MAP } from 'config/constants/network'
import Tokens from 'config/constants/tokens'
import { Network, Token } from 'config/types'
import { getFilterScore } from 'utils'

export const getNetworkInfo = (chainId: ChainIdEnum): { network: Network; native: Token } => {
  const listTokenByChain = Tokens[chainId]
  const networkInfo = NETWORK_MAP[chainId]

  const nativeToken: Token = Object.values(listTokenByChain || {})?.filter((token) => token.isNative)?.[0] || null

  return {
    network: networkInfo,
    native: nativeToken,
  }
}

export const getNetworkIncludeFiatInfo = (
  chainId: ChainIdEnum,
  settings?: {
    getFiatDefaultNative?: boolean
  },
): { network: Network; native: Token } => {
  if (chainId === ChainIdEnum.FIAT)
    return {
      network: FIAT_NETWORK,
      native: settings?.getFiatDefaultNative ? Tokens[ChainIdEnum.FIAT].IDR : null,
    }
  return getNetworkInfo(chainId)
}

export const getBlockExplorerUrls = ({ chainId, txnHash }: { chainId: ChainIdEnum; txnHash: string }) => {
  const networkInfo = NETWORK_MAP[chainId]
  return networkInfo ? `${networkInfo.blockExplorerUrls}/${networkInfo.blockExplorerTxPath || 'tx'}/${txnHash}` : ''
}

export function getBlockExploreLink(
  data: string | number,
  type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
  chainId: ChainIdEnum,
): string {
  const chain = NETWORK_MAP[chainId]
  if (!chain) return NETWORK_MAP[ChainIdEnum.BNB].blockExplorerUrls

  const endpoint = chain.blockExplorerUrls
  const pathTx = chain.blockExplorerTxPath || 'tx'

  const pathToken = chain?.blockExplorerTokenPath || 'token'

  switch (type) {
    case 'transaction': {
      return `${endpoint}/${pathTx}/${data}`
    }
    case 'token': {
      return `${endpoint}/${pathToken}/${data}`
    }
    case 'block': {
      return `${endpoint}/block/${data}`
    }
    case 'countdown': {
      return `${endpoint}/block/countdown/${data}`
    }
    default: {
      return `${endpoint}/address/${data}`
    }
  }
}

export const isSolChain = (chainId: ChainIdEnum) =>
  [ChainIdEnum.SOL, ChainIdEnum.SOL_TESTNET].includes(chainId) || false

export const isTrxChain = (chainId: ChainIdEnum) =>
  [ChainIdEnum.TRX, ChainIdEnum.TRX_TESTNET].includes(chainId) || false

export const isOutSideEvmChain = (chainId: ChainIdEnum) =>
  [
    ChainIdEnum.SOL,
    ChainIdEnum.SOL_TESTNET,
    ChainIdEnum.BTC,
    ChainIdEnum.BTC_TESTNET,
    ChainIdEnum.TRX,
    ChainIdEnum.TRX_TESTNET,
    ChainIdEnum.TON,
    ChainIdEnum.TON_TESTNET,
    ChainIdEnum.XRP,
    ChainIdEnum.XRP_TESTNET,
    ChainIdEnum.LTC,
    ChainIdEnum.LTC_TESTNET,
  ].includes(chainId) || false

export const isFTMChain = (chainId: ChainIdEnum) =>
  [ChainIdEnum.FTM, ChainIdEnum.FTM_TESTNET].includes(chainId) || false

export const isFiatNetwork = (network: Network) => SYSTEM_NETWORK_MAP[ChainIdEnum.FIAT] === network

export const isTonChain = (chainId: ChainIdEnum) =>
  [ChainIdEnum.TON, ChainIdEnum.TON_TESTNET].includes(chainId) || false

export const isXrpChain = (chainId: ChainIdEnum) =>
  [ChainIdEnum.XRP, ChainIdEnum.XRP_TESTNET].includes(chainId) || false

export const isLTCChain = (chainId: ChainIdEnum) =>
  [ChainIdEnum.LTC, ChainIdEnum.LTC_TESTNET].includes(chainId) || false

export const filterNetworkSearch = (networks: Network[], searchString: string) => {
  try {
    if (!searchString || !networks) return networks

    return networks
      .map((item) => {
        const displayNameSearchScore = item?.networkInfo?.displayName
          ? getFilterScore(item.networkInfo.displayName, searchString)
          : 0
        const shortNameSearchScore = item?.networkInfo?.shortName
          ? getFilterScore(item.networkInfo.shortName, searchString)
          : 0

        const score = Math.max(shortNameSearchScore, displayNameSearchScore)

        if (!score) return null
        return { ...item, priority: score }
      })
      .filter((item) => item)
      .sort((item1, item2) => item2.priority - item1.priority) as Network[]
  } catch {
    return networks
  }
}
