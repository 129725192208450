import BigNumber from 'bignumber.js'
import { ColumnsDefTypes } from 'UIKit/Table/type'
import { ProfileTierInfo } from 'config/types/profile'
import { Referee, ReferralCampaign } from 'config/types/referral'

export type { ColumnType } from 'UIKit/Table/type'

export const MAX_COMMISSION = 100
export const OLD_COMMISSION_RATE = 1.5

export enum ReferralTabEnums {
  DASHBOARD = 1,
  CAMPAIGNS = 2,
  REFERRALS = 3,
  ABOUT_REFERRAL = 4,
}

export enum ReferralSortBy {
  CREATED = 0,
  PROFIT = 1,
}

export const RefereeTableColumnSchema: ColumnsDefTypes[] = [
  {
    id: 1,
    name: 'date',
    label: 'Date',
    width: '10%',
  },
  {
    id: 2,
    name: 'campaignName',
    label: 'Campaign',
    width: '30%',
  },
  {
    id: 3,
    name: 'user',
    label: 'User',
    width: '10%',
  },
  {
    id: 4,
    name: 'totalDeposits',
    label: 'Total Deposits',
    width: '15%',
  },
  {
    id: 5,
    name: 'wager',
    label: 'Wager',
    width: '15%',
  },
  {
    id: 6,
    name: 'referralCommisionPercent',
    label: 'You Get / Friends Get',
    width: '10%',
  },
]

export interface RowProps {
  date: string
  campaignName: string
  user: string
  totalDeposits: { tokenCode: string; value: BigNumber }[]
  wager: { tokenCode: string; value: BigNumber }[]
  referralCommisionPercent: string
  referrerUserCode: string
}

export interface CampaignsHistoryRowProps {
  code: string
  name: string
  referrals: number
  comissionShares: {
    userCommission: number
    shareCommission: number
  }
  totalProfitUSD: string
  isDefault: boolean
  origin: {
    campaign: ReferralCampaign
  }
}

export interface ReferralsHistoryRowProps {
  user: {
    avatar: string
    displayName: string
    registeredAt: number
    userCode: string
    userTier: ProfileTierInfo
  }
  comissionShares: {
    userCommission: number
    shareCommission: number
  }
  totalDepositUSD: string
  totalWagerUSD: string
  totalProfitUSD: string

  origin: {
    referee: Referee
  }
}

export const referralCampaignsSchema: ColumnsDefTypes[] = [
  {
    id: 0,
    name: 'name',
    label: 'Name',
    width: '25%',
  },
  {
    id: 1,
    name: 'totalProfitUSD',
    label: 'My Profit',
    width: '15%',
  },
  {
    id: 2,
    name: 'referrals',
    label: 'Referrals',
    width: '15%',
  },
  {
    id: 3,
    name: 'comissionShares',
    label: 'Commission Shares',
    width: '30%',
  },
  {
    id: 4,
    name: 'isDefault',
    label: 'Action',
    width: '15%',
  },
]
export const referralsSchema: ColumnsDefTypes[] = [
  {
    id: 0,
    name: 'user',
    label: 'User',
    width: '25%',
  },
  {
    id: 1,
    name: 'comissionShares',
    label: 'Commission Shares',
    width: '30%',
  },
  {
    id: 1,
    name: 'totalDepositUSD',
    label: 'Total Deposits',
    width: '15%',
  },
  {
    id: 2,
    name: 'totalWagerUSD',
    label: 'Total Wager',
    width: '15%',
  },

  {
    id: 4,
    name: 'totalProfitUSD',
    label: 'My Profit',
    width: '15%',
  },
]
