import Box from 'UIKit/Box/Box'
import Button from 'UIKit/Button'
import FormControl from 'UIKit/FormControl'
import FormInput from 'UIKit/FormControl/FormInput'
import Modal, { ModalBody, ModalProps } from 'UIKit/Modal'
import Text from 'UIKit/Text'
import FormValidator from 'config/constants/formValidator'
import { ValidationError } from 'config/types/validator'
import useForm from 'hooks/useForm'
import useToggle from 'hooks/useToggle'
import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import AuthService from 'services/AuthenticationService'
import { useAppDispatch } from 'state'
import { updateUserSetting } from 'state/profile/actions'
import { HunnyToast } from 'utils/toastify'

interface Disable2FAModalProps {
  variants: 'verify' | 'disable'
}

export const verificationErrorMessages = {
  [ValidationError.Required]: 'Please provide verification code',
  [ValidationError.IncorrectLength]: 'Please enter the 6-digits verification code',
  [ValidationError.InCorrect2FA]: 'Two-Factor Authentication Code is incorrect, please check the code again',
}

const Disable2FAModal: React.FC<ModalProps<Disable2FAModalProps>> = ({ onDismiss }) => {
  const [submiting, toggleSubmiting] = useToggle(false)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { states, controls, isValid } = useForm({
    code: {
      value: '',
      validators: [FormValidator.required, FormValidator.requiredLength(6)],
    },
  })

  const handleVerify2FACode = useCallback(async () => {
    toggleSubmiting()
    const response = await AuthService.disable2FA({ code: states.code.value })
    if (response.code === 'error_auth_totp') {
      controls.code.onErrorChanged([ValidationError.InCorrect2FA])
    } else if (response.code === 'success') {
      HunnyToast.success(t('Disable 2FA successfully!'))
      dispatch(updateUserSetting({ settings: { isActive2FA: false } }))
      onDismiss()
    }
    toggleSubmiting()
  }, [states.code.value])

  return (
    <Modal p="24px" width={['', , , , '440px !important']} minWidth="unset !important" onDismiss={onDismiss}>
      <ModalBody borderRadius="0px !important" overflow="hidden !important">
        <Box
          as="form"
          onSubmit={(e) => {
            e.preventDefault()
            handleVerify2FACode()
            return
          }}
        >
          <Text fontSize="16px" fontWeight={600} lineHeight="normal" letterSpacing={-0.32}>
            <Trans>Disable Two-Factor Authentication</Trans>
          </Text>

          <Text
            fontSize="14px"
            lineHeight="normal"
            fontWeight={300}
            letterSpacing={-0.28}
            color="textTertiary"
            my="24px"
          >
            <Trans>To disable Two-Factor authentication, enter your Two-Factor Authentication Code.</Trans>
          </Text>

          <FormControl
            label={t('Two-Factor Authentication Code')}
            state={states.code}
            formatErrorMessage={(e) => t(verificationErrorMessages[e[0]])}
          >
            <FormInput
              tabIndex={1}
              control={controls.code}
              placeholder={t('Enter code')}
              adornmentType="PasteFromClipboard"
              type="text"
              name="code"
            />
          </FormControl>

          <Button
            variant="error"
            mt="24px"
            disabled={!isValid || !states.code.value || submiting}
            height="46px !important"
            type="submit"
            width="100%"
            id="disable-2fa-submit-button"
          >
            <Text fontSize="14px" fontWeight={600} letterSpacing={-0.28}>
              <Trans>Disable</Trans>
            </Text>
          </Button>
        </Box>
      </ModalBody>
    </Modal>
  )
}

export default Disable2FAModal
