import Input from 'UIKit/Input'
import styled from 'styled-components'
import { layout, space } from 'styled-system'
import { CheckboxProps } from './types'

const Checkbox = styled(Input).attrs({ type: 'checkbox' })<CheckboxProps>`
  appearance: none;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s ease-in-out;
  border: 1px solid ${({ theme }) => theme.colors.textSubtle};
  border-radius: ${({ theme }) => theme.radii.tiny};
  margin: 0;
  display: block;

  &:after {
    content: '';
    position: absolute;
    border-bottom: 2px solid;
    border-left: 2px solid;
    border-color: transparent;
    top: 30%;
    left: 0;
    right: 0;
    width: 50%;
    height: 25%;
    margin: auto;
    transform: rotate(-50deg);
    transition: border-color 0.2s ease-in-out;
  }

  &:checked {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    border: none;
    &:after {
      border-color: rgb(21, 18, 29);
    }
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.strokeAlt};
  }

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }

  ${layout}
  ${space}
`

export default Checkbox
