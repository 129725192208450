import Button from 'UIKit/Button'
import Image from 'UIKit/Image'
import Modal, { ModalBody, ModalProps } from 'UIKit/Modal'
import Text from 'UIKit/Text'
import { RouteConfig } from 'config/constants/route'
import { DepositFiatTransaction, Transaction } from 'config/types/transaction'
import useModal, { useCloseAllModal } from 'hooks/useModal'
import { usePrivateSocket } from 'hooks/usePrivateSocket'
import { useRouter } from 'hooks/useRouter'
import { ColumnCenter } from 'layout/Components/Column'
import { RowBetween, RowCenter } from 'layout/Components/Row'
import { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useAuth } from 'state/auth/hooks'
import theme from 'theme'
import { colors } from 'theme/colors'
import { formatDisplayFullDateTime } from 'utils/dateHelper'
import { getFullDisplayBalance } from 'utils/formatBalance'
import DepositTransactionDetailsModal from 'views/Profile/Transactions/components/Deposit/DepositTransactionDetailsModal'
import { TransactionHash } from 'views/Profile/Transactions/components/styled'
import { useResumeProgressingDepositFiat } from './hooks'

const DepositInProgressModal: React.FC<
  ModalProps<{ paymentFiatTransaction: DepositFiatTransaction; transactionDetail: Transaction }>
> = ({ data: { paymentFiatTransaction, transactionDetail }, onDismiss }) => {
  const router = useRouter()
  const { onResume, countdownTime } = useResumeProgressingDepositFiat(paymentFiatTransaction.createdAt)
  const closeAllModal = useCloseAllModal()
  const { isSigned } = useAuth()
  const socket = usePrivateSocket()
  const [onPresentDepositDetails] = useModal(DepositTransactionDetailsModal)

  useEffect(() => {
    if (!socket || !isSigned) return
    socket.on('deposit.confirmed', (data) => {
      if (data?.txn_hash === paymentFiatTransaction.txnHash) closeAllModal()
    })

    return () => {
      if (socket) {
        socket.off('deposit.confirmed', () => {})
      }
    }
  }, [socket, isSigned, paymentFiatTransaction])

  return (
    <Modal onDismiss={onDismiss}>
      <ModalBody padding="24px">
        <Text fontWeight={600} fontSize="16px" letterSpacing={-0.32} lineHeight="normal">
          <Trans>Deposit in progress</Trans>
        </Text>

        <ColumnCenter mt="20px" style={{ gap: '20px' }}>
          <Image src={'/images/payment/deposit-fiat-in-progress.png'} width={40} height={40} />
          <RowCenter>
            <Text fontSize="20px" fontWeight={600} letterSpacing={-0.4} lineHeight="normal">
              <Trans>Deposit in progress</Trans>
            </Text>
          </RowCenter>

          <ColumnCenter
            borderRadius={theme.radii.small}
            border={'1px solid #adadad0f'}
            background={colors.backgroundAlt5}
            padding="20px"
            style={{
              gap: '16px',
            }}
          >
            <RowBetween width="100%">
              <Text
                color="textSubtle"
                fontSize={['12px', , , '16px']}
                fontWeight={400}
                lineHeight="normal"
                letterSpacing={-0.32}
              >
                <Trans>Amount</Trans>:
              </Text>

              <Text fontSize={['12px', , , '16px']} fontWeight={400} color="textTertiary" letterSpacing={-0.32}>
                {getFullDisplayBalance(paymentFiatTransaction.amount.amount)}{' '}
                <Text
                  fontSize={['12px', , , '16px']}
                  fontWeight={400}
                  lineHeight="normal"
                  letterSpacing={-0.48}
                  color="textSubtle"
                  ml="2px"
                  as="span"
                >
                  {paymentFiatTransaction.amount.token.name}
                </Text>
              </Text>
            </RowBetween>
            <RowBetween width="100%">
              <Text
                color="textSubtle"
                fontSize={['12px', , , '16px']}
                fontWeight={400}
                lineHeight="normal"
                letterSpacing={-0.32}
              >
                <Trans>Payment method</Trans>:
              </Text>

              <Text fontSize={['12px', , , '16px']} fontWeight={400} color="textTertiary" letterSpacing={-0.32}>
                {paymentFiatTransaction.name}
              </Text>
            </RowBetween>

            <RowBetween width="100%">
              <Text
                color="textSubtle"
                fontSize={['12px', , , '16px']}
                fontWeight={400}
                lineHeight="normal"
                letterSpacing={-0.32}
              >
                <Trans>Transaction ID</Trans>:
              </Text>

              <TransactionHash
                txnHash={paymentFiatTransaction.txnHash}
                chainId={paymentFiatTransaction.amount.token.network}
                hideHyperLink
                onClick={() => {
                  if (transactionDetail) onPresentDepositDetails({ transaction: transactionDetail, bonusInfo: null })
                }}
              />
            </RowBetween>

            <RowBetween width="100%">
              <Text
                color="textSubtle"
                fontSize={['12px', , , '16px']}
                fontWeight={400}
                lineHeight="normal"
                letterSpacing={-0.32}
              >
                <Trans>Date</Trans>:
              </Text>

              <Text fontSize={['12px', , , '16px']} fontWeight={400} color="textTertiary" letterSpacing={-0.32}>
                {formatDisplayFullDateTime(new Date(paymentFiatTransaction.createdAt))}
              </Text>
            </RowBetween>
          </ColumnCenter>

          <Button
            width="100%"
            variant="primary"
            maxWidth={'432px !important'}
            onClick={() => {
              router.push(RouteConfig.DepositTransactionHistory)
              onDismiss()
            }}
          >
            <Text fontSize="14px" fontWeight={600} letterSpacing={-0.28} lineHeight="normal">
              <Trans>I've Made Deposit</Trans>
            </Text>
          </Button>
          <Button
            width="100%"
            variant="text"
            maxWidth={'432px !important'}
            onClick={() => {
              if (countdownTime >= new Date()) onResume(paymentFiatTransaction, true)
            }}
            height="fit-content !important"
          >
            <Text fontSize="12px" color="primary" fontWeight={400} letterSpacing={-0.28} lineHeight="normal">
              <Trans>Continue Deposit Process</Trans>
            </Text>
          </Button>
        </ColumnCenter>
      </ModalBody>
    </Modal>
  )
}

export default DepositInProgressModal
