import { ColumnsDefTypes } from 'UIKit/Table/type'

export const SpinWinnerHistoryColumnSchema: ColumnsDefTypes[] = [
  {
    id: 1,
    name: 'createTime',
    label: 'Time',
    width: '40%',
  },
  {
    id: 2,
    name: 'user',
    label: 'User',
    width: '40%',
  },
  {
    id: 3,
    name: 'prize',
    label: 'Prize',
    width: '20%',
  },
]
