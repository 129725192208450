import Button from 'UIKit/Button'
import ConfirmationLayoutModal from 'components/ConfirmationModal/ConfirmationLayoutModal'
import { ModalProps } from 'UIKit/Modal'
import { QuestionDescription, WrapperQuestion } from 'components/Survey/styled'
import useModal from 'hooks/useModal'
import Column from 'layout/Components/Column'
import { Trans } from 'react-i18next'
import BonusService from 'services/BonusService'
import styled from 'styled-components'
import Payment from 'views/Payment'

const WarningDepositBonusModal: React.FC<ModalProps<{ bonusId: number }>> = ({ onDismiss, data: { bonusId } }) => {
  const [presentPaymentModal] = useModal(Payment)

  const handleClick = async () => {
    onDismiss()
    await BonusService.prepareDepositBonus(bonusId)
    presentPaymentModal()
  }
  return (
    <ConfirmationLayoutModal title="There is an actived bonus">
      <WrapperQuestion my={['12px', '', '24px']}>
        <Column mb="24px">
          <QuestionDescription>
            <Trans>To activate this bonus, you must either complete the current wagering bonus or cancel it.</Trans>
          </QuestionDescription>
        </Column>
      </WrapperQuestion>
      <StyledButton onClick={handleClick}>
        <Trans>Deposit Anyway</Trans>
      </StyledButton>
    </ConfirmationLayoutModal>
  )
}

const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 600;
`

export default WarningDepositBonusModal
