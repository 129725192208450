import BigNumber from 'bignumber.js'
import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import { StyledControlContainer } from 'UIKit/FormControl/styled'
import { InputLabel } from 'UIKit/Input/styled'
import NetworkSelect from 'components/NetworkSelect'
import TokenSelect from 'UIKit/TokenSelect'
import { ChainIdEnum } from 'config/constants/network'
import { PaymentMethodEnum } from 'config/constants/payment'
import { HUSD_TOKEN } from 'config/constants/tokens'
import { Network, Token } from 'config/types'
import { WalletType } from 'config/types/wallet'
import { useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useAppSelector } from 'state'
import { useListTokens } from 'state/app/hooks'
import { getNetworkIncludeFiatInfo, getNetworkInfo } from 'utils/network'
import { getNetworkType, validateUserAddressByNetwokType } from 'utils/token'
import ContractInfo from './ContractInfo'

interface PaymentTokenSelectProps extends BoxProps {
  token: Token
  disabled?: boolean
  onTokenSelect: (token: Token) => void
  networks: Network[]
  fetchTokenBalanceFn?: (token: Token) => Promise<BigNumber>
  dropdownHeight?: string
  hideHUSDToken?: boolean
  selectToken?: (token: Token) => boolean
  from?: PaymentMethodEnum
  isIncludeFiat?: boolean
}

const PaymentTokenSelect: React.FC<PaymentTokenSelectProps> = ({
  onTokenSelect,
  token,
  disabled,
  networks,
  fetchTokenBalanceFn,
  dropdownHeight = '200px',
  selectToken,
  hideHUSDToken,
  from,
  isIncludeFiat,
  ...props
}) => {
  const wallet = useAppSelector((state) => state.auth.wallet)
  const tokenInfoes = useAppSelector((state) => state.app.tokenInfoes)
  const fallbackNetwork = useMemo(() => {
    return tokenInfoes.find((token) =>
      wallet?.type === WalletType.SOL
        ? token.network === ChainIdEnum.SOL || token.network === ChainIdEnum.SOL_TESTNET
        : token,
    )?.network
  }, [])

  const initialNetwork = token.network === ChainIdEnum.HPN ? fallbackNetwork : token.network
  const [chainId, setChainId] = useState<ChainIdEnum>(initialNetwork)
  const networkInfo = useMemo(
    () => (isIncludeFiat ? getNetworkIncludeFiatInfo(chainId) : getNetworkInfo(chainId)),
    [chainId, isIncludeFiat],
  )

  const listToken = useListTokens()

  const parsedListToken = useMemo(() => {
    const selectedTokens = listToken.filter(
      (token) => (selectToken ? selectToken(token) : true) && token.network === chainId,
    )

    return hideHUSDToken ? selectedTokens : [...selectedTokens, HUSD_TOKEN]
  }, [listToken, chainId])

  const handleSelectNetwork = (network: Network) => {
    setChainId(network.chainId)

    const { native } = isIncludeFiat
      ? getNetworkInfo(network.chainId)
      : getNetworkIncludeFiatInfo(network.chainId, { getFiatDefaultNative: true })

    if (!selectToken || selectToken(native)) onTokenSelect(native)
    else {
      onTokenSelect(listToken.find((t) => t.network === network.chainId && selectToken(t)))
    }
  }
  const isValidAddress = validateUserAddressByNetwokType(wallet?.address, getNetworkType(networkInfo?.network?.chainId))

  return (
    <Box {...props}>
      <StyledControlContainer state={null}>
        <InputLabel>
          <Trans>Select Network</Trans>
        </InputLabel>
        <NetworkSelect
          disabled={disabled}
          options={networks}
          value={networkInfo?.network}
          onNetworkChange={handleSelectNetwork}
          dropdownContentProps={{
            maxHeight: `${dropdownHeight} !important`,
          }}
        />
      </StyledControlContainer>

      <StyledControlContainer state={null} mt="16px">
        <InputLabel>
          <Trans>Select Coin</Trans>
        </InputLabel>
        <TokenSelect
          disabled={disabled}
          value={token}
          onTokenChange={(token) => onTokenSelect(token)}
          fetchTokenBalanceFn={isValidAddress ? fetchTokenBalanceFn : null}
          options={parsedListToken}
          dropdownContentProps={{
            maxHeight: `${dropdownHeight} !important`,
          }}
        />

        {token?.address && !token?.isNative && <ContractInfo from={from} token={token} mt="20px" />}
      </StyledControlContainer>
    </Box>
  )
}

export default PaymentTokenSelect
