import Button from 'UIKit/Button'
import CopyButton from 'UIKit/Button/CopyButton'
import Heading from 'UIKit/Heading'
import Link from 'UIKit/Link'
import Modal, { ModalBody, ModalHeader, ModalProps } from 'UIKit/Modal'
import Text from 'UIKit/Text'
import { NETWORK_MAP } from 'config/constants/network'
import { PaymentMethodEnum } from 'config/constants/payment'
import { Token } from 'config/types'
import { RowBetween, RowMiddle } from 'layout/Components/Row'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Icons } from 'svgs'
import theme from 'theme'
import { getBlockExploreLink } from 'utils/network'

const ContractInfoModal: React.FC<ModalProps<{ token: Token; from: PaymentMethodEnum }>> = ({ data, ...props }) => {
  const { t } = useTranslation()
  const chain = useMemo(() => NETWORK_MAP[data.token.network], [data.token])

  const [url, formatAddress] = useMemo(() => {
    const address = data?.token?.address
    if (!chain || !address) return ''

    return [getBlockExploreLink(address, 'token', data.token.network), data.token.address]
  }, [data.token, chain])

  return (
    <Modal minWidth="100% !important" maxWidth="480px !important" {...props}>
      <ModalHeader background={theme.colors.cardBackground} px={['16px', '', '24px']}>
        <RowMiddle width="100%" justifyContent={['flex-start', '', 'center']}>
          <Heading scale="xs" textAlign="center">
            <Trans>Contract Information</Trans>
          </Heading>
        </RowMiddle>
      </ModalHeader>
      <ModalBody background={`${theme.colors.cardBackground} !important`} p={['16px', '', '24px']} pt="0 !important">
        <Text fontSize="12px" color="textTertiary" textAlign={['left', 'left', 'center']} lineHeight={1.5}>
          <Trans>
            {data?.from === PaymentMethodEnum.Deposit
              ? 'Please ensure that the contract address for the token has the same contract address as the token deposited. Deposit of tokens which is different from the contract address listed will result in the asset to be lost. Current contract address detail.'
              : 'Please ensure that the contract address for the token has the same contract address as the token to be withdrawn. Withdrawal of tokens which is different from the contract address listed will result in the asset to be lost. Current contract address details.'}
          </Trans>
        </Text>
        <RowBetween
          p="12px 8px"
          style={{
            borderRadius: '8px',
          }}
          border={`1px solid  ${theme.colors.normal}`}
          my="16px"
        >
          <Text ellipsis={2} color="textAlt1" small wordBreak="break-all" lineHeight={1.4} mr="16px">
            {formatAddress}
          </Text>
          <CopyButton text={url}>
            <StyledCopyIcon stroke={theme.colors.textAlt1} width={20} height={20} />
          </CopyButton>
        </RowBetween>
        <Link width="100%" href={url} external>
          <Button
            width="100%"
            height="40px"
            style={{
              fontWeight: 600,
              borderRadius: theme.radii.tiny,
              fontSize: '14px',
            }}
          >
            {t('View on {{name}}', {
              name: chain.blockExplorerName || '',
            })}
          </Button>
        </Link>
      </ModalBody>
    </Modal>
  )
}

const StyledCopyIcon = styled(Icons.CopyIcon)`
  path {
    fill: ${({ theme }) => theme.colors.textAlt1};
  }
`
export default ContractInfoModal
