import Text from 'UIKit/Text'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { ColumnCenter } from 'layout/Components/Column'
import { RowCenter } from 'layout/Components/Row'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Icons } from 'svgs'
import theme from 'theme'
import { colors } from 'theme/colors'
import RewardThumbnail from './RewardThumbnail'

const RewardCard: React.FC<{
  rewardDetail: any
  isActive: boolean
  isHighlight: boolean
  refReward: any
}> = ({ rewardDetail, isActive, isHighlight, refReward }) => {
  const { isMobile } = useMatchBreakpoints()

  const isFocusBackground = isActive
  const { t } = useTranslation()

  return (
    <CardStyled ref={refReward} width={!isMobile ? '120px !important' : 'unset'}>
      <Text
        fontSize="12px"
        fontWeight={isActive ? 500 : 400}
        color={isFocusBackground ? '#fff' : colors.textAlt1}
        mb="4px"
      >
        {t('Day {{day}}', {
          day: rewardDetail.day,
        })}
      </Text>
      <RewardCardStyled
        p="12px 4px"
        borderRadius={theme.radii.small}
        height={isMobile ? '100px' : '80px'}
        width="100%"
        justifyContent="center !important"
        background={
          isHighlight
            ? 'linear-gradient(0deg, rgba(22, 25, 31, 0.20) 0%, rgba(22, 25, 31, 0.20) 100%), radial-gradient(155.59% 167.7% at 0% -6.63%, rgba(233, 96, 175, 0.40) 0%, rgba(235, 100, 178, 0.36) 40.38%, rgba(255, 133, 203, 0.00) 85.72%)'
            : isFocusBackground
            ? 'linear-gradient(180deg, #FFF -30.38%, rgba(255, 255, 255, 0.00) 100%)'
            : rewardDetail?.userData?.isClaimed
            ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.00) 65.91%)'
            : 'linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.00) 65.91%)'
        }
        isFocus={isFocusBackground}
      >
        <RewardThumbnail rewardDetail={rewardDetail} isClaimed={rewardDetail.userData?.isClaimed} />

        {rewardDetail.displayName && (
          <Text
            color={isFocusBackground ? '#fff' : theme.colors.textTertiary}
            fontSize="12px"
            fontWeight={isFocusBackground ? 700 : 400}
            mt="4px"
            textAlign="center"
            lineHeight={1.2}
          >
            {rewardDetail.displayName}
          </Text>
        )}
        {rewardDetail?.userData?.isClaimed && (
          <RowCenter
            width={['22px', '', '30px']}
            height={['10px', '', '14px']}
            position="absolute"
            top="0px"
            right="0px"
            p="10px 5px"
            background={theme.colors.backgroundAlt5}
            borderBottomLeftRadius={theme.radii.small}
          >
            <Icons.SuccessIcon width={isMobile ? '12px' : '16px'} fill={colors.success} />
          </RowCenter>
        )}
      </RewardCardStyled>
    </CardStyled>
  )
}

const RewardCardStyled = styled(ColumnCenter)<{ isFocus: boolean }>`
  position: relative;
  overflow: hidden;
  justify-content: flex-start !important;
  ${(props) =>
    props.isFocus
      ? `
      border:  2px solid #fff;
      &::before {
        position: absolute;
        top: -13px;
        display: block;
        content:'';
        background-color: #fff;
        width: 16px;
        height:  16px;
        transform: rotate(45deg)
      }
      `
      : ''}
`

const CardStyled = styled(ColumnCenter)``

export default RewardCard
