import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import React from 'react'
import styled from 'styled-components'
import { Icons } from 'svgs'
import hunnyKeyframes from 'theme/keyframes'

const CircleLoader: React.FC<{ scale?: number } & BoxProps> = ({ scale = 1, ...props }) => {
  return (
    <Wrapper $scale={scale} {...props}>
      <Icons.CircleLoadingBackgroundIcon className="circle-bg" />
      <StyledSpin>
        <Icons.CircleLoadingIcon className="circle-loading" />
      </StyledSpin>
    </Wrapper>
  )
}

const Wrapper = styled.span<{ $scale: number }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  svg {
    transform: scale(${({ $scale }) => $scale});
  }
`

const StyledSpin = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  animation: ${hunnyKeyframes.rotate} 2s linear infinite;
`
export default React.memo(CircleLoader)
