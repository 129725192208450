import Text from 'UIKit/Text'
import { useMemo, useRef } from 'react'
import { Trans } from 'react-i18next'
import { useDailyRewardCampaign } from 'state/bonus/hooks/dailyReward'
import theme from 'theme'
import { colors } from 'theme/colors'

const CountdownToNextDay: React.FC<{
  isTodayClaimed: boolean
  wagerRequiredAmount: number
  isCompletedCampaign: boolean
}> = ({ isTodayClaimed, wagerRequiredAmount, isCompletedCampaign }) => {
  const { campaign } = useDailyRewardCampaign()

  const currentTimeInDay = useRef(Date.now() % (1000 * 60 * 60 * 24))

  const waitingHour = Number(
    isCompletedCampaign
      ? ((Number(campaign?.finishAt) - Date.now() / 1000) / (60 * 60)).toFixed(0)
      : ((1000 * 60 * 60 * 24 - currentTimeInDay.current) / (1000 * 60 * 60)).toFixed(0),
  )

  const waitingTime = useMemo(() => (waitingHour < 24 ? waitingHour : Math.ceil(waitingHour / 24)), [waitingHour])

  return (
    <Text textAlign="center" color={theme.colors.textTertiary} fontSize="12px" fontWeight={400} letterSpacing={-0.24}>
      {isTodayClaimed || isCompletedCampaign ? (
        <Trans
          i18nKey={Number(waitingHour) < 24 ? 'comebackAfterClaim' : 'comebackAfterMultipleDays'}
          values={{
            waitingTime,
          }}
          components={{
            highlight_tag: <Text as="span" fontSize="12px" color={colors.text} fontWeight={600} />,
          }}
        />
      ) : (
        wagerRequiredAmount > 0 && (
          <Trans
            i18nKey="wagerRequiredAmount"
            values={{
              wagerRequiredAmount,
            }}
            components={{
              highlight_tag: <Text as="span" fontSize="12px" color={colors.text} fontWeight={600} />,
            }}
          />
        )
      )}
    </Text>
  )
}

export default CountdownToNextDay
