import BigNumber from 'bignumber.js'
import Box from 'UIKit/Box/Box'
import Skeleton from 'UIKit/Skeleton'
import Table from 'UIKit/Table'
import { TableBody } from 'UIKit/Table/Table'
import TableCell from 'UIKit/Table/TableCell'
import TableHeader from 'UIKit/Table/TableHeader'
import TableRow from 'UIKit/Table/TableRow'
import Text from 'UIKit/Text'
import configTokens from 'config/constants/tokens'
import { SpinPrizeTransaction } from 'config/types/luckyspin'
import { usePublicSocket } from 'hooks/usePublicSocket'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import LuckySpinService from 'services/LuckySpinService'
import { parseSpinPrizeTransaction } from 'services/mapper/LuckySpin'
import styled from 'styled-components'
import { range } from 'utils'
import PrizeWinnerRow from './PrizeWinnerRow'

const UserWinTable = () => {
  const [transactions, setTransactions] = useState<SpinPrizeTransaction[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { execute } = useRequest()

  const socket = usePublicSocket()

  useEffect(() => {
    if (!socket) {
      return
    }

    socket.on('lucky_spin.rewarded', (data) => {
      if (data) {
        const parseData = parseSpinPrizeTransaction(data)
        if (parseData) {
          setTransactions((preState) => [parseData, ...preState])
        }
      }
    })

    return () => {
      if (socket) {
        socket.off('lucky_spin.rewarded')
      }
    }
  }, [socket])

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      const response = await execute(LuckySpinService.getPrizeTransactions(10))

      if (response?.data) {
        const transactions = range(0, 10)
          .map((_, index) => {
            if (response.data[index]) return response.data[index]

            return null
          })
          .filter((item) => item)

        setTransactions(transactions)
      }
      setIsLoading(false)
    }

    fetch()
  }, [])

  return (
    <StyledContainer className="prize-table-container">
      <Box position="relative" maxHeight="570px" overflowY="hidden">
        <StyledTable>
          <thead>
            <tr>
              <TableHeader key={'time'} padding="16px" width={'40%'} background="none !important">
                <Text textAlign="left" fontSize="16px" bold color="textSubtle">
                  <Trans>Time</Trans>
                </Text>
              </TableHeader>
              <TableHeader key={'user'} padding="16px" width={'40%'} background="none !important">
                <Text textAlign="left" fontSize="16px" bold color="textSubtle">
                  <Trans>User</Trans>
                </Text>
              </TableHeader>
              <TableHeader key={'prize'} padding="16px" width={'20%'} background="none !important">
                <Text textAlign="left" fontSize="16px" bold color="textSubtle">
                  <Trans>Prize</Trans>
                </Text>
              </TableHeader>
            </tr>
          </thead>
          <TableBody className="prize-table-body">
            {isLoading ? (
              <>
                {range(0, 10).map((idx) => (
                  <TableRow key={`table-row-loader-${idx}`}>
                    <TableCell className="loader" colSpan={100} pl="0px !important" border="none !important">
                      <Skeleton width="100%" height="52px" my={1} />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <>
                {transactions.map((item) => {
                  const token = configTokens[item.network][item.currency]
                  if (token)
                    return (
                      <PrizeWinnerRow
                        user={{
                          avatar: item.userProfile.avatar,
                          displayName: item.userProfile.displayName,
                          code: item.userProfile.code,
                        }}
                        createTime={item.createTime}
                        prize={{
                          amount: new BigNumber(item.amount),
                          token,
                        }}
                        key={`table-row-${item.id}`}
                      />
                    )

                  return <></>
                })}
              </>
            )}
          </TableBody>
        </StyledTable>
      </Box>
    </StyledContainer>
  )
}

const StyledContainer = styled(Box)`
  margin-top: 32px;

  .container {
    color: ${({ theme: { colors } }) => colors.text};

    td {
      height: 52px;
      border-bottom: ${({ theme: { colors } }) => `1px solid ${colors.stroke}`};
    }

    .create-time,
    .address,
    .prize-value-container {
      padding-left: 12px;
    }

    .prize-value-container {
      display: flex;
      align-items: center;

      .logo-container {
        position: relative;
        max-width: 30px;

        .token-logo {
          max-width: 25px;
          max-height: 25px;
        }

        .network-logo {
          position: absolute;

          max-width: 15px;
          max-height: 15px;

          top: 0px;
          right: 0px;
        }
      }

      .prize-value {
        font-weight: 600;
        margin-left: 8px;
      }
    }
  }
`

const StyledTable = styled(Table)`
  border-collapse: unset;
`

export default UserWinTable
