import BigNumber from 'bignumber.js'
import { FlexProps } from 'UIKit/Box/types'
import Text from 'UIKit/Text'
import { RowMiddle } from 'layout/Components/Row'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { getFullDisplayBalance } from 'utils/formatBalance'
import USDAmountDisplay from 'views/Fiat/USDAmountDisplay'

interface StatisticTagInfoProps {
  title: string
  amount: number
  disabledFiatDisplay?: boolean
}

const StatisticTagInfo: React.FC<StatisticTagInfoProps & FlexProps> = ({
  title,
  amount,
  disabledFiatDisplay = false,
  ...props
}) => {
  return (
    <StyledTagContainer {...props}>
      <StyledTagTitle>
        <Trans>{title}</Trans>
      </StyledTagTitle>
      <Text
        color={Number(amount) ? 'success' : 'textAlt1'}
        fontWeight={700}
        fontSize="16px"
        lineHeight="normal"
        letterSpacing={-0.32}
        as="span"
        ml={1}
      >
        {disabledFiatDisplay ? getFullDisplayBalance(BigNumber(amount), 0, 0) : <USDAmountDisplay amount={amount} />}
      </Text>
    </StyledTagContainer>
  )
}

const StyledTagContainer = styled(RowMiddle)`
  width: max-content;
  background: rgba(255, 255, 255, 0.08);
  border-radius: ${({ theme }) => theme.radii.extraLarge};
  color: ${({ theme }) => theme.colors.textSubtle};
  padding: 4px 12px;
`

const StyledTagTitle = styled(Text)`
  color: ${({ theme: { colors } }) => colors.textSubtle};
  font-size: 14px;
  line-height: normal;
  letter-spacing: -0.28px;
  font-weight: 300;
`

export default StatisticTagInfo
