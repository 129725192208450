import BigNumber from 'bignumber.js'
import { ChainIdEnum } from 'config/constants/network'
import tokens, { HUSD_TOKEN, USD_CURRENCY } from 'config/constants/tokens'
import { RefToken } from 'config/types'
import {
  ApiBonusTypeEnums,
  BonusInfomation,
  BoosterWagerCommonBonus,
  CashCommonBonus,
  CommonBonus,
  DepositCashCommonBonus,
  DepositCommonBonus,
  DepositFreeSpinCommonBonus,
  FreeHUSDLockCommonBonus,
  FreeLuckyspinCommonBonus,
  NoWageringCommonCashBonus,
  FreeSpinCommonBonus,
  HUSDUnLockBoosterCommonBonus,
  IBonusCondition,
  IBoosterWagerBonus,
  ICommonBonus,
  IDepositCommonBonus,
  IFreeHUSDLockCommonBonus,
  IFreeLuckyspinCommonBonus,
  INoWageringCommonCashBonus,
  IFreeSpinCommonBonus,
  IHUSDUnLockBoosterCommonBonus,
  ILevelUpCommonBonus,
  IWageringCondition,
  LevelUpCommonBonus,
} from 'config/types/bonus'
import {
  AdventureUserRewardResponse,
  AdventureUserStatsReward,
  InfoReward,
  WagerAdventure,
} from 'config/types/bonus/WagerAdventure'
import { BonusCampaignItem, BonusesCampaign } from 'config/types/bonus/bonusesCampaigns'
import {
  BoosterWagerBonus,
  CashBonus,
  DepositBonus,
  DepositCashBonus,
  DepositFreespinBonus,
  FreeHUSDLockBonus,
  FreeLuckyspinBonus,
  NoWageringCashBonus,
  FreespinBonus,
  HUSDUnLockBoosterBonus,
  IUserBonus,
  IUserWageringCashoutMilestone,
  IUserWageringCondition,
  LevelUpBonus,
  UserBonus,
} from 'config/types/bonus/userBonus'
import { WelcomePackItemType } from 'config/types/bonus/welcomePackage'
import { BaseResponse, Paging } from 'services/types'
import { formatApiNetworkField } from 'utils'
import { formatNumber } from 'utils/formatBalance'

export const parseDataWelcomePack = (data: any) => ({
  id: data.id,
  name: data.name,
  packages: data.config.packages.map((item: any): WelcomePackItemType => {
    return {
      code: item.code,
      thumbnail: `/images/bonusCenter/${item.code}-badge.png`,
      bonuses: item.vouchers.map(({ voucher }) => parsePublicBonus(voucher)),
    }
  }),
})

export const UserBonusMapper = (rawResponse: string): BaseResponse<Paging<UserBonus>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      ...response.data,
      items: response.data.items?.map((item) => parseUserBonus(item)).filter((item) => item) || [],
      selectedDepositBonusId: response.data.deposit_voucher_user_id,
    },
  }
}

export const FreeBonusesCampaignMapper = (rawResponse: string): BaseResponse<BonusesCampaign> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && parseFreeBonusCampaign(response.data),
  }
}

export const PublicBonusDetailsMapper = (rawResponse: string): BaseResponse<CommonBonus> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)
  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && parsePublicBonus(response.data.voucher),
  }
}

export const UserBonusDetailsMapper = (rawResponse: string): BaseResponse<UserBonus> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && parseUserBonus(response.data.voucher),
  }
}

export const UserWageringBonusGameCodesMapper = (rawResponse: string): BaseResponse<string[]> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data?.list_code || [],
  }
}

export const UserBonusRedeemMapper = (
  rawResponse: string,
): BaseResponse<{
  error: string | null
  bonus: CommonBonus
}> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: {
      error: response?.data?.error,
      bonus: response?.data?.voucher ? parsePublicBonus(response?.data?.voucher) : null,
    },
  }
}

export const PrepareCancelBonusMapper = (rawResponse: string): BaseResponse<{ token: RefToken; amount: string }> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.code === 'success' &&
      response.data && {
        token: { token: response.data.currency, network: ChainIdEnum[formatApiNetworkField(response.data.network)] },
        amount: response.data.deduct_amount,
      },
  }
}

export const UserBonusApplyMapper = (rawResponse: string): BaseResponse<boolean> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.code === 'success',
  }
}

export const parseFreeBonusCampaign = (apiCampaign: any): BonusesCampaign => {
  return {
    title: apiCampaign.campaign_title,
    startDate: apiCampaign.from_time * 1000,
    endDate: apiCampaign.to_time * 1000,
    bonuses: apiCampaign.bonuses
      ?.filter((bonus) => bonus?.voucher_info?.status !== -1)
      ?.map(
        (bonus) =>
          ({
            code: bonus.code,
            bonus: parsePublicBonus(bonus.voucher_info),
            userData: {
              isRedeemed: bonus.claimed || false,
            },
            dateTitle: bonus.date_title,
            displayName: bonus.display_name,
            percent: Number(formatNumber((bonus.distribute_count / bonus.quota) * 100, 0, 2)),
            totalFreeBonusAmount: bonus.quota,
            startDate: bonus.voucher_info.from_time * 1000,
            waitingImage: '/images/bonusCenter/free-bonus-waiting-1.png',
            minVipLevel: bonus.min_level,
            maxVipLevel: bonus.max_level,
          } as BonusCampaignItem),
      ),
    additionalContent: {
      imageUrl: apiCampaign.additional_image_url,
      content: apiCampaign.additional_text,
    },
  }
}

const mapBonusLevelAdventure = (rowData: any, rewardFromLevel: number) => {
  if (!rowData) return []

  const rewards: InfoReward[] = rowData
    ?.filter((bonus) => bonus?.data?.status !== -1)
    ?.map((bonus, index) => ({
      level: index + rewardFromLevel,
      wagerUsd: bonus.to_wager_usd,
      bonusName: bonus.display_name,
      bonus: parsePublicBonus(bonus.data),
    }))

  return rewardFromLevel > 1
    ? [
        {
          level: 1,
          wagerUsd: '0',
          bonusName: '',
          bonus: null,
        },
        ...rewards,
      ]
    : rewards
}
export const WagerAdventureMapper = (rawResponse: any): BaseResponse<WagerAdventure> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  const result = response.data

  if (!result) {
    return {
      code: response.code,
      data: null,
    }
  }

  const rewardByLevels = mapBonusLevelAdventure(result.reward_by_levels, 2)
  const rewardTopPlayers = mapBonusLevelAdventure(result.reward_top_players, 1)

  return {
    code: response.code,
    message: response.message,
    data: {
      id: result.id,
      title: result.campaign_title,
      startDate: result.from_time * 1000,
      endDate: result.to_time * 1000,
      gameCollectionId: Number(result.game_collection_id),
      intro: result.intro,
      rule: result.rule,
      name: result.name,

      rewardByLevels,
      rewardTopPlayers,
    },
  }
}

export const UserWagerAdventureRewardMapper = (rawResponse: any): BaseResponse<AdventureUserRewardResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  const result = response.data

  return {
    code: response.code,
    message: response.message,
    data: result.items.map((result, index) => ({
      avatar: result.avatar_url,
      displayName: result.display_name,
      rank: index + 1,
      level: result.level,
      tierId: result.tier_id,
      code: result.user_code,
      wagerUsd: result.wager_amount_usd,
    })),
  }
}
export const UserStatsWagerAdventureRewardMapper = (rawResponse: any): BaseResponse<AdventureUserStatsReward> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  const result = response.data

  return {
    code: response.code,
    message: response.message,
    data: {
      avatar: result?.avatar_url || '',
      displayName: result?.display_name || '',
      level: result?.level || 0,
      code: result?.user_code || '',
      wagerUsd: result?.wager_amount_usd || 0,
    },
  }
}
const parseWageringConditionCashoutMilestones = (apiBonus: any): IUserWageringCashoutMilestone => {
  const milestoneResults = apiBonus.data.result.cashout_result?.milestone_result
  const milestoneResultCodes = apiBonus.data.result.cashout_result?.milestone_result?.map((item) => item?.code)
  const milestoneClaimToken =
    milestoneResults?.length > 0 &&
    tokens[ChainIdEnum[formatApiNetworkField(milestoneResults[0]?.bonus_network)]]?.[
      milestoneResults[0]?.bonus_currency
    ]

  const milestoneData =
    apiBonus.data.setting.cashout_condition?.claim_milestones?.length > 1
      ? apiBonus.data.setting.cashout_condition?.claim_milestones?.map((item) => ({
          code: item.code,
          wagerPercentage: item.wager_percentage,
          bonusPercentage: item.bonus_percentage,
          wagerAmount: new BigNumber(
            apiBonus.voucher_cashout_type === 2
              ? apiBonus.data.result.bet_result.to_wager_currency
              : apiBonus.data.result.bet_result.to_wager_usd,
          )
            .dividedBy(100)
            .multipliedBy(item.wager_percentage),
          bonusAmount: new BigNumber(
            (apiBonus.data.result?.bet_result?.bonus_amount !== '0' &&
              apiBonus.data.result?.bet_result?.bonus_amount) ||
              apiBonus.data.setting?.bonus_amount_condition?.amount ||
              '0',
          )
            .dividedBy(100)
            .multipliedBy(item.bonus_percentage),
          isClaimed: milestoneResultCodes?.includes(item.code),
          token: milestoneClaimToken,
        }))
      : []

  return {
    milestones: milestoneData,
    milestoneClaimToken,
    isActiveMilestone: !!milestoneData?.length,
  }
}

export const parseUserBonus = (apiBonus: any): UserBonus => {
  const bonus: IUserBonus = {
    id: apiBonus.id,
    apiStatus: apiBonus.status,
    depositId: apiBonus.data.result.deposit_result.deposit_id || null,
    redeemDate: apiBonus.redeem_time * 1000 || 0,
    type: apiBonus.voucher_type,
    bonusUpcoming: apiBonus.voucher_up_coming?.map((item) => parsePublicBonus(item)),
    ...parseCommonBonus(apiBonus),
  }

  const buildWageringConditionData = (): IUserWageringCondition => {
    const wageringBonusData = parseWageringCondition(apiBonus)

    return {
      ...parseWageringConditionCashoutMilestones(apiBonus),
      ...wageringBonusData,
      cashoutAmount: apiBonus.data.result.cashout_result && {
        token: wageringBonusData.bonusAmount.token,
        amount: new BigNumber(apiBonus.data.result.cashout_result.cashout_amount),
      },
      currentWager:
        apiBonus.voucher_cashout_type === 2
          ? apiBonus.data.result.bet_result.current_wager_currency
          : apiBonus.data.result.bet_result.current_wager_usd,
      targetWager:
        apiBonus.voucher_cashout_type === 2
          ? apiBonus.data.result.bet_result.to_wager_currency
          : apiBonus.data.result.bet_result.to_wager_usd,
    }
  }

  switch (apiBonus.voucher_type) {
    case ApiBonusTypeEnums.HusdLockReward:
      return new FreeHUSDLockBonus(bonus, parseFreeHUSDLockCommonBonus(apiBonus))
    case ApiBonusTypeEnums.NoWageringCashBonus:
      return new NoWageringCashBonus(bonus, parseNoWageringCommonCashBonus(apiBonus))
    case ApiBonusTypeEnums.IncreaseVIP:
      return new LevelUpBonus(bonus, parseLevelUpCommonBonus(apiBonus))
    case ApiBonusTypeEnums.LuckspinReward:
      return new FreeLuckyspinBonus(bonus, parseFreeLuckyspinCommonBonus(apiBonus))
    case ApiBonusTypeEnums.HusdUnlockBooster:
      return new HUSDUnLockBoosterBonus(bonus, parseHUSDUnLockBoosterCommonBonus(apiBonus))
    case ApiBonusTypeEnums.BoosterWagerBonus:
      return new BoosterWagerBonus(bonus, parseBoosterWagerCommonBonus(apiBonus))
    case ApiBonusTypeEnums.DepositBonus:
      return new DepositBonus(bonus, buildWageringConditionData(), parseDepositCommonBonus(apiBonus))
    case ApiBonusTypeEnums.CashBonus:
      return new CashBonus(bonus, buildWageringConditionData())
    case ApiBonusTypeEnums.DepositCashBonus:
      return new DepositCashBonus(bonus, buildWageringConditionData())

    case ApiBonusTypeEnums.DepositFreeSpinBonus:
      return new DepositFreespinBonus(bonus, buildWageringConditionData(), parseFreeSpinCommonBonus(apiBonus))
    case ApiBonusTypeEnums.FreeSpinBonus:
      return new FreespinBonus(bonus, buildWageringConditionData(), parseFreeSpinCommonBonus(apiBonus))

    default:
      return null
  }
}

export const parsePublicBonus = (apiBonus: any): CommonBonus => {
  const bonus = { type: apiBonus.type, ...parseCommonBonus(apiBonus) }

  switch (apiBonus.type) {
    case ApiBonusTypeEnums.HusdLockReward:
      return new FreeHUSDLockCommonBonus(bonus, parseFreeHUSDLockCommonBonus(apiBonus))
    case ApiBonusTypeEnums.NoWageringCashBonus:
      return new NoWageringCommonCashBonus(bonus, parseNoWageringCommonCashBonus(apiBonus))

    case ApiBonusTypeEnums.IncreaseVIP:
      return new LevelUpCommonBonus(bonus, parseLevelUpCommonBonus(apiBonus))

    case ApiBonusTypeEnums.LuckspinReward:
      return new FreeLuckyspinCommonBonus(bonus, parseFreeLuckyspinCommonBonus(apiBonus))
    case ApiBonusTypeEnums.BoosterWagerBonus:
      return new BoosterWagerCommonBonus(bonus, parseBoosterWagerCommonBonus(apiBonus))

    case ApiBonusTypeEnums.HusdUnlockBooster:
      return new HUSDUnLockBoosterCommonBonus(bonus, parseHUSDUnLockBoosterCommonBonus(apiBonus))
    case ApiBonusTypeEnums.DepositBonus:
      return new DepositCommonBonus(bonus, parseWageringCondition(apiBonus), parseDepositCommonBonus(apiBonus))

    case ApiBonusTypeEnums.CashBonus:
      return new CashCommonBonus(bonus, parseWageringCondition(apiBonus))
    case ApiBonusTypeEnums.DepositCashBonus:
      return new DepositCashCommonBonus(bonus, parseWageringCondition(apiBonus))

    case ApiBonusTypeEnums.DepositFreeSpinBonus:
      return new DepositFreeSpinCommonBonus(bonus, parseWageringCondition(apiBonus), parseFreeSpinCommonBonus(apiBonus))
    case ApiBonusTypeEnums.FreeSpinBonus:
      return new FreeSpinCommonBonus(bonus, parseWageringCondition(apiBonus), parseFreeSpinCommonBonus(apiBonus))

    default:
      break
  }
}

// Parser

const parseCommonBonus = (apiBonus: any): ICommonBonus => {
  return {
    condition: parseBonusCondition(apiBonus),
    info: parseBonusInfomation(apiBonus),
  }
}

const parseBonusCondition = (apiBonus: any): IBonusCondition => {
  return {
    expiryDate: (apiBonus.expire_time || apiBonus.to_time) * 1000,
    updateDate: (apiBonus.update_time || 0) * 1000,
    maxUserLevel: apiBonus.max_level || apiBonus.user_max_level || 0,
    minUserLevel: apiBonus.min_level || apiBonus.user_min_level || 0,
    userType: apiBonus.voucher_target_user_type || apiBonus.target_user_type,

    signUpAfter: Number(apiBonus.data.setting?.redeem_condition?.signup_after_time || 0) * 1000,
    minBalanceToRedeem: Number(apiBonus.data.setting?.redeem_condition?.min_balance || 0),
    minAmountWagerToRedeem: Number(apiBonus.data.setting?.redeem_condition?.min_wager_in_time?.amount_usd || 0),
    minAmountWagerInDay: Number(apiBonus.data.setting?.redeem_condition?.min_wager_in_time?.day_duration || 0),
    gameRequiredID: apiBonus.data.setting?.redeem_condition?.min_wager_in_time?.game_id,
  }
}

const parseBonusInfomation = (apiBonus: any): BonusInfomation => {
  return {
    image: apiBonus.icon_url,
    name: apiBonus.title || '',
    badge: apiBonus.badge || '',

    instruction: apiBonus.voucher_description || apiBonus.instruction || '',
  }
}

const parseFreeHUSDLockCommonBonus = (apiBonus: any): IFreeHUSDLockCommonBonus => {
  return {
    amount: apiBonus.data.setting?.amount,
  }
}

const parseNoWageringCommonCashBonus = (apiBonus: any): INoWageringCommonCashBonus => {
  const bonusToken = apiBonus.data.setting.bonus_amount_condition?.network
    ? tokens[ChainIdEnum[formatApiNetworkField(apiBonus.data.setting.bonus_amount_condition?.network)]][
        apiBonus.data.setting.bonus_amount_condition?.currency
      ]
    : null

  const amount = new BigNumber(
    (apiBonus.data.setting.bonus_amount_condition?.amount !== '0' &&
      apiBonus.data.setting.bonus_amount_condition?.amount) ||
      '0',
  )
  return {
    bonusAmount: { amount, token: bonusToken },
  }
}

const parseHUSDUnLockBoosterCommonBonus = (apiBonus: any): IHUSDUnLockBoosterCommonBonus => {
  return {
    duration: apiBonus.data.setting?.duration,
    extraUnlockPercent: apiBonus.data.setting?.percent,
  }
}

const parseLevelUpCommonBonus = (apiBonus: any): ILevelUpCommonBonus => {
  return {
    duration: apiBonus.data.setting?.duration,
    maxLevels: apiBonus.data.setting?.max_level,
    extraLevels: apiBonus.data.setting?.upgrade_count,
  }
}

const parseFreeLuckyspinCommonBonus = (apiBonus: any): IFreeLuckyspinCommonBonus => {
  return {
    amount: apiBonus.data.setting?.quantity,
  }
}

const parseWageringCondition = (apiBonus: any): IWageringCondition => {
  const bonusToken =
    apiBonus.data.result && apiBonus.data.result.bet_result.bonus_network
      ? tokens[ChainIdEnum[formatApiNetworkField(apiBonus.data.result.bet_result.bonus_network)]][
          apiBonus.data.result.bet_result.bonus_currency
        ]
      : apiBonus.data.setting && apiBonus.data.setting.bonus_amount_condition?.network
      ? tokens[ChainIdEnum[formatApiNetworkField(apiBonus.data.setting.bonus_amount_condition.network)]][
          apiBonus.data.setting.bonus_amount_condition.currency
        ]
      : null

  return {
    maxBet: Number(apiBonus.data.setting.bet_condition.max_bet_usd),
    minBet: Number(apiBonus.data.setting.bet_condition.min_bet_usd),
    maxBonusAmountInUsd: apiBonus.data.setting.bonus_amount_condition.max_usd,
    minDeposit: apiBonus.data.setting.deposit_condition?.min_usd,
    wagerMultiplier: apiBonus.data.setting.bonus_amount_condition.wager_multiplier,
    activeDurationInDays:
      apiBonus.data.setting.deposit_condition?.day_duration ||
      apiBonus.data.setting.activate_frb_duration ||
      apiBonus.data.setting.bonus_amount_condition?.day_duration,
    wagerDurationInDays: apiBonus.data.setting.bet_condition.day_duration,
    gameCollectionId: apiBonus.data.setting.bet_condition.game_collection_id,
    isCreaditReward: apiBonus.voucher_cashout_type === 2,
    maxCashoutInUsd: apiBonus.data?.setting?.cashout_condition?.max_cashout_amount,
    maxCashoutType: apiBonus.data?.setting?.cashout_condition?.max_cashout_type,
    bonusAmount: {
      amount: new BigNumber(
        (apiBonus.data.result?.bet_result?.bonus_amount !== '0' && apiBonus.data.result?.bet_result?.bonus_amount) ||
          apiBonus.data.setting?.bonus_amount_condition?.amount ||
          '0',
      ),
      token: bonusToken == HUSD_TOKEN ? USD_CURRENCY : bonusToken,
    },
  }
}

const parseDepositCommonBonus = (apiBonus: any): IDepositCommonBonus => {
  return {
    percent: Number(apiBonus.data.setting.bonus_amount_condition.percentage),
  }
}

const parseFreeSpinCommonBonus = (apiBonus: any): IFreeSpinCommonBonus => {
  return {
    freeSpinAmount: apiBonus.data.setting.free_round_condition.quota,
    spinDurationInDays: apiBonus.data.setting.free_round_condition.day_duration,
    spinGameIds: apiBonus.data.setting.free_round_condition.game_ids,
    spinPrice: Number(apiBonus.data.setting.free_round_condition.bet_amount_currency),
    freeSpinCurrency: apiBonus.data.setting.free_round_condition.currency,
  }
}

const parseBoosterWagerCommonBonus = (apiBonus: any): IBoosterWagerBonus => {
  return {
    boostHourDuration: apiBonus.data.setting.boost_hour_duration,
    duration: apiBonus.data.setting.apply_day_duration,
    maxBooster: apiBonus.data.setting.max_boost_amount_usd,
    boostPercent: apiBonus.data.setting.boost_percentage,
    boostedAmount: apiBonus.data.result && apiBonus.data.result.booster_result.boost_amount_usd,
  }
}

export const JourneyBonusesMapper = (rawResponse: string): BaseResponse<Paging<CommonBonus>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      items: response.data.items?.map((item) => parsePublicBonus(item)) || [],
    },
  }
}
