import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import React from 'react'
import styled from 'styled-components'
import theme from 'theme'

const ModalHeader: React.FC<{ wrapBackground?: string } & BoxProps> = ({ wrapBackground, children, ...props }) => {
  return (
    <StyledContainer
      background={wrapBackground || theme.colors.modalHeaderBackground}
      className="wrap-header-container"
      minHeight="70px"
      mb="-10px"
    >
      <StyledHeaderContainer {...props}>{children}</StyledHeaderContainer>
    </StyledContainer>
  )
}

const StyledContainer = styled(Box)``
const StyledHeaderContainer = styled(Box)`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
`

export default ModalHeader
