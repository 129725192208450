import BigNumber from 'bignumber.js'
import Flex from 'UIKit/Box/Flex'
import Text from 'UIKit/Text'
import TokenLogo from 'UIKit/TokenLogo'
import { USD_CURRENCY } from 'config/constants/tokens'
import { useVariableLoadedListener } from 'hooks/useVariableLoadedListener'
import Column from 'layout/Components/Column'
import { RowBetween } from 'layout/Components/Row'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { WheelPayoutConfig } from 'views/InhouseGame/Wheel/config/types'

const WheelPayoutInfo: React.FC<{
  payout: WheelPayoutConfig
  arrowPosition: number
  segments: number
  hideProfit?: boolean
}> = ({ arrowPosition, payout, segments, hideProfit }) => {
  const [betAmountInUsd, setBetAmountInUsd] = useState('')
  const betAmountElement = useVariableLoadedListener(() =>
    typeof window === 'undefined' ? null : (document.getElementById('wheel-bet-amount-input') as HTMLInputElement),
  )

  useEffect(() => {
    if (!betAmountElement || hideProfit) return
    setBetAmountInUsd(betAmountElement.value)
    const listener = (value: any) => {
      setBetAmountInUsd(value?.target.value)
    }
    betAmountElement.addEventListener('input', listener)

    return () => {
      betAmountElement.removeEventListener('input', listener)
    }
  }, [betAmountElement, hideProfit])

  return (
    <StyledWrapper $arrowPosition={arrowPosition}>
      {!hideProfit && (
        <Column flex="1 1" width="100%">
          <RowBetween>
            <Text small>Profit on Win</Text>
          </RowBetween>
          <StyledAmount mt={['6px', '', '', '', '8px']}>
            <Text small mr="8px">
              {betAmountInUsd
                ? getFullDisplayBalance(new BigNumber(betAmountInUsd).multipliedBy(payout.payout), 0, 2)
                : '0.00'}
            </Text>
            <TokenLogo token={USD_CURRENCY} size={16} />
          </StyledAmount>
        </Column>
      )}

      <Column flex="1 1" width="100%">
        <RowBetween>
          <Text small>Chance</Text>
        </RowBetween>
        <StyledAmount mt={['6px', '', '', '', '8px']}>
          <Text small mr="8px">
            {payout.rate * segments} / {segments}
          </Text>
        </StyledAmount>
      </Column>
    </StyledWrapper>
  )
}

const StyledWrapper = styled(Flex)<{ $arrowPosition: number }>`
  animation: open 0.2s linear;
  position: absolute;
  top: -12px;
  left: 0;
  width: 100%;

  padding: 8px;

  background: #222931;
  border-radius: ${({ theme }) => theme.radii.tiny};
  box-shadow: 0 4px 6px -1px rgba(27, 23, 23, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.12);
  transform: translateY(-100%);

  @keyframes open {
    0% {
      transform: translateY(-120%);
      opacity: 0;
    }
    100% {
      transform: translateY(-100%);
      opacity: 1;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: ${({ $arrowPosition }) => $arrowPosition}px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.5em 0.5em 0;
    border-color: #222931 transparent transparent transparent;
    transform: translate(-50%);
  }

  ${Text} {
    font-size: 10px;
  }
  svg {
    width: 10px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    top: -10px;
    grid-gap: 16px;

    ${Text} {
      font-size: 14px;
    }
    svg {
      width: 16px;
    }
  }
`

const StyledAmount = styled(RowBetween)`
  background: ${({ theme }) => theme.colors.backgroundAlt};
  border: 2px solid rgba(255, 255, 255, 0.08);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  min-height: 26px;
  padding: 4px;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }) => theme.radii.tiny};

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 8px;
  }
`
export default WheelPayoutInfo
