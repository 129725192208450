import BigNumber from 'bignumber.js'
import Box from 'UIKit/Box/Box'
import { ModalProps } from 'UIKit/Modal'
import { TableRowValueHistory } from 'UIKit/Table/TableHistory'
import TokenLogo from 'UIKit/TokenLogo'
import { ChainIdEnum, NETWORK_UNKNOWN } from 'config/constants/network'
import tokens from 'config/constants/tokens'
import { Transaction } from 'config/types/transaction'
import Column from 'layout/Components/Column'
import { RowBetween, RowMiddle } from 'layout/Components/Row'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useUserSettings } from 'state/profile/hooks'
import { getNetworkIncludeFiatInfo } from 'utils/network'
import { getTokenName, isFiatCurrency } from 'utils/token'
import TokenAmountFiatDisplay from 'views/Fiat/TokenAmountFiatDisplay'
import {
  FieldTitle,
  SectionTransactionAmount,
  TransactionHash,
  TransactionStatus,
  TransactionWrapperModal,
} from '../../styled'
import WithdrawFiatProgressInfo from '../WithdrawFiatProgressInfo'
import Instruction from './Instruction'

const WithdrawTransactionDetailsModal: React.FC<
  ModalProps<{
    transaction: Transaction
  }>
> = ({ data, onDismiss }) => {
  const { t } = useTranslation()

  const { transaction } = data

  const token = tokens[transaction.network]?.[transaction.currency]
  const { network = NETWORK_UNKNOWN } = getNetworkIncludeFiatInfo(transaction.network)
  const isFiatTransaction = isFiatCurrency(transaction.currency)

  const {
    userSettings: { isDisplayInFiat },
  } = useUserSettings()

  return (
    <TransactionWrapperModal title={t('Withdraw Details')} onDismiss={onDismiss}>
      <Box maxHeight="640px" overflowY="scroll">
        <SectionTransactionAmount
          title="Withdraw"
          amount={transaction.value}
          token={token}
          date={transaction.createTime}
        >
          <RowBetween alignItems="flex-start !important">
            <FieldTitle>
              <Trans>Status</Trans>
            </FieldTitle>
            <Column alignItems="flex-end">
              <TransactionStatus status={transaction.status} />
            </Column>
          </RowBetween>

          {!isFiatCurrency(transaction.currency) && (
            <RowBetween mt="12px">
              <FieldTitle>
                <Trans>Network</Trans>
              </FieldTitle>
              <TableRowValueHistory>{network?.networkInfo.displayName}</TableRowValueHistory>
            </RowBetween>
          )}
          {isFiatCurrency(transaction.currency) && (
            <RowBetween mt="12px">
              <FieldTitle>
                <Trans>Payment Method</Trans>
              </FieldTitle>
              <TableRowValueHistory>{transaction?.fiat?.bankName || ''}</TableRowValueHistory>
            </RowBetween>
          )}

          <RowBetween mt="12px">
            <FieldTitle>
              <Trans>Fee</Trans>
            </FieldTitle>
            <RowMiddle>
              <Box minWidth="16px">
                <TokenLogo
                  token={token}
                  size={20}
                  networkImageProps={{
                    width: 12,
                    height: 12,
                  }}
                />
              </Box>

              <TableRowValueHistory ml="4px" fontWeight={600}>
                {!isDisplayInFiat ? (
                  <>
                    {transaction.fee}
                    <TableRowValueHistory ml="4px" as="span" symbol fontWeight={600}>
                      {getTokenName(token)}
                    </TableRowValueHistory>
                  </>
                ) : (
                  <TokenAmountFiatDisplay
                    tokenAmount={{
                      token,
                      amount: BigNumber(transaction.fee),
                    }}
                  />
                )}
              </TableRowValueHistory>
            </RowMiddle>
          </RowBetween>

          <RowBetween mt="12px">
            <FieldTitle>
              <Trans>Transaction ID</Trans>
            </FieldTitle>
            <TransactionHash
              txnHash={transaction.txnHash}
              chainId={transaction.network}
              hideHyperLink={isFiatTransaction || false}
            />
          </RowBetween>
        </SectionTransactionAmount>

        {transaction.instruction && (
          <Instruction instructionDetail={transaction.instruction} padding="12px" border="1px solid #232c3d8e" />
        )}

        {transaction?.network === ChainIdEnum.FIAT && <WithdrawFiatProgressInfo transaction={transaction} />}
      </Box>
    </TransactionWrapperModal>
  )
}

export default WithdrawTransactionDetailsModal
