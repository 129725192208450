import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { BoxProps } from 'UIKit/Box/types'
import OpenEffect from 'UIKit/OpenEffect'
import { TabProps } from 'UIKit/Tab'
import useElementScrollInfo from 'hooks/useElementScrollInfo'
import useKeydownEventListener from 'hooks/useKeydownEventListener'
import useResizeEventListener from 'hooks/useResizeEventListener'
import React, { ReactElement, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { animate } from 'utils/scroll'

export const StyledIndicator = styled(Box)`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 2px;
  width: 30px;
`

const StyledIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  height: 100%;
  top: 0px;
  transition: ${({ theme }) => theme.transitions.fast};

  pointer-events: none;
`

const StyledContainer = styled(Box)`
  ::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }

  ::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
`

const StyledArrowButton = styled.div`
  z-index: 2;
  position: absolute;
  top: 50%;
  overflow: hidden;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.backgroundAlt2};
  display: none;

  svg {
    fill: ${({ theme }) => theme.colors.textSubtle};
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.text};
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    display: flex;
  }
`

const StyledArrowLeftButton = styled(StyledArrowButton)`
  left: -12px;
  transform: translateY(-50%) rotate(-90deg);
`
const StyledArrowRightButton = styled(StyledArrowButton)`
  right: -12px;
  transform: translateY(-50%) rotate(90deg);
`

const StyledBlur = styled(OpenEffect)`
  width: 150px;
  pointer-events: none;
  height: 105%;
  position: absolute;
`

const StyledBlurLeft = styled(StyledBlur)<{ blurBackground: string }>`
  z-index: 2;
  top: 50%;
  left: -12px;
  transform: translateY(-50%);
  background: linear-gradient(
    90deg,
    ${({ blurBackground }) => blurBackground} 10px,
    ${({ blurBackground }) => blurBackground}a8 40%,
    transparent 50%
  );
`
const StyledBlurRight = styled(StyledBlur)<{ blurBackground: string }>`
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
  background: linear-gradient(
    270deg,
    ${({ blurBackground }) => blurBackground} 10px,
    ${({ blurBackground }) => blurBackground}a8 40%,
    transparent 50%
  );
`

type TabsProps = {
  value: any
  children: ReactElement<TabProps>[]
  onTagChanged: (value: any) => void
  backgroundBlurColor?: string
  hideIndicator?: boolean
  indicator?: React.ReactElement
  disabledScroll?: boolean
}
const Tabs: React.FC<TabsProps & BoxProps> = ({
  value,
  children,
  onTagChanged,
  backgroundBlurColor,
  hideIndicator,
  indicator,
  disabledScroll = false,
  ...props
}) => {
  const tabsRef = useRef<HTMLDivElement | null>(null)
  const selectedIndexRef = useRef(null)
  const { elementRef, scroll } = useElementScrollInfo('horizontal')
  const { t } = useTranslation()

  const [indicatorStyles, setIndicatorStyles] = useState(null)

  useKeydownEventListener((event) => {
    if (event && event.key === 'ArrowLeft') {
      const previous = document.activeElement.previousSibling as HTMLElement
      if (previous) {
        previous.focus()
      }
    }
    if (event && event.key === 'ArrowRight') {
      const next = document.activeElement.nextSibling as HTMLElement
      if (next) {
        next.focus()
      }
    }

    if (event && event.key === 'Enter') {
      const activeElement = document.activeElement as HTMLElement
      if (activeElement) {
        activeElement.click()
      }
    }
  })

  const updateTabView = useCallback(() => {
    let selectedIndex = -1
    let selectedTabScrollLeft = 0

    let selectedTabWidth = 0
    let nextTabWidth = 0
    let preTabWidth = 0

    React.Children.forEach(children, (element, index) => {
      if (!React.isValidElement(element)) return
      const { value: childValue } = element.props

      if (childValue === value) {
        selectedIndex = index
      }
    })

    selectedIndexRef.current = selectedIndex
    tabsRef?.current?.childNodes.forEach((node: any, index) => {
      const width = window.getComputedStyle(node).width.replaceAll('px', '')

      if (selectedIndex >= index) {
        selectedTabScrollLeft += Number(width)
        selectedTabWidth = Number(width)
      }

      if (selectedIndex >= index - 1) {
        nextTabWidth = Number(width)
      }
      if (selectedIndex >= index + 1) {
        preTabWidth = Number(width)
      }
    })

    // Scroll tab to view

    const scrollLeftElement = elementRef.current?.scrollLeft || 0
    const offsetWidthElement = elementRef.current?.offsetWidth || 0

    if (scrollLeftElement + offsetWidthElement < selectedTabScrollLeft + nextTabWidth) {
      animate('scrollLeft', elementRef.current, selectedTabScrollLeft + nextTabWidth - offsetWidthElement)
    } else if (elementRef.current?.scrollLeft > selectedTabScrollLeft - selectedTabWidth - preTabWidth) {
      animate('scrollLeft', elementRef.current, selectedTabScrollLeft - selectedTabWidth - preTabWidth)
    }
    // Update indicator
    if (!hideIndicator)
      setIndicatorStyles({ left: `${selectedTabScrollLeft - selectedTabWidth}px`, width: `${selectedTabWidth}px` })
  }, [value, hideIndicator, t])

  const { elementRef: containerRef } = useResizeEventListener(updateTabView)

  const scrollLeftElement = elementRef.current?.scrollLeft || 0
  const offsetWidthElement = elementRef.current?.offsetWidth || 0

  return (
    <Box ref={containerRef} position="relative" {...props}>
      {!disabledScroll && scroll && !scroll.start && (
        <>
          {backgroundBlurColor && <StyledBlurLeft className="tabs-left-blur" blurBackground={backgroundBlurColor} />}

          <StyledArrowLeftButton
            onClick={() => {
              animate('scrollLeft', elementRef.current, scrollLeftElement - offsetWidthElement / 1.5, 450)
            }}
          >
            <Icons.ArrowUpIcon />
          </StyledArrowLeftButton>
        </>
      )}

      <StyledContainer className="hide-scroll" ref={elementRef} overflowX="auto" position="relative" zIndex="0">
        <Flex ref={tabsRef} position="relative" zIndex={1}>
          {React.Children.map(children, (child: ReactElement<TabProps>) =>
            React.cloneElement(child, {
              ...child.props,
              selectedValue: value,
              onTagChanged,
            }),
          )}
        </Flex>
        <Box width="100%" height="2px" className="wrap-indicator">
          {!hideIndicator && indicatorStyles && (
            <StyledIndicatorContainer style={indicatorStyles}>
              {indicator ? <>{indicator}</> : <StyledIndicator />}
            </StyledIndicatorContainer>
          )}
        </Box>
      </StyledContainer>

      {!disabledScroll && scroll && !scroll.end && (
        <>
          {backgroundBlurColor && <StyledBlurRight className="tabs-right-blur" blurBackground={backgroundBlurColor} />}
          <StyledArrowRightButton
            onClick={() => {
              animate('scrollLeft', elementRef.current, scrollLeftElement + offsetWidthElement / 1.5, 450)
            }}
          >
            <Icons.ArrowUpIcon />
          </StyledArrowRightButton>
        </>
      )}
    </Box>
  )
}

export default Tabs
