import Button from 'UIKit/Button'
import Image from 'UIKit/Image'
import Modal, { ModalBody, ModalProps } from 'UIKit/Modal'
import Text from 'UIKit/Text'
import { RouteConfig } from 'config/constants/route'
import { Transaction, WithdrawFiatTransaction } from 'config/types/transaction'
import useModal, { useCloseAllModal } from 'hooks/useModal'
import { usePrivateSocket } from 'hooks/usePrivateSocket'
import { useRouter } from 'hooks/useRouter'
import { ColumnCenter } from 'layout/Components/Column'
import { RowBetween, RowCenter } from 'layout/Components/Row'
import { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useAuth } from 'state/auth/hooks'
import theme from 'theme'
import { colors } from 'theme/colors'
import { formatDisplayFullDateTime } from 'utils/dateHelper'
import { TransactionHash } from 'views/Profile/Transactions/components/styled'
import WithdrawTransactionDetailsModal from 'views/Profile/Transactions/components/Withdraw/WithdrawTransactionDetailsModal'

const WithdrawInProgressModal: React.FC<
  ModalProps<{ paymentFiatTransaction: WithdrawFiatTransaction; transactionDetails: Transaction }>
> = ({ data: { paymentFiatTransaction, transactionDetails }, onDismiss }) => {
  const router = useRouter()
  const [onPresentWithdrawDetailModal] = useModal(WithdrawTransactionDetailsModal)
  const { isSigned } = useAuth()
  const socket = usePrivateSocket()
  const closeAllModal = useCloseAllModal()

  useEffect(() => {
    if (!socket || !isSigned) return
    socket.on('withdrawal.succeeded', (data) => {
      if (data?.txn_hash === paymentFiatTransaction.txnHash) closeAllModal()
    })

    return () => {
      if (socket) {
        socket.off('withdrawal.succeeded', () => {})
      }
    }
  }, [socket, isSigned, paymentFiatTransaction])

  return (
    <Modal onDismiss={onDismiss}>
      <ModalBody padding="24px">
        <Text fontWeight={600} fontSize="16px" letterSpacing={-0.32} lineHeight="normal">
          <Trans>Withdraw in progress</Trans>
        </Text>

        <ColumnCenter mt="20px" style={{ gap: '20px' }}>
          <Image src={'/images/payment/deposit-fiat-in-progress.png'} width={40} height={40} />
          <RowCenter>
            <Text fontSize="20px" fontWeight={600} letterSpacing={-0.4} lineHeight="normal">
              <Trans>Withdraw in progress</Trans>
            </Text>
          </RowCenter>

          <ColumnCenter
            borderRadius={theme.radii.small}
            border={'1px solid #adadad0f'}
            background={colors.backgroundAlt5}
            padding="20px"
            style={{
              gap: '16px',
            }}
          >
            <RowBetween width="100%">
              <Text
                color="textSubtle"
                fontSize={['12px', , , '16px']}
                fontWeight={400}
                lineHeight="normal"
                letterSpacing={-0.32}
              >
                <Trans>Amount</Trans>:
              </Text>

              <Text fontSize={['12px', , , '16px']} fontWeight={400} color="textTertiary" letterSpacing={-0.32}>
                {paymentFiatTransaction.amount.amount.toString()} {paymentFiatTransaction.amount.token.name}
              </Text>
            </RowBetween>
            <RowBetween width="100%">
              <Text
                color="textSubtle"
                fontSize={['12px', , , '16px']}
                fontWeight={400}
                lineHeight="normal"
                letterSpacing={-0.32}
              >
                <Trans>Payment method</Trans>:
              </Text>

              <Text fontSize={['12px', , , '16px']} fontWeight={400} color="textTertiary" letterSpacing={-0.32}>
                {paymentFiatTransaction.name}
              </Text>
            </RowBetween>

            <RowBetween width="100%">
              <Text
                color="textSubtle"
                fontSize={['12px', , , '16px']}
                fontWeight={400}
                lineHeight="normal"
                letterSpacing={-0.32}
              >
                <Trans>Transaction ID</Trans>:
              </Text>

              <TransactionHash
                txnHash={paymentFiatTransaction.txnHash}
                chainId={paymentFiatTransaction.amount.token.network}
                hideHyperLink
                onClick={() => {
                  if (transactionDetails)
                    onPresentWithdrawDetailModal({
                      transaction: transactionDetails,
                    })
                }}
              />
            </RowBetween>

            <RowBetween width="100%">
              <Text
                color="textSubtle"
                fontSize={['12px', , , '16px']}
                fontWeight={400}
                lineHeight="normal"
                letterSpacing={-0.32}
              >
                <Trans>Date</Trans>:
              </Text>

              <Text fontSize={['12px', , , '16px']} fontWeight={400} color="textTertiary" letterSpacing={-0.32}>
                {formatDisplayFullDateTime(new Date(paymentFiatTransaction.createdAt))}
              </Text>
            </RowBetween>
          </ColumnCenter>

          <Button
            width="100%"
            variant="primary"
            maxWidth={'432px !important'}
            onClick={() => {
              router.push(RouteConfig.WithdrawTransactionHistory)
              closeAllModal()
            }}
          >
            <Text fontSize="14px" fontWeight={600} letterSpacing={-0.28} lineHeight="normal">
              <Trans>Withdraw History</Trans>
            </Text>
          </Button>
        </ColumnCenter>
      </ModalBody>
    </Modal>
  )
}

export default WithdrawInProgressModal
