import Box from 'UIKit/Box/Box'
import OpenEffect from 'UIKit/OpenEffect'
import Text from 'UIKit/Text'
import { Game } from 'config/types/game'
import { useVariableLoadedListener } from 'hooks/useVariableLoadedListener'
import { RowCenter, RowMiddle } from 'layout/Components/Row'
import React, { useMemo, useRef } from 'react'
import { Trans } from 'react-i18next'
import { useProviderInfo } from 'state/app/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import FavoriteGameButton from './FavoriteGameButton'
import ShareGameButton from './ShareGameButton'

export interface GameItemActionsProps {
  game: Game
  defaultFavoriteCount?: number
  onFavoriteCountUpdated?: (defaultFavoriteCount) => void
}

enum ScaleVariant {
  Small,
  Medium,
  Large,
  ExtraLarge,
}

const scaleVariantConfig = {
  [ScaleVariant.Small]: {
    playBtnWidth: '32px',
    playBtnHeight: '32px',
    iconSize: '22px',
    textSize: '14px',
    titleSize: '16px',
    providerTextSize: '12px',
    space: '8px',
    padding: '12px',
  },
  [ScaleVariant.Medium]: {
    playBtnWidth: '68px',
    playBtnHeight: '32px',
    iconSize: '24px',
    textSize: '14px',
    titleSize: '16px',
    providerTextSize: '12px',
    space: '8px',
    padding: '16px 12px',
  },
  [ScaleVariant.Large]: {
    playBtnWidth: '120px',
    playBtnHeight: '36px',
    iconSize: '24px',
    textSize: '14px',
    titleSize: '16px',
    providerTextSize: '12px',
    space: '12px',
    padding: '20px 12px',
  },
  [ScaleVariant.ExtraLarge]: {
    playBtnWidth: '200px',
    playBtnHeight: '48px',
    iconSize: '32px',
    textSize: '16px',
    titleSize: '18px',
    providerTextSize: '16px',
    space: '16px',
    padding: '28px',
  },
}

const GameItemActions: React.FC<GameItemActionsProps> = ({ game, defaultFavoriteCount, onFavoriteCountUpdated }) => {
  const provider = useProviderInfo(game.providerCode)
  const elementRef = useRef(null)
  const containerWidth = useVariableLoadedListener(() => elementRef.current?.clientWidth)

  const variant = useMemo(() => {
    if (containerWidth <= 190) {
      return ScaleVariant.Small
    }
    if (containerWidth <= 248) {
      return ScaleVariant.Medium
    }
    if (containerWidth <= 350) {
      return ScaleVariant.Large
    }
    return ScaleVariant.ExtraLarge
  }, [containerWidth])

  const scaleVariant = scaleVariantConfig[variant]

  return (
    <StyledWrapInfoGame ref={elementRef} p={scaleVariant.padding}>
      {containerWidth && (
        <OpenEffect openType="grow">
          <Box>
            <Text
              ellipsis={1}
              letterSpacing="-2%"
              lineHeight="1.2"
              fontSize={scaleVariant.titleSize}
              color="text"
              fontWeight={700}
            >
              {game.name}
            </Text>
            {provider?.name && (
              <Text
                fontSize={scaleVariant.providerTextSize}
                color={`${colors.textAlt1} !important`}
                lineHeight="1.2"
                fontWeight={500}
              >
                {provider.name}
              </Text>
            )}
          </Box>

          <RowMiddle mt={scaleVariant.space}>
            <Box>
              <StyledPlayGame display="flex" width={scaleVariant.playBtnWidth} height={scaleVariant.playBtnHeight}>
                <RowCenter mx="auto">
                  <Icons.PlayAltIcon width="16px" height="16px" fill={`${colors.buttonBackgroundAlt2} !important`} />
                  {variant !== ScaleVariant.Small && (
                    <Text color={`${colors.buttonBackgroundAlt2} !important`} fontSize="14px" fontWeight={700} ml="3px">
                      <Trans>Play</Trans>
                    </Text>
                  )}
                </RowCenter>
              </StyledPlayGame>
            </Box>

            <FavoriteGameButton
              game={game}
              ml="10px"
              defaultFavoriteCount={defaultFavoriteCount}
              onFavoriteCountUpdated={onFavoriteCountUpdated}
              size={scaleVariant.iconSize}
              fontSize={scaleVariant.textSize}
            />
            <SyledShareGameButon game={game} ml="8px" size={scaleVariant.iconSize} />
          </RowMiddle>
        </OpenEffect>
      )}
    </StyledWrapInfoGame>
  )
}

const SyledShareGameButon = styled(ShareGameButton)`
  ${Icons.ShareArrowIcon} {
    width: 32px !important;
    height: 26px !important;
  }
`

const StyledPlayGame = styled(Box)`
  background: ${({ theme: { colors } }) => colors.text};
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme: { radii } }) => radii.tiny} !important;
`

const StyledWrapInfoGame = styled(Box)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
`

export default GameItemActions
