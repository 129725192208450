import Flex from 'UIKit/Box/Flex'
import Modal, { ModalBody, ModalProps } from 'UIKit/Modal'
import { UserBonus } from 'config/types/bonus/userBonus'

import Box from 'UIKit/Box/Box'
import Button from 'UIKit/Button'
import Image from 'UIKit/Image/Image'
import Text from 'UIKit/Text/Text'
import { RouteConfig } from 'config/constants/route'
import { useCloseAllModal } from 'hooks/useModal'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { buildBonusName } from 'views/BonusCenter/hooks'

interface DepositedActiveBonusModalProps {
  bonus: UserBonus
}

const DepositedActiveBonusModal: React.FC<ModalProps<DepositedActiveBonusModalProps>> = ({
  onDismiss,
  data: { bonus },
}) => {
  const { t } = useTranslation()
  const closeModal = useCloseAllModal()
  const router = useRouter()

  return (
    <StyledModal onDismiss={onDismiss}>
      <StyledModalBody borderRadius="0px" height="100%">
        <Flex flexDirection="column" alignItems="center" overflow="hidden">
          <Box width="130px" height="120px">
            <Image src="/images/bonusCenter/gift-header-title.png" width={300} height={300} />
          </Box>
          <Text mt="20px" fontWeight="700" fontSize="20px">
            <Trans>Bonus Activated!</Trans>
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="textSubtle"
            mt="8px"
            textAlign="center"
            maxWidth="320px"
            marginX="auto"
            lineHeight="17.07px"
          >
            <Trans
              i18nKey="bonuseActivatedWhenDepositDesc"
              values={{ bonusName: buildBonusName(t, bonus)[0] }}
              components={{
                highlight_tag: <Text color="text" bold fontSize="inherit" display="inline !important" />,
              }}
            />
          </Text>
        </Flex>

        <Button
          maxWidth="290px"
          marginX="auto"
          width="100%"
          mt="40px"
          onClick={() => {
            router.push(RouteConfig.BonusCenter)
            closeModal()
          }}
        >
          <Text small bold>
            <Trans>Check bonus</Trans>
          </Text>
        </Button>

        <Button
          variant="text"
          maxWidth="290px"
          marginX="auto"
          width="100%"
          mt="4px"
          onClick={() => {
            onDismiss()
          }}
        >
          <Text small color="textSubtle">
            <Trans>Close</Trans>
          </Text>
        </Button>
      </StyledModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  padding: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 360px !important;
    max-width: 360px !important;
  }
`

const StyledModalBody = styled(ModalBody)`
  overflow-y: unset !important;
`

export default DepositedActiveBonusModal
