import { BoxProps } from 'UIKit/Box/types'
import Text from 'UIKit/Text'
import { TextProps } from 'UIKit/Text/types'
import TokenLogo from 'UIKit/TokenLogo'
import { TokenAmount } from 'config/types'
import { RowMiddle } from 'layout/Components/Row'
import { colors } from 'theme/colors'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { getTokenName } from 'utils/token'

type DisplayTokenAmountProps = {
  tokenAmount: TokenAmount
  textStyle?: TextProps
} & BoxProps

const DisplayTokenAmount: React.FC<DisplayTokenAmountProps> = ({
  tokenAmount: { token, amount },
  textStyle,
  ...props
}) => {
  return (
    <RowMiddle
      height="25px"
      border={`1px solid ${colors.backgroundAlt8}`}
      borderRadius="4px"
      p="4px"
      background={colors.backgroundAlt5}
      {...props}
    >
      <TokenLogo
        token={token}
        size={16}
        minWidth={16}
        maxWidth={16}
        networkImageProps={{
          width: 32,
          height: 32,
        }}
      />

      <Text ml="4px" color="textAlt1" fontSize="14px" fontWeight={600} letterSpacing="-0.28px" {...textStyle}>
        {getFullDisplayBalance(amount, 0, 8)} {getTokenName(token)}
      </Text>
    </RowMiddle>
  )
}

export default DisplayTokenAmount
