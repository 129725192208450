import Box from 'UIKit/Box/Box'
import Button from 'UIKit/Button'
import FormControl from 'UIKit/FormControl'
import FormInput from 'UIKit/FormControl/FormInput'
import Modal, { ModalBody, ModalProps } from 'UIKit/Modal'
import Text from 'UIKit/Text'
import FormValidator from 'config/constants/formValidator'
import { ValidationError } from 'config/types/validator'
import useForm from 'hooks/useForm'
import { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BaseResponse } from 'services/types'
import styled from 'styled-components'

interface ProvideTotpAuthCodeModalProps {
  onTotpAuthCodeConfirmed: (code: string) => Promise<BaseResponse<any>>
}

const verificationErrorMessages = {
  [ValidationError.Required]: 'Please provide verification code',
  [ValidationError.IncorrectLength]: 'Please enter the 6-digits verification code',
  [ValidationError.InCorrect2FA]: 'Two-Factor Authentication Code is incorrect, please check the code again',
}

const ProvideTotpAuthCodeModal: React.FC<ModalProps<ProvideTotpAuthCodeModalProps>> = ({
  data: { onTotpAuthCodeConfirmed },
  onDismiss,
}) => {
  const [submiting, setSubmiting] = useState(false)
  const { states, controls, validateAll } = useForm({
    code: {
      value: '',
      validators: [FormValidator.required, FormValidator.requiredLength(6)],
    },
  })
  const { t } = useTranslation()

  const handleVerify2FAModal = useCallback(async () => {
    const isValid = await validateAll()
    if (!isValid) return

    setSubmiting(true)
    const result = await onTotpAuthCodeConfirmed(states.code.value)
    if (result.code === 'error_auth_totp') {
      controls.code.onErrorChanged([ValidationError.InCorrect2FA])
    }
    if (result.code === 'success') {
      onDismiss()
    }

    setSubmiting(false)
  }, [states.code])

  return (
    <Modal p="24px" width="440px !important" minWidth="unset !important" onDismiss={onDismiss}>
      <ModalBody borderRadius="0px !important" overflowX="hidden">
        <Box
          as="form"
          width="100%"
          overflow="hidden"
          onSubmit={(e) => {
            e.preventDefault()
            handleVerify2FAModal()
          }}
        >
          <Text fontSize="16px" fontWeight={600} lineHeight="normal" letterSpacing={-0.32}>
            <Trans>Enter Two-Factor Authentication Code</Trans>
          </Text>

          <Text
            fontSize="14px"
            lineHeight="normal"
            fontWeight={300}
            letterSpacing={-0.28}
            color="textTertiary"
            my="24px"
          >
            <Trans>Please Enter Two-Factor authentication code generated by your Authenticator app.</Trans>
          </Text>

          <FormControl
            label={t('Two-Factor Authentication Code')}
            state={states.code}
            formatErrorMessage={(e) => t(verificationErrorMessages[e[0]])}
          >
            <FormInput
              tabIndex={1}
              control={controls.code}
              placeholder={t('Enter code')}
              adornmentType="PasteFromClipboard"
            />
          </FormControl>

          <StyledConfirmButton
            mt="24px"
            disabled={!states.code.value || submiting}
            type="submit"
            tabIndex={1}
            width="100% !important"
          >
            <Text fontSize="14px" fontWeight={600} letterSpacing={-0.28}>
              <Trans>Enter</Trans>
            </Text>
          </StyledConfirmButton>
        </Box>
      </ModalBody>
    </Modal>
  )
}

const StyledConfirmButton = styled(Button).attrs({ variants: 'primary' })`
  border-radius: ${({ theme: { radii } }) => radii.tiny};
  height: 46px !important;
`

export default ProvideTotpAuthCodeModal
