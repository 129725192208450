import Button from 'UIKit/Button/Button'
import Text from 'UIKit/Text/Text'
import { WalletInfo } from 'config/types/wallet'
import { ColumnCenter } from 'layout/Components/Column'

import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { getIcon } from 'svgs'

import Box from 'UIKit/Box/Box'
import QRCode from 'UIKit/QRCode'

const InstallWalletQRCode: React.FC<{ wallet: WalletInfo; onInstalled: () => void }> = ({ wallet, onInstalled }) => {
  const Icon = getIcon(wallet.icon)
  return (
    <ColumnCenter justifyContent="center !important" alignItems={['center !important']} height="100%">
      <Text textAlign="center" color="textTertiary">
        <Trans>Scan with your phone to download on IOS or Android</Trans>
      </Text>

      <ColumnCenter position="relative" my="16px">
        <QRCode size={300} image={Icon} url={wallet?.options?.downloadLink} />
        <StyledImageIcon>
          <Icon width="60px" />
        </StyledImageIcon>
      </ColumnCenter>

      <StyledButtonBack onClick={onInstalled}>
        <Trans>Installed</Trans>
      </StyledButtonBack>
    </ColumnCenter>
  )
}

const StyledButtonBack = styled(Button)`
  width: 140px;
  height: 36px;
  border-radius: 40px;
  margin: 0 auto;
  font-weight: bold;
`

const StyledImageIcon = styled(Box)`
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
`
export default InstallWalletQRCode
