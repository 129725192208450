import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { BoxProps } from 'UIKit/Box/types'
import Image from 'UIKit/Image'
import { ImageProps } from 'UIKit/Image/types'
import { NETWORK_MAP, pathNetworkImage } from 'config/constants/network'
import { pathTokenImage } from 'config/constants/tokens/bsc'
import { Token } from 'config/types'
import { Icons } from 'svgs'
import theme from 'theme'

const TokenLogo: React.FC<
  BoxProps & {
    token: Token
    tokenImageProps?: ImageProps
    networkImageProps?: ImageProps
    hideNetwork?: boolean
    size?: number
  }
> = ({ token, tokenImageProps, networkImageProps, hideNetwork, size = 24, ...props }) => {
  const network = NETWORK_MAP?.[token.network]?.networkInfo?.icon

  return (
    <Box {...props} position="relative">
      {token?.logo ? (
        <>
          <Box width={size} height={size}>
            <Image
              src={token.logo}
              alt={`${token.name} LOGO`}
              width={size * 4}
              height={size * 4}
              {...tokenImageProps}
            />
          </Box>
          {network &&
            network.replace(pathNetworkImage, '') !== token.logo.replace(pathTokenImage, '') &&
            !hideNetwork && (
              <Box position="absolute" top="-3px" right="-2px">
                <Image
                  src={network}
                  alt={`${token.name} LOGO`}
                  width={12}
                  height={12}
                  style={{
                    minWidth: 12,
                    minHeight: 12,
                    maxWidth: 12,
                    maxHeight: 12,
                  }}
                  {...networkImageProps}
                />
              </Box>
            )}
        </>
      ) : (
        <Flex>
          <Icons.HelpOutlineIcon fill={theme.colors.textSubtle} width={size} height={size} {...tokenImageProps} />
        </Flex>
      )}
    </Box>
  )
}

export default TokenLogo
