import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import Text from 'UIKit/Text'
import { WalletInfo } from 'config/types/wallet'
import styled from 'styled-components'
import { getIcon } from 'svgs'

interface WalletCardProps {
  wallet: WalletInfo
  onSelect: (wallet: WalletInfo) => void
}

const WalletCard: React.FC<WalletCardProps & BoxProps> = ({ wallet, onSelect, ...props }) => {
  const Icon = getIcon(wallet.icon)
  return (
    <StyledContainer {...props} onClick={() => onSelect(wallet)}>
      <Text fontSize="12px" fontWeight="500">
        {wallet.name}
      </Text>
      <Icon width="28px" />
    </StyledContainer>
  )
}

const StyledContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;

  max-height: 44px;

  background: rgba(255, 255, 255, 0.04);
  border-radius: 100px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`

export default WalletCard
