import { FlexProps } from 'UIKit/Box/types'
import TransText from 'UIKit/Text/TransText'
import { TextProps } from 'UIKit/Text/types'
import { ColumnCenter } from 'layout/Components/Column'
import { RowCenter } from 'layout/Components/Row'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'

interface NotAvailableGameItemOverlayProps {
  isBlocked?: boolean
  isRestricted?: boolean
  isDesktopRestrictedGame?: boolean
  iconSize?: number
}

const NotAvailableGameItemOverlay: React.FC<NotAvailableGameItemOverlayProps & FlexProps & TextProps> = ({
  isBlocked = false,
  isRestricted = false,
  iconSize = 100,
  ...props
}) => {
  return (
    <StyledOverlayWrapper {...props}>
      <ColumnCenter maxWidth="320px">
        {isBlocked ? (
          <>
            <Icons.LockIcon width={iconSize} fill={colors.textTertiary} />
            <TransText fontSize="32px" textAlign="center" mt="8px" {...props}>
              This game is unavailable in your region
            </TransText>
          </>
        ) : isRestricted ? (
          <>
            <Icons.LockIcon width={iconSize} fill={colors.textTertiary} />
            <TransText fontSize="32px" textAlign="center" mt="8px" {...props}>
              Unavailable with Active Claim to Play Bonus
            </TransText>
          </>
        ) : (
          <>
            <Icons.SettingConfigIcon width={iconSize} fill={colors.textTertiary} />
            <TransText fontSize="32px" textAlign="center" mt="8px" {...props}>
              This game is under maintenance
            </TransText>
          </>
        )}
      </ColumnCenter>
    </StyledOverlayWrapper>
  )
}

const StyledOverlayWrapper = styled(RowCenter)`
  z-index: 2;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
  opacity: 0;
  background: linear-gradient(180deg, rgba(22, 28, 36, 0) -22.78%, #191d25e0 55%, #17181b 86.75%);
  opacity: 1;
`

export default NotAvailableGameItemOverlay
