import Flex from 'UIKit/Box/Flex'
import Heading from 'UIKit/Heading'
import CircleLoader from 'UIKit/Loader/CircleLoader'
import { FetchingStatus } from 'config/constants'
import FormValidator from 'config/constants/formValidator'
import { INHOUSE_GAMES, InhouseGameDetails, InhouseGameType } from 'config/types/game'
import useForm from 'hooks/useForm'
import Column from 'layout/Components/Column'
import { useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import PlinkoGameService from 'services/InhouseGameService/PlinkoGameService'
import BaseInhouseGameService from 'services/InhouseGameService/BaseInhouseGameService'
import { useTokenSelected } from 'state/session/hooks'
import theme from 'theme'
import { ProbilityFair } from 'views/InhouseGame/config/types'
import LimboGameService from 'services/InhouseGameService/LimboGameService'
import DiceGameService from 'services/InhouseGameService/DiceGameService'
import WheelGameService from 'services/InhouseGameService/WheelGameService'
import MineGameService from 'services/InhouseGameService/MineGameService'
import Text from 'UIKit/Text'
import { InHouseGameButton } from '../styled'
import { FairInfo, FairInfoChange } from './styled'

const SeedFairness: React.FC<{ game: InhouseGameDetails }> = ({ game }) => {
  const playToken = useTokenSelected()

  const [probilityFairFetch, setProbilityFairFetch] = useState<FetchingStatus>(FetchingStatus.Fetching)

  const [probilityFair, setProbilityFair] = useState<ProbilityFair>(null)

  const { states, controls } = useForm({
    clientSeed: {
      validators: [FormValidator.required, FormValidator.inputLength({ max: 64 })],
      validateOnChange: true,
      value: '',
    },
  })

  const probilityFairService: BaseInhouseGameService = useMemo(() => {
    switch (game.additionalData.inhouseGameType) {
      case InhouseGameType.Plinko:
        return PlinkoGameService
      case InhouseGameType.Limbo:
        return LimboGameService
      case InhouseGameType.Dice:
        return DiceGameService
      case InhouseGameType.Wheel:
        return WheelGameService
      case InhouseGameType.Mine:
        return MineGameService
      default:
        return null
    }
  }, [game])

  useEffect(() => {
    const fetch = async () => {
      setProbilityFairFetch(FetchingStatus.Fetching)
      const probilityFair = await probilityFairService.getSeed(game.code, playToken)
      if (probilityFair) {
        setProbilityFair(probilityFair)
        controls.clientSeed.onValueChanged(probilityFair.nextSeed.clientSeed)
      }

      setTimeout(() => {
        setProbilityFairFetch(FetchingStatus.Fetched)
      }, 500)
    }
    fetch()
  }, [probilityFairService])

  const updateSeed = async () => {
    setProbilityFairFetch(FetchingStatus.Fetching)
    const probilityFair = await probilityFairService.updateSeed(game.code, playToken, states.clientSeed.value)
    if (probilityFair) {
      setProbilityFair(probilityFair)
      controls.clientSeed.onValueChanged(probilityFair.nextSeed.clientSeed)
    }
    setTimeout(() => {
      setProbilityFairFetch(FetchingStatus.Fetched)
    }, 500)
  }

  return (
    <Column>
      <FairInfo label="Active Client Seed" value={probilityFair?.currentSeed.clientSeed || ''} mb="16px" />
      <FairInfo
        label="Active Server Seed (Hashed)"
        value={probilityFair?.currentSeed.hashedServerSeed || ''}
        mb="16px"
      />
      <FairInfo label="Total bets made with pair" value={probilityFair?.currentSeed.nonce.toString() || ''} mb="24px" />

      <Column width="100%">
        <Heading scale="xs" mx="auto" mb="24px">
          <Trans>Rotate Seed Pair</Trans>
        </Heading>

        <Flex alignItems="flex-end" mb="16px">
          <FairInfoChange
            label="New Client Seed"
            state={states.clientSeed}
            value={states.clientSeed.value}
            validators={controls.clientSeed.validators}
            onErrorChanged={controls.clientSeed.onErrorChanged}
            onValueChanged={controls.clientSeed.onValueChanged}
            style={{
              borderTopRightRadius: '0',
              borderBottomRightRadius: '0',
            }}
          />

          <InHouseGameButton
            height="44px"
            maxHeight="44px"
            minWidth="96px"
            style={{
              borderRadius: theme.radii.tiny,
              borderTopLeftRadius: '0',
              borderBottomLeftRadius: '0',
            }}
            disabled={
              states.clientSeed.errors.length > 0 ||
              probilityFairFetch === FetchingStatus.Fetching ||
              probilityFair?.lockedByGames.length > 0
            }
            onClick={updateSeed}
          >
            {probilityFairFetch === FetchingStatus.Fetching ? <CircleLoader /> : <Trans>Change</Trans>}
          </InHouseGameButton>
        </Flex>

        <FairInfo label="Next Server Seed (Hashed)" value={probilityFair?.nextSeed.hashedServerSeed || ''} />
      </Column>

      {probilityFair?.lockedByGames.length > 0 && (
        <Text fontSize="12px" color="textSubtle" fontWeight="500" mt="14px">
          <Trans>You need to finish the following game(s) before you can rotate your seed pair</Trans>:{' '}
          <Text display="inline" fontSize="12px" color="text" bold>
            {probilityFair.lockedByGames
              .map((item) => INHOUSE_GAMES.find((game) => game.type === item).name)
              .join(', ')}
          </Text>
        </Text>
      )}
    </Column>
  )
}

export default SeedFairness
