import Flex from 'UIKit/Box/Flex'
import { ModalProps } from 'UIKit/Modal'
import { DepositFreespinBonus, FreespinBonus, WageringConditionBonus } from 'config/types/bonus/userBonus'
import { useCountDown } from 'hooks/useCountdown'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useActiveBonusInWageringProcess } from 'state/bonus/hooks'
import styled from 'styled-components'
import BonusAction from 'views/BonusCenter/components/BonusAction'
import { displayCountdownTimeUnit } from 'views/Cashback/utils'
import FreespinBonusBody from './FreespinBonusBody'
import FreespinBonusHeader from './FreespinBonusHeader'

interface FreespinBonusModalProps {
  onSubmitted: () => void
  bonus: WageringConditionBonus
  isZeroAmount?: boolean
}

const FreespinBonusModal: React.FC<ModalProps<FreespinBonusModalProps>> = ({
  onDismiss,
  data: { bonus, onSubmitted, isZeroAmount },
}) => {
  const activeWageringBonus = useActiveBonusInWageringProcess()
  const selectedBonus = activeWageringBonus?.id === bonus.id ? activeWageringBonus : bonus

  const { t } = useTranslation()

  const bonusExpireTimeRef = useCountDown(
    bonus.condition.expiryDate || 0,
    (s, m, h, d) => `
      ${displayCountdownTimeUnit(t('day'), 'Day', d)}
      <div class='colon-mark'>:</div>
      ${displayCountdownTimeUnit(t('hour'), 'Hour', h)}
      <div class='colon-mark'>:</div>
      ${displayCountdownTimeUnit(t('min'), 'Min', m)}
      <div class='colon-mark'>:</div>
      ${displayCountdownTimeUnit(t('sec'), 'Sec', s)}
    `,
    () => {},
  )

  const handleSubmit = () => {
    onSubmitted()
    onDismiss()
  }

  return (
    <>
      {(selectedBonus instanceof DepositFreespinBonus || selectedBonus instanceof FreespinBonus) && (
        <FreespinBonusHeader onDismiss={onDismiss} isZeroAmount={isZeroAmount}>
          <FreespinBonusBody mt="20px" px="12px" bonus={selectedBonus} />
          <StyledButtonAction
            trackingModule="finishFreeSpinModal"
            bonus={selectedBonus}
            onBonusSubmitted={handleSubmit}
          />
          <StyledCountdownContent ref={bonusExpireTimeRef} />
        </FreespinBonusHeader>
      )}
    </>
  )
}

const StyledButtonAction = styled(BonusAction)`
  max-width: 290px;
  margin-inline: auto;
  width: 100%;
  margin-top: 40px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
`

const StyledCountdownContent = styled(Flex)`
  color: ${({ theme: { colors } }) => colors.textAlt1};
  font-size: 12px;
  margin-top: 12px;
  margin-inline: auto;

  div {
    width: 20px;
    text-align: center;
  }

  span {
    color: ${({ theme: { colors } }) => colors.textAlt1};
    width: 12px;
    text-align: center;
  }

  .day,
  .hour,
  .min,
  .sec {
    .value {
      color: ${({ theme: { colors } }) => colors.text};
      font-weight: bold;
      margin-bottom: 1px;
    }
  }
`

export default FreespinBonusModal
