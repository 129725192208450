import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import { Checkbox } from 'UIKit/Checkbox'
import Text from 'UIKit/Text'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import useQueryParam from 'hooks/useQueryParam'
import { useReferrerInfo } from 'hooks/useReferrerInfo'
import React, { useRef } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

const ReferralCode: React.FC<BoxProps> = (props) => {
  const { displayName, type, refCode } = useReferrerInfo()
  const catchedRefCode = useRef({ refCode, type })

  const { removeParam, addParam } = useQueryParam()

  useIsomorphicEffect(() => {
    if (refCode) {
      catchedRefCode.current = { refCode, type }
    }
  }, [refCode])

  const handleToggleApplyReferral = () => {
    if (refCode) {
      if (catchedRefCode.current.type === 'hunnyplay') removeParam('join')
      else removeParam('stag')
    } else {
      addParam(catchedRefCode.current.type === 'hunnyplay' ? 'join' : 'stag', catchedRefCode.current.refCode)
    }
  }

  return (
    catchedRefCode.current.refCode && (
      <StyledReferralContainer onClick={handleToggleApplyReferral} {...props}>
        <StyledCheckbox
          width="18px"
          minWidth="18px"
          height="18px"
          mr="8px"
          value={!!refCode}
          onValueChanged={() => {}}
        />

        <Text color="textTertiary" fontSize="12px" fontWeight={300} lineHeight="normal">
          <Trans
            i18nKey={displayName ? 'appliedRefferralCodeByDisplayName' : 'appliedReferralCode'}
            values={{ code: catchedRefCode.current.refCode, displayName }}
            components={{
              highlight_tag: <Text fontSize="12px" color="text" as="span" />,
            }}
          />
        </Text>
      </StyledReferralContainer>
    )
  )
}

export const StyledReferralContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`

const StyledCheckbox = styled(Checkbox)`
  border-radius: 50%;

  &:checked {
    background-color: ${({ theme }) => theme.colors.success};
    border-color: ${({ theme }) => theme.colors.success};
    border: none;
    &:after {
      border-color: rgb(21, 18, 29);
    }
  }
`

export default ReferralCode
