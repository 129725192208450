import IconButton from 'UIKit/Button/IconButton'
import useToggle from 'hooks/useToggle'
import React from 'react'
import styled from 'styled-components'
import { Icons } from 'svgs'
import FormInput, { FormInputProps } from './FormInput'

const FormInputPassword: React.FC<FormInputProps> = ({ control, ...props }) => {
  const [toggle, setToggle] = useToggle(false)

  const passwordAdornment = control.value && (
    <IconButton onClick={setToggle}>{toggle ? <Icons.EyeRevealIcon /> : <Icons.EyeIcon />}</IconButton>
  )

  return (
    <StyledPasswordInput
      autoComplete="on"
      adornment={passwordAdornment}
      control={control}
      type={toggle ? 'text' : 'password'}
      name="password"
      $showText={toggle || !control.value}
      {...props}
    />
  )
}

const StyledPasswordInput = styled(FormInput)<{ $showText?: boolean }>`
  ${({ $showText }) =>
    !$showText
      ? `
    font-size: 16px;
    font-family: Verdana;
  `
      : ''}
`

export default FormInputPassword
