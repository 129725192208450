import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import styled from 'styled-components'
import { LayoutProps, layout } from 'styled-system'

const TableHeader: React.FC<BoxProps> = ({ children, width, display, ...props }) => (
  <StyledTh display={display} width={width}>
    <StyledHeader {...props}>{children}</StyledHeader>
  </StyledTh>
)

const StyledHeader = styled(Box)`
  display: flex;
  align-items: center;
  background: ${({ theme: { colors } }) => colors.normal};
`
const StyledTh = styled.th<LayoutProps>`
  &:first-child ${StyledHeader} {
    border-top-left-radius: ${({ theme: { radii } }) => radii.default};
  }

  &:last-child ${StyledHeader} {
    border-top-right-radius: ${({ theme: { radii } }) => radii.default};
  }

  ${layout}
`

export default styled(TableHeader)``
