import Image from 'UIKit/Image'
import Link from 'UIKit/Link'
import Text from 'UIKit/Text'
import { ProfileStatisticGameDetailInfo } from 'config/types/profile'
import Column from 'layout/Components/Column'
import { RowBetween, RowCenter } from 'layout/Components/Row'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import USDAmountDisplay from 'views/Fiat/USDAmountDisplay'

interface PlayedGameListProps {
  games: ProfileStatisticGameDetailInfo[]
  type: 'MostWagered' | 'MostWin'
  title: string
}

const PlayedGameList: React.FC<PlayedGameListProps> = ({ games, title, type }) => {
  return (
    !!games?.length && (
      <Column py="6px">
        <Text color="textAlt1" fontSize="14px" fontWeight={400} letterSpacing={-0.28} my="6px">
          {title}
        </Text>

        {games.map((game) => (
          <Link href={game.slug} key={game.slug}>
            <RowBetween py="4px" my="6px">
              <RowCenter>
                <StyledGameThumbnail src={game.thumbnail} width={48} height={48} />
                <Column ml="6px">
                  <Text color="textTertiary" fontSize="14px" lineHeight="normal" fontWeight={500} letterSpacing={-0.28}>
                    {game.gameName}
                  </Text>
                  <Text
                    color="textAlt1"
                    fontSize="12px"
                    fontWeight={400}
                    lineHeight="normal"
                    letterSpacing={-0.24}
                    mt="4px"
                  >
                    <>
                      {type === 'MostWagered' ? <Trans>Wagered</Trans> : <Trans>Profit</Trans>}
                      <Text
                        as="span"
                        ml="4px"
                        color="inherit"
                        fontSize="inherit"
                        fontWeight="inherit"
                        lineHeight="inherit"
                      >
                        <USDAmountDisplay amount={game.amount} />
                      </Text>
                    </>
                  </Text>
                </Column>
              </RowCenter>

              <RowCenter width="32px" height="32px" background="rgba(255,255,255,0.04)" borderRadius="50%">
                <StyledPlayIcon width="20px" height="20px" fill={colors.text} />
              </RowCenter>
            </RowBetween>
          </Link>
        ))}
      </Column>
    )
  )
}

const StyledGameThumbnail = styled(Image)`
  img {
    border-radius: 4px;
  }
`

const StyledPlayIcon = styled(Icons.PlayAltIcon)`
  position: relative;
  left: 2px;
  top: 2px;
`

export default PlayedGameList
