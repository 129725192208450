import React, { useMemo } from 'react'
import { FieldState } from 'hooks/useForm'
import { ValidationError } from 'config/types/validator'
import { BoxProps } from 'UIKit/Box/types'
import { InputLabel, InputMessage } from 'UIKit/Input/styled'
import { Trans } from 'react-i18next'
import InputInstruction from 'UIKit/Input/InputInstruction'
import { StyledControlContainer } from './styled'

type FormControlProps = {
  state: FieldState
  label?: string
  formatErrorMessage: (error: ValidationError[]) => string | React.ReactNode
  instructionConfig?: {
    validationError: ValidationError
    message: string
  }[]
}

const FormControl: React.FC<BoxProps & FormControlProps> = ({
  state,
  label,
  formatErrorMessage,
  instructionConfig,
  children,
  ...props
}) => {
  const isDisplayInstruction = useMemo(() => {
    return (
      instructionConfig &&
      state.value &&
      !!instructionConfig.filter((config) => state.errors.includes(config.validationError)).length
    )
  }, [state])

  return (
    <StyledControlContainer state={state} {...props}>
      {label && <InputLabel>{label}</InputLabel>}

      {children}
      {isDisplayInstruction && (
        <InputInstruction className="display-when-active" data={instructionConfig} errors={state.errors} />
      )}
      <InputMessage>
        <Trans>{formatErrorMessage(state.errors) || ''}</Trans>
      </InputMessage>
    </StyledControlContainer>
  )
}

export default FormControl
