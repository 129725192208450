import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Button from 'UIKit/Button'
import CircleLoader from 'UIKit/Loader/CircleLoader'
import Text from 'UIKit/Text'
import useModal from 'hooks/useModal'
import { RowBetween, RowMiddle } from 'layout/Components/Row'
import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import InhouseGameService from 'services/InhouseGameService/InhouseGameService'
import theme from 'theme'
import WheelPayoutGroup from 'views/InhouseGame/Wheel/components/GameBoard/components/WheelPayoutGroup'
import { WHEEL_PAYOUTS, WHEEL_RISKS, WHEEL_SEGMENTS } from 'views/InhouseGame/Wheel/config'
import FairnessModal from 'views/InhouseGame/components/FairnessModal'
import { FairInfo } from 'views/InhouseGame/components/FairnessModal/styled'
import { WheelProbilityFairBetInfo } from 'views/InhouseGame/config/types'

const WheelGameContent: React.FC<{ inhouseBetId: number; betDetails?: WheelProbilityFairBetInfo }> = ({
  inhouseBetId,
  betDetails,
}) => {
  const { t } = useTranslation()
  const [presentFairnessModal] = useModal(FairnessModal)

  const [bet, setBet] = useState<WheelProbilityFairBetInfo>(betDetails)

  const selectedPayout = useMemo(() => {
    if (!bet || !bet.data) return null
    const payouts = WHEEL_PAYOUTS[bet.data.risk][bet.data.segments]

    return payouts.find((payout) => payout.payout === bet.data.result)
  }, [bet])

  useEffect(() => {
    if (betDetails) return

    const fetch = async () => {
      const betDetails = await InhouseGameService.getBetInfo(inhouseBetId)
      if (betDetails) {
        setBet(betDetails as WheelProbilityFairBetInfo)
      }
    }

    fetch()
  }, [inhouseBetId, betDetails])
  return (
    <Box position="relative">
      {!bet && (
        <Box position="absolute" left="50%" top="50%" style={{ transform: 'translate(-50%, -50%)' }}>
          <CircleLoader />
        </Box>
      )}

      <Box style={{ opacity: bet ? 1 : 0 }} width="100%">
        <Flex justifyContent="center" width="100%">
          <Box mt="100px" mb="24px" width="100%">
            {bet && bet.data && (
              <WheelPayoutGroup
                small
                padding="0px"
                segments={bet?.data.segments}
                risk={bet?.data.risk}
                activePayout={selectedPayout}
              />
            )}
          </Box>
        </Flex>

        <RowBetween mt="12px">
          <Text fontSize="1em" color="textAlt1" lineHeight={1.4}>
            <Trans>Risk</Trans>
          </Text>
          <RowMiddle>
            <Text fontSize="1em" color="textTertiary" fontWeight={300}>
              {WHEEL_RISKS.find((risk) => risk.value === bet?.data.risk)?.name}
            </Text>
          </RowMiddle>
        </RowBetween>
        <RowBetween mt="12px">
          <Text fontSize="1em" color="textAlt1" lineHeight={1.4}>
            <Trans>Segments</Trans>
          </Text>
          <RowMiddle>
            <Text fontSize="1em" color="textTertiary" fontWeight={300}>
              {WHEEL_SEGMENTS.find((s) => s.value === bet?.data.segments)?.name}
            </Text>
          </RowMiddle>
        </RowBetween>

        <FairInfo labelColor="textAlt1" label="Client Seed" value={bet?.seed.clientSeed || ''} mt="24px" />
        <FairInfo
          labelColor="textAlt1"
          label="Server Seed (Hashed)"
          value={bet?.seed.hashedServerSeed || ''}
          mt="24px"
        />
        <FairInfo
          labelColor="textAlt1"
          label="Server Seed"
          value={bet?.seed.serverSeed || t("Seed hasn't been revealed yet")}
          mt="24px"
          disabledCopy={!bet?.seed.serverSeed}
        />
        <FairInfo labelColor="textAlt1" label="Nonce" value={bet?.seed.nonce.toString()} mt="24px" />

        {bet?.seed.serverSeed ? (
          <Box mt="12px" width="100%">
            <Button
              width="100%"
              my="8px"
              style={{
                background: 'rgba(255, 255, 255, 0.12)',
                borderRadius: theme.radii.tiny,
                fontSize: '14px',
                lineHeight: '16px',
                fontWeight: 700,
              }}
              height={['32px', '', '40px']}
              onClick={() => {
                presentFairnessModal({ inhouseBet: bet })
              }}
            >
              <Trans>Verify</Trans>
            </Button>
          </Box>
        ) : (
          <Text mt="12px" width="100%" fontSize="12px" color="textSubtle">
            <Trans>Server seed needs to be changed to verify bet...</Trans>
          </Text>
        )}
      </Box>
    </Box>
  )
}

export default WheelGameContent
