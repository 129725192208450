import { BoxProps } from 'UIKit/Box/types'
import { InputProps } from 'UIKit/Input'
import { FieldState } from 'hooks/useForm'
import { Trans } from 'react-i18next'
import {
  InHouseGameInput,
  InHouseGameNumericalInput,
  InHouseLabel,
  InhouseGameInputContainer,
} from 'views/InhouseGame/components/styled'
import styled, { css } from 'styled-components'
import { ControlContainerFair } from '../../styled'

const InputControl: React.FC<
  {
    label: string
    state: FieldState
    type?: 'string' | 'number'
  } & BoxProps &
    InputProps
> = ({ label, state, type = 'string', value, validators, onValueChanged, onErrorChanged, style, ...props }) => {
  return (
    <ControlContainerFair state={state} {...props}>
      <InHouseLabel>
        <Trans>{label}</Trans>
      </InHouseLabel>
      <StyledInhouseGameInputContainer height="44px" style={style}>
        {type === 'string' ? (
          <InHouseGameInput
            px="8px"
            autoFocus={false}
            tabIndex={1}
            value={value}
            validators={validators}
            onErrorChanged={onErrorChanged}
            onValueChanged={onValueChanged}
            validateOnchange
          />
        ) : (
          <InHouseGameNumericalInput
            px="8px"
            autoFocus={false}
            tabIndex={1}
            value={value}
            validators={validators}
            onErrorChanged={onErrorChanged}
            onValueChanged={onValueChanged}
            validateOnchange
            placeholder={''}
            style={{
              textAlign: 'left',
            }}
          />
        )}
      </StyledInhouseGameInputContainer>
    </ControlContainerFair>
  )
}

const StyledInhouseGameInputContainer = styled(InhouseGameInputContainer)<{ $disabled?: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    css`
      background: ${({ theme }) => theme.colors.backgroundAlt9};
      cursor: auto;
      user-select: none;
      caret-color: transparent;
    `}
`

export default InputControl
