import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import Text from 'UIKit/Text'
import Column from 'layout/Components/Column'
import { RowCenter } from 'layout/Components/Row'
import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import USDAmountDisplay from 'views/Fiat/USDAmountDisplay'

interface BonusCardProps {
  title: string
  IconComponent: React.ReactNode
  amount: number
}

const BonusCard: React.FC<BonusCardProps & BoxProps> = ({ title, IconComponent, amount, ...props }) => {
  return (
    <StyledContainer {...props}>
      <Column>
        <Text fontSize="12px" color="textAlt1" lineHeight="normal" letterSpacing={-0.24}>
          <Trans>{title}</Trans>
        </Text>
        <RowCenter width="fit-content" mt="6px">
          {IconComponent}
          <Text
            fontSize="14px"
            lineHeight="normal"
            fontWeight={700}
            letterSpacing={-0.28}
            mt="2px"
            ml="5px"
            color={Number(amount) ? 'text' : 'textAlt1'}
          >
            <USDAmountDisplay amount={amount} limitDisplayDecimals={2} />
          </Text>
        </RowCenter>
      </Column>
    </StyledContainer>
  )
}

const StyledContainer = styled(Box)`
  border-radius: 4px;
  padding: 8px 12px;
  background: rgba(35, 44, 61, 0.2);
`

export default BonusCard
