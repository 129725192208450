import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { BoxProps } from 'UIKit/Box/types'
import Tab from 'UIKit/Tab'
import Tabs from 'UIKit/Tabs'
import Text from 'UIKit/Text'
import { RowCenter } from 'layout/Components/Row'
import { Trans } from 'react-i18next'
import { useUserSettings } from 'state/profile/hooks'
import styled, { css } from 'styled-components'
import { FlexProps } from 'styled-system'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import TwoFactorWarning from 'views/Profile/Settings/components/SettingSecurity/2FA/TwoFactorWarning'

export enum PaymentPageEnums {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  Swap = 'swap',
  Tip = 'tip',
}

export enum DepositOptionEnums {
  Crypto = 'crypto',
  Fiat = 'fiat',
  BuyCrypto = 'buy-crypto',
}

export enum WithdrawOptionEnums {
  Crypto = 'crypto',
  Fiat = 'fiat',
}

type PaymentMenuProps = FlexProps &
  BoxProps & {
    paymentPage: PaymentPageEnums
    depositSubTabOption: DepositOptionEnums
    withdrawSubTabOption: WithdrawOptionEnums
    onPaymentPageChanged: (value: PaymentPageEnums) => void
    onDepositSubTabChanged: (value: DepositOptionEnums) => void
    onWithdrawSubTabChanged: (value: WithdrawOptionEnums) => void
  }

export const PaymentMenuDesktop: React.FC<PaymentMenuProps> = ({
  paymentPage,
  depositSubTabOption,
  withdrawSubTabOption,
  onPaymentPageChanged,
  onDepositSubTabChanged,
  onWithdrawSubTabChanged,
  ...props
}) => {
  const { userSettings } = useUserSettings()
  const isActive2FA = userSettings.isActive2FA

  return (
    <Box width="100%" pt="12px" px="12px" background="#192029" {...props}>
      <StyledVerticalTab
        $active={paymentPage === PaymentPageEnums.Deposit}
        onClick={() => {
          onPaymentPageChanged(PaymentPageEnums.Deposit)
        }}
      >
        <Flex alignItems="center" mb="8px">
          <Icons.WalletIcon width="24px" fill={colors.text} />
          <Text bold small ml="8px">
            <Trans>Deposit</Trans>
          </Text>
        </Flex>

        <StyledPaymentOptionItem
          $isActive={paymentPage === PaymentPageEnums.Deposit && depositSubTabOption === DepositOptionEnums.Crypto}
          onClick={() => onDepositSubTabChanged(DepositOptionEnums.Crypto)}
        >
          <Text small fontWeight={600} lineHeight="normal" letterSpacing="-0.28px">
            <Trans>Crypto</Trans>
          </Text>
        </StyledPaymentOptionItem>
        <StyledPaymentOptionItem
          $isActive={paymentPage === PaymentPageEnums.Deposit && depositSubTabOption === DepositOptionEnums.Fiat}
          onClick={() => onDepositSubTabChanged(DepositOptionEnums.Fiat)}
        >
          <Text small fontWeight={600} lineHeight="normal" letterSpacing="-0.28px">
            <Trans>Fiat</Trans>
          </Text>
        </StyledPaymentOptionItem>
        <StyledPaymentOptionItem
          $isActive={paymentPage === PaymentPageEnums.Deposit && depositSubTabOption === DepositOptionEnums.BuyCrypto}
          onClick={() => onDepositSubTabChanged(DepositOptionEnums.BuyCrypto)}
        >
          <Text small fontWeight={600} lineHeight="normal" letterSpacing="-0.28px">
            <Trans>Buy crypto</Trans>
          </Text>
        </StyledPaymentOptionItem>
      </StyledVerticalTab>

      <StyledVerticalTab
        $active={paymentPage === PaymentPageEnums.Withdraw}
        onClick={() => {
          onPaymentPageChanged(PaymentPageEnums.Withdraw)
        }}
      >
        <Flex alignItems="center" mb="8px">
          <Icons.WithdrawIcon width="24px" fill={colors.text} />
          <Text bold small ml="8px">
            <Trans>Withdraw</Trans>
          </Text>
        </Flex>

        <StyledPaymentOptionItem
          $isActive={paymentPage === PaymentPageEnums.Withdraw && withdrawSubTabOption === WithdrawOptionEnums.Crypto}
          onClick={() => onWithdrawSubTabChanged(WithdrawOptionEnums.Crypto)}
          mt="12px !important"
        >
          <Text small fontWeight={600} lineHeight="normal" letterSpacing="-0.28px">
            <Trans>Crypto</Trans>
          </Text>
        </StyledPaymentOptionItem>
        <StyledPaymentOptionItem
          $isActive={paymentPage === PaymentPageEnums.Withdraw && withdrawSubTabOption === WithdrawOptionEnums.Fiat}
          onClick={() => onWithdrawSubTabChanged(WithdrawOptionEnums.Fiat)}
          mt="12px !important"
        >
          <Text small fontWeight={600} lineHeight="normal" letterSpacing="-0.28px">
            <Trans>Fiat</Trans>
          </Text>
        </StyledPaymentOptionItem>
      </StyledVerticalTab>

      <StyledVerticalTab
        $active={paymentPage === PaymentPageEnums.Swap}
        onClick={() => {
          onPaymentPageChanged(PaymentPageEnums.Swap)
        }}
      >
        <Flex alignItems="center">
          <Icons.SwapIcon width="24px" fill={colors.text} />
          <Text bold small ml="8px">
            <Trans>Swap</Trans>
          </Text>
        </Flex>
      </StyledVerticalTab>

      <StyledVerticalTab
        $active={paymentPage === PaymentPageEnums.Tip}
        onClick={() => {
          onPaymentPageChanged(PaymentPageEnums.Tip)
        }}
      >
        <Flex alignItems="center" position="relative" left="-2px">
          <Icons.TipIcon width="22px" fill={colors.text} />
          <Text bold small ml="12px">
            <Trans>Tip</Trans>
          </Text>
        </Flex>
      </StyledVerticalTab>

      {!isActive2FA && <TwoFactorWarning />}
    </Box>
  )
}

export const PaymentMenuMoble: React.FC<
  Omit<
    PaymentMenuProps,
    'depositSubTabOption' | 'withdrawSubTabOption' | 'onDepositSubTabChanged' | 'onWithdrawSubTabChanged'
  >
> = ({ paymentPage, onPaymentPageChanged, ...props }) => {
  return (
    <StyledTabs
      width="100%"
      value={paymentPage}
      onTagChanged={(value) => {
        onPaymentPageChanged(value)
      }}
      backgroundBlurColor={colors.background}
      indicator={<StyledIndicator />}
      $active={
        paymentPage === PaymentPageEnums.Deposit
          ? 1
          : paymentPage === PaymentPageEnums.Withdraw
          ? 2
          : paymentPage === PaymentPageEnums.Swap
          ? 3
          : 4
      }
      {...props}
    >
      <StyledTab value={PaymentPageEnums.Deposit} $active={paymentPage === PaymentPageEnums.Deposit}>
        <RowCenter>
          <Text bold fontSize="12px">
            <Trans>Deposit</Trans>
          </Text>
        </RowCenter>
      </StyledTab>
      <StyledTab value={PaymentPageEnums.Withdraw} $active={paymentPage === PaymentPageEnums.Withdraw}>
        <RowCenter>
          <Text bold fontSize="12px">
            <Trans>Withdraw</Trans>
          </Text>
        </RowCenter>
      </StyledTab>
      <StyledTab value={PaymentPageEnums.Swap} $active={paymentPage === PaymentPageEnums.Swap}>
        <RowCenter>
          <Text bold fontSize="12px">
            <Trans>Swap</Trans>
          </Text>
        </RowCenter>
      </StyledTab>
      <StyledTab value={PaymentPageEnums.Tip} $active={paymentPage === PaymentPageEnums.Tip}>
        <RowCenter>
          <Text bold fontSize="12px">
            <Trans>Tip</Trans>
          </Text>
        </RowCenter>
      </StyledTab>
    </StyledTabs>
  )
}

const StyledVerticalTab = styled(Box)<{ $active: boolean }>`
  padding: 9px 12px;
  margin-bottom: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  position: relative;

  svg {
    fill: ${({ theme }) => theme.colors.textSubtle};
    transition: 0.2s;
  }

  div {
    color: ${({ theme }) => theme.colors.textSubtle};
    transition: 0.2s;
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.text};
    }

    div {
      color: ${({ theme }) => theme.colors.text};
    }
  }

  ${({ $active }) =>
    $active
      ? css`
          background: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 1.89%, rgba(255, 255, 255, 0) 108.73%);

          svg {
            fill: ${({ theme }) => theme.colors.text};
          }

          div {
            color: ${({ theme }) => theme.colors.text};
          }
        `
      : css``}
`

const StyledPaymentOptionItem = styled(Box)<{ $isActive: boolean }>`
  background: ${({ $isActive }) => ($isActive ? 'rgba(255, 255, 255, 0.08)' : 'transparent')};
  padding: 8px 0;
  height: 36px;
  width: 100%;
  padding-left: 32px;

  border-radius: ${({ theme: { radii } }) => radii.small};
`

const StyledTab = styled(Tab)<{ $active: boolean }>`
  padding: 10px 12px;
  width: 25%;

  border-radius: ${({ theme }) => theme.radii.small};
  position: relative;

  svg {
    min-width: 20px;
    transition: ${({ theme }) => theme.transitions.fast};
    fill: ${({ theme, $active }) => ($active ? `${theme.colors.text} !important` : theme.colors.textSubtle)};
  }

  ${Text} {
    transition: ${({ theme }) => theme.transitions.fast};
    color: ${({ theme, $active }) => ($active ? `${theme.colors.text} !important` : theme.colors.textSubtle)};
    white-space: pre !important;
    font-weight: 600px;
  }
`

const StyledTabs = styled(Tabs)<{ $active: number }>`
  background: rgba(255, 255, 255, 0.04);
  height: 40px;
  border-radius: ${({ theme }) => theme.radii.tiny};
  padding: 4px 8px;

  .wrap-indicator {
    height: auto;
  }

  ${StyledTab}:nth-child(2),${StyledTab}:nth-child(3),${StyledTab}:nth-child(4) {
    &::after {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      width: 1px;
      height: 12px;
      background: #232c3d;
      opacity: 0.6;
    }
  }

  ${({ $active }) =>
    $active === 1
      ? css`
          ${StyledTab}:nth-child(2) {
            &::after {
              display: none;
            }
          }
        `
      : $active === 2
      ? css`
          ${StyledTab}:nth-child(2),${StyledTab}:nth-child(3) {
            &::after {
              display: none;
            }
          }
        `
      : $active === 3
      ? css`
          ${StyledTab}:nth-child(3) {
            &::after {
              display: none;
            }
          }
        `
      : ''}
`

const StyledIndicator = styled.div`
  border-radius: ${({ theme }) => theme.radii.tiny};
  background: rgba(255, 255, 255, 0.08);
  height: 100%;
  width: 100%;
`
