import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import useKeydownEventListener from 'hooks/useKeydownEventListener'
import React from 'react'
import styled from 'styled-components'

export interface ModalProps<T = any> {
  id?: string
  onDismiss?: () => void
  data?: T
  disableClose?: boolean
}

const Modal: React.FC<ModalProps & BoxProps> = ({ id, onDismiss, children, disableClose, ...props }) => {
  const handleOnDismiss = () => {
    if (onDismiss && !disableClose) {
      onDismiss()
    }
  }

  useKeydownEventListener((event) => {
    if (event && event.key === 'Escape') {
      handleOnDismiss()
    }
  })

  return (
    <StyledModal p="20px" id={id} {...props}>
      {children}
    </StyledModal>
  )
}

const StyledModal = styled(Box)`
  box-sizing: border-box;
  backdrop-filter: blur(10px);

  border-top-left-radius: ${({ theme }) => theme.radii.large};
  border-top-right-radius: ${({ theme }) => theme.radii.large};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 100%;
  width: 100%;
  min-height: 100px;
  background: ${({ theme }) => theme.colors.modalBackground};
  max-width: 512px;
  max-height: calc(var(--screen-height) * 0.95);
  ${({ theme }) => theme.mediaQueries.xs} {
    backdrop-filter: unset;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 512px;
    border-radius: ${({ theme }) => theme.radii.large};
  }
`

export default Modal
