import BigNumber from 'bignumber.js'
import Skeleton from 'UIKit/Skeleton'
import Text from 'UIKit/Text'
import { Trans } from 'react-i18next'
import { useUsdFiatConversionAmount } from 'state/profile/hooks'
import { getFullDisplayBalance } from 'utils/formatBalance'
import USDAmountDisplay from 'views/Fiat/USDAmountDisplay'

const HUSDDisplayBalance: React.FC<{ balance: BigNumber; color?: string; loading?: boolean }> = ({
  balance,
  color = 'text',
  loading,
}) => {
  const { amount } = useUsdFiatConversionAmount(balance)
  if (loading)
    return (
      <>
        <Skeleton width="156px" height="32px" mt="8px" />
        <Skeleton width="96px" height="14px" minHeight="14px !important" mt="8px" />
      </>
    )

  return (
    <>
      <Text fontSize="32px" color={color} fontWeight="700" mt="8px">
        {getFullDisplayBalance(amount, 0, 6)}
      </Text>

      <Text fontSize="14px" color="textSubtle" mt="8px">
        <Trans>
          ~ <USDAmountDisplay amount={balance} />
        </Trans>
      </Text>
    </>
  )
}

export default HUSDDisplayBalance
