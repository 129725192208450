import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { BoxProps } from 'UIKit/Box/types'
import Link from 'UIKit/Link'
import Text from 'UIKit/Text'
import { RouteConfig } from 'config/constants/route'
import Column from 'layout/Components/Column'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

export const Header = styled(Box)`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
  padding: 20px;

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    border-bottom: none;
    align-items: flex-end;
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.md} {
    padding: 0px 40px;
  }
`

export const Content = styled(Box)`
  margin: auto 0;
  padding: 20px;

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    min-width: 400px;
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.md} {
    padding: 32px 40px;
  }
`

export const Footer = styled(Box)`
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  padding: 20px;

  ${({ theme: { mediaQueries } }) => mediaQueries.md} {
    padding: 0px 40px;
  }
`

export interface TemplateProps {
  children?: JSX.Element[]
  className?: string
}

export const ConditionAcccess: React.FC<BoxProps> = ({ ...props }) => (
  <Column flex={1} justifyContent="flex-start !important" maxWidth={['100%', '', '80%', '100%']} mt="16px" {...props}>
    <Box display="inline">
      <Text as="span" fontWeight={300} fontSize="12px" mr="4px" lineHeight="14.5px" color="textTertiary">
        <Trans>
          <Trans
            i18nKey="over18Content"
            components={{
              highlight_tag: (
                <Link
                  id="signup-terms-of-service-text"
                  style={{
                    display: 'inline-flex',
                    wordBreak: 'break-word',
                  }}
                  fontSize="12px"
                  color="primary"
                  target="_blank"
                  href={RouteConfig.TermOfService}
                  external
                />
              ),
            }}
          />
        </Trans>
      </Text>
    </Box>
  </Column>
)

const Template: React.FC<TemplateProps & BoxProps> = ({ children, ...rest }) => {
  return <Container {...rest}>{children.map((child) => child)}</Container>
}

const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;
  height: 100%;
`
export default Template
