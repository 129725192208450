import Image from 'UIKit/Image'
import Text from 'UIKit/Text'
import { TextProps } from 'UIKit/Text/types'
import { FiatCurrency } from 'config/types/fiat'
import { RowCenter } from 'layout/Components/Row'
import theme from 'theme'

const FiatCurrencyDisplay = ({
  fiat,
  imgSize = 20,
  textStyle,
}: {
  fiat: FiatCurrency
  imgSize?: number
  textStyle?: TextProps
}) => {
  return (
    fiat && (
      <RowCenter p={0}>
        <Image src={fiat.logo} width={imgSize} height={imgSize} />
        <Text fontSize="14px" fontWeight={600} ml="8px" color={theme.colors.textTertiary} {...textStyle}>
          {fiat.code}
        </Text>
      </RowCenter>
    )
  )
}

export default FiatCurrencyDisplay
