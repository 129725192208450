import Box from 'UIKit/Box/Box'
import Grid from 'UIKit/Box/Grid'
import { BoxProps } from 'UIKit/Box/types'
import Text from 'UIKit/Text'
import { RowCenter } from 'layout/Components/Row'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import theme from 'theme'
import { PlinkoPayout } from 'views/InhouseGame/Plinko/types/Payout'
import { Risk, payoutDefaultValue, payoutRateValue } from '../../config'
import PayoutEventUpdater from './PayoutEventUpdater'
import PayoutInfo from './PayoutInfo'

export const getPayouts = (row: number, risk: Risk): PlinkoPayout[] => {
  const payoutValues = payoutDefaultValue[row - 8][risk]
  const payoutRates = payoutRateValue[row - 8]
  return payoutValues.map((item, payoutIndex) => {
    /*
      The end of payout at the boundary of both sides is colored rgb(255,0,63), and the value 0,204,67 is used to make the color rgb(255,192,0).
    */
    const payoutLength = payoutValues.length
    const r =
      255 -
      (payoutIndex < (payoutLength - 1) / 2 ? payoutIndex : payoutLength - 1 - payoutIndex) * ((0 / payoutLength) * 2)
    const g =
      0 +
      (payoutIndex < (payoutLength - 1) / 2 ? payoutIndex : payoutLength - 1 - payoutIndex) * ((204 / payoutLength) * 2)
    const b =
      63 -
      (payoutIndex < (payoutLength - 1) / 2 ? payoutIndex : payoutLength - 1 - payoutIndex) * ((67 / payoutLength) * 2)
    return {
      id: `${row}-${risk}-${item}-${payoutIndex}`,
      payoutIndex,
      color: { r, g, b },
      multiplier: item,
      rate: payoutRates[payoutIndex],
    }
  })
}

const PayoutGroup: React.FC<
  {
    row: number
    risk: Risk
    widthScale: number
    volumeEnable?: boolean
  } & BoxProps
> = ({ row, risk, widthScale, volumeEnable, ...props }) => {
  const [isOpenInfo, setOpenInfo] = useState(false)
  const refPayoutId = useRef<{
    payout: PlinkoPayout
    arrowPosition: number
  }>(null)

  const selectedPayoutValues: PlinkoPayout[] = useMemo(() => getPayouts(row, risk), [row, risk])

  const onMouseOut = useCallback(() => {
    refPayoutId.current = null
    setOpenInfo(false)
  }, [])

  return (
    <RowCenter position="relative" width="100%" {...props}>
      <StyledMultiplierGroup width={`${widthScale * selectedPayoutValues.length * 100}%`} gridGap="1%">
        {selectedPayoutValues.map((payout) => (
          <StyledPayoutBox
            id={`payout-${payout.payoutIndex}`}
            key={`payout-${payout.payoutIndex}`}
            height={['1.4em', '', '1.4em', '', '1.8em']}
            maxHeight="29px"
            width="100%"
            borderRadius={row < 14 ? theme.radii.tiny : '2px'}
            background={`rgb(${payout.color.r}, ${payout.color.g}, ${payout.color.b})`}
            style={{
              boxShadow: `0 0.2em 0 0 rgba(${payout.color.r}, ${payout.color.g}, ${payout.color.b}, 0.6)`,
              cursor: 'help',
            }}
            onMouseOver={(e) => {
              refPayoutId.current = {
                payout,
                arrowPosition: e.currentTarget.offsetLeft + e.currentTarget.clientWidth / 2,
              }
              setOpenInfo(true)
            }}
            onMouseOut={onMouseOut}
          >
            <RowCenter height="100%">
              <Text
                fontSize={row < 12 ? '.8em' : row < 15 ? '.75em' : '0.7em'}
                textAlign="center"
                fontWeight={700}
                color="#000"
              >
                {payout.multiplier > 10 ? payout.multiplier : `${payout.multiplier}x`}
              </Text>
            </RowCenter>
          </StyledPayoutBox>
        ))}

        {isOpenInfo && refPayoutId.current && (
          <PayoutInfo payout={refPayoutId.current?.payout} arrowPosition={refPayoutId.current?.arrowPosition} />
        )}
        <PayoutEventUpdater volumeEnable={volumeEnable} />
      </StyledMultiplierGroup>
    </RowCenter>
  )
}

const StyledMultiplierGroup = styled(Grid)`
  display: flex;
  justify-content: center;
  user-select: none;
  position: relative;
`

const StyledPayoutBox = styled(Box)`
  transition: 0.1s;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
`

export default PayoutGroup
