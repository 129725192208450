import Flex from 'UIKit/Box/Flex'
import Text from 'UIKit/Text'
import { BIG_ONE } from 'config/constants/number'
import { Token } from 'config/types'
import { useTokenUsdPrice } from 'state/app/hooks'
import { getFullDisplayBalance } from 'utils/formatBalance'

const EstimateReceive: React.FC<{ token: Token; amount: string }> = ({ token, amount }) => {
  const tokenUsdPrice = useTokenUsdPrice(token)

  return (
    <Flex alignItems="flex-end">
      <Text bold fontSize="20px">
        {amount ? getFullDisplayBalance(BIG_ONE.div(tokenUsdPrice).times(amount), 0, 5) : 0}
      </Text>
      <Text bold fontSize="14px" ml="4px" mb="1px">
        {token.name}
      </Text>
    </Flex>
  )
}

export default EstimateReceive
