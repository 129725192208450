import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { BoxProps } from 'UIKit/Box/types'
import Popper from 'UIKit/Popper'
import Text from 'UIKit/Text'
import Toggle from 'UIKit/Toggle'
import { Token, TokenAmount } from 'config/types'
import useKeydownEventListener from 'hooks/useKeydownEventListener'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { animated, useSpring } from 'react-spring'
import { useUserSettings } from 'state/profile/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import theme from 'theme'
import { colors } from 'theme/colors'
import FiatSetting from 'views/Fiat/FiatSetting'
import TokenAmountOptionItem, { BalanceOptionItemProps } from './TokenAmountOptionItem'
import { useBalanceSelect } from './hooks'

type BalanceSelectProps = {
  handleSelectBalance: (token: TokenAmount) => void
  selectedToken: Token
  maxHeight?: any
}

type SelectDropdownProps = {
  toggle: boolean
  setOpen: (open: boolean) => void
  onDismiss?: () => void
}

const SelectDropdown: React.FC<SelectDropdownProps & BalanceSelectProps> = ({
  toggle,
  handleSelectBalance,
  selectedToken,
  setOpen,
  maxHeight = '480px !important',
  onDismiss,
}) => {
  const { balanceOptions, selectedBalanceOption } = useBalanceSelect(selectedToken)
  const { updateSetting, userSettings } = useUserSettings()
  const { isMobile } = useMatchBreakpoints()

  const displayBalanceOptions: BalanceOptionItemProps[] = useMemo(() => {
    return [{ ...selectedBalanceOption, disabled: true }, ...balanceOptions]
  }, [balanceOptions, selectedBalanceOption])

  const containerRef = useRef<HTMLDivElement>(null)

  const eventHandler = useCallback(
    (event) => {
      if (event && event.key === 'ArrowDown') {
        event.preventDefault()

        const next = document.activeElement.nextSibling as HTMLElement
        if (next) {
          next.focus()
        } else {
          const firstChild = containerRef.current.firstChild as HTMLElement
          firstChild.focus()
        }
      } else if (event && event.key === 'ArrowUp') {
        event.preventDefault()

        const previous = document.activeElement.previousSibling as HTMLElement
        if (previous) {
          previous.focus()
        } else {
          const lastChild = containerRef.current.lastChild as HTMLElement
          lastChild.focus()
        }
      } else if (event && event.key === 'Enter') {
        const activeElement = document.activeElement as HTMLElement
        if (activeElement) {
          activeElement.click()
        }
      } else if (event && event.key === 'Escape') {
        onDismiss()
      }
    },
    [displayBalanceOptions],
  )
  useKeydownEventListener(eventHandler)

  const styleOpenOption = useSpring({
    config: { duration: 150 },
    opacity: toggle ? 1 : 0,
    y: toggle ? 0 : 20,
    from: toggle ? { opacity: 0, y: 20 } : { opacity: 1, y: 0 },
    onResolve: () => {
      if (!toggle) {
        setOpen(false)
      }
    },
  })

  const handleSelectToken = (selectedTokenAmount: TokenAmount) => {
    if (selectedTokenAmount.token !== selectedBalanceOption.balance.token) handleSelectBalance(selectedTokenAmount)
  }

  return (
    <StyledAnim style={{ ...styleOpenOption }}>
      <StyledOptionsContainer
        style={{
          backdropFilter: isMobile ? 'none' : 'blur(15px)',
          background: colors.cardBackground,
          border: '1px solid #232C3D',
        }}
      >
        <StyledOptionsInnerContainer ref={containerRef} maxHeight={maxHeight}>
          {displayBalanceOptions
            .filter((item) =>
              userSettings.isHideZeroBalance
                ? item.balance.amount.gt(0) || item.bonus.gt(0) || item.balance.token === selectedToken
                : true,
            )
            .map((item, index) => (
              <StyledOptionItem
                className="option-items"
                autoFocus={index === 0}
                key={index}
                $disabled={item?.disabled || false}
                onClick={() => {
                  if (!item?.disabled) handleSelectToken(item.balance)
                  onDismiss()
                }}
              >
                <TokenAmountOptionItem item={item} />
              </StyledOptionItem>
            ))}
        </StyledOptionsInnerContainer>
        <Flex alignItems="center" background={theme.colors.buttonBackgroundAlt2} height="40px">
          <Flex padding="0px 8px" alignItems="center" justifyContent="space-between" width="100%">
            <Text fontSize="14px" bold>
              <Trans>Hide zero balances</Trans>
            </Text>
            <Toggle
              defaultColor="textDisable"
              checkedColor="textTertiary"
              backgroundColor="modalHeaderBackground"
              checkedBackgroundColor="success"
              value={userSettings.isHideZeroBalance}
              onValueChanged={(value) =>
                updateSetting({
                  isHideZeroBalance: value,
                })
              }
            />
          </Flex>
        </Flex>
        <Flex alignItems="center" background={theme.colors.buttonBackgroundAlt2} height="40px">
          <FiatSetting />
        </Flex>
      </StyledOptionsContainer>
    </StyledAnim>
  )
}

const BaseBalanceSelect: React.FC<BoxProps & BalanceSelectProps> = ({
  children,
  handleSelectBalance,
  selectedToken,
  maxHeight,
  ...props
}) => {
  const [toggle, setToggle] = useState(false)
  const [open, setOpen] = useState(false)

  const handleClickSelect = useCallback(
    (value: boolean) => {
      if (value) {
        setOpen(true)
        setToggle(true)
      } else {
        setToggle(false)
      }
    },
    [open],
  )

  return (
    <Popper
      open={open}
      setOpen={handleClickSelect}
      dropdownContent={
        <SelectDropdown
          selectedToken={selectedToken}
          handleSelectBalance={handleSelectBalance}
          toggle={toggle}
          setOpen={setOpen}
          maxHeight={maxHeight}
        />
      }
      dropdownPosition="bottom"
      {...props}
    >
      <Flex height="100%" alignItems="center">
        <Flex flex="1 1" width="100%" alignItems="center">
          {children}
        </Flex>

        <StyledExpansionIconContainer $Open={open}>
          <Icons.ArrowDownIcon fill={colors.textSubtle} />
        </StyledExpansionIconContainer>
      </Flex>
    </Popper>
  )
}

const StyledExpansionIconContainer = styled.div<{ $Open: boolean }>`
  transition: ${({ theme }) => theme.transitions.fast};
  transform: ${({ $Open }) => ($Open ? 'rotate(180deg)' : '')};
  height: 20px;
  min-width: 20px;
  width: 20px;
`

const StyledAnim = styled(animated.div)`
  width: 100%;
  box-sizing: border-box;
`

const StyledOptionsContainer = styled(animated.div)`
  margin-top: 6px;
  border-radius: ${({ theme }) => theme.radii.small};
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  background: ${({ theme }) => theme.colors.inputAlt};
  overflow: hidden;
`

const StyledOptionsInnerContainer = styled(Box)`
  width: 100%;
  scroll-behavior: smooth;
  margin: 8px 0px;
  scrollbar-width: none;
  overflow-y: overlay;
  overflow-x: hidden;

  ::-webkit-scrollbar-thumb {
    background-color: #2d3137 !important;
    visibility: visible;
  }
`

const StyledOptionItem = styled.button<{ $disabled?: boolean }>`
  cursor: pointer;
  background: ${({ $disabled }) => ($disabled ? 'rgba(255, 255, 255, 0.05)' : 'transparent')};
  display: block;
  width: 100%;
  border-radius: 0px;
  padding: 12px;
  outline: none;
  border: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }

  &:focus-visible {
    background-color: rgba(0, 0, 0, 0.15);
  }
  &:disabled {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }
`

export default BaseBalanceSelect
