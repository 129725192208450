import { BoxProps } from 'UIKit/Box/types'

export const animation = {
  WAVES: 'waves',
  PULSE: 'pulse',
  PULSE_RING: 'pulse-rign',
} as const

export const variant = {
  RECT: 'rect',
  CIRCLE: 'circle',
} as const

export type Animation = (typeof animation)[keyof typeof animation]
export type Variant = (typeof variant)[keyof typeof variant]

export interface SkeletonProps extends BoxProps {
  variant?: Variant
  className?: string
}
