import BigNumber from 'bignumber.js'
import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import EmptyContent from 'UIKit/EmptyContent'
import Skeleton from 'UIKit/Skeleton'
import { useTable } from 'UIKit/Table/hooks'
import Paging from 'UIKit/Table/Paging'
import { ColumnsDefTypes } from 'UIKit/Table/type'
import Text from 'UIKit/Text'
import TokenLogo from 'UIKit/TokenLogo'
import { emptyPagingReponse } from 'config/constants'
import { HUSD_TOKEN } from 'config/constants/tokens'
import { HusdUnlockTxnStatusEnums } from 'config/constants/transactions'
import { useCallback, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import BonusService from 'services/BonusService'
import { HUSDClaimedTransaction, Paging as APIPaging } from 'services/types'
import { formatDisplayDateTime } from 'utils/dateHelper'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { getHusdStatus } from 'views/Profile/Transactions/components/styled'
import SelectHusdHistoryType from './SelectHusdHistoryStatus'

const HUSDClaimnedHistoryColumnSchema: ColumnsDefTypes[] = [
  {
    id: 0,
    name: 'createdAt',
    label: 'CreationTime',
    width: '50%',
  },
  {
    id: 1,
    name: 'amount',
    label: 'Amount',
    width: '50%',
  },
  {
    id: 2,
    name: 'status',
    label: 'Status',
    width: '0%',
  },
]

const HistoryTable: React.FC<{ totalClaimed: BigNumber }> = ({ totalClaimed }) => {
  const limit = 4
  const { t } = useTranslation()
  const [txnStatusFilter, setTxnStatusFilter] = useState<HusdUnlockTxnStatusEnums>(HusdUnlockTxnStatusEnums.All)

  const fetchHUSDClaimedHistory = useCallback(
    async (offset: number, limit: number): Promise<APIPaging<HUSDClaimedTransaction>> => {
      let filterOptions: any = null
      if (txnStatusFilter === HusdUnlockTxnStatusEnums.Claimed)
        filterOptions = {
          in_out_status: HusdUnlockTxnStatusEnums.Outcome,
          type: HusdUnlockTxnStatusEnums.Claimed,
        }
      if (txnStatusFilter === HusdUnlockTxnStatusEnums.Received)
        filterOptions = {
          in_out_status: HusdUnlockTxnStatusEnums.Received,
        }

      const result = await BonusService.getHUSDClaimedHistory(offset, limit, filterOptions).call()

      if (!result) return emptyPagingReponse

      return {
        paging: { ...result.data.paging, total: null },
        items: result.data.items,
      }
    },
    [limit, txnStatusFilter],
  )

  const { rows, pagination, isLoading, dispatch } = useTable(HUSDClaimnedHistoryColumnSchema, fetchHUSDClaimedHistory, {
    displayPerPage: limit,
  })

  const handleChangeTxnStatusFilter = useCallback((selectedTxnStatusFilter: HusdUnlockTxnStatusEnums) => {
    setTxnStatusFilter(selectedTxnStatusFilter)
    dispatch({
      type: 'GOTO_FIRST_PAGE',
    })
  }, [])

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb="12px">
        <Text fontSize="14px" color="text" bold>
          <Trans>History</Trans>
        </Text>

        <Flex alignItems="center">
          <Text fontSize="14px" color="text">
            <Trans>Total Claimed</Trans>
          </Text>

          <TokenLogo token={HUSD_TOKEN} size={16} ml="10px" mr="4px" />

          <Text fontSize="14px" color="text">
            {getFullDisplayBalance(totalClaimed, 0, 3)}
          </Text>
        </Flex>
      </Flex>

      <SelectHusdHistoryType value={txnStatusFilter} onFilterChanged={handleChangeTxnStatusFilter} />

      <Box minHeight="152px">
        {rows.map((row) => {
          const husdStatus = getHusdStatus(row.original.status)

          return (
            <Flex flexDirection="column" mb="24px" key={row.original.createdAt}>
              <Flex justifyContent="space-between" alignItems="center" mb="12px">
                <Text fontSize="14px" bold>
                  <Trans>{row.original.status === HusdUnlockTxnStatusEnums.Claimed ? 'Claimed' : 'Received'}</Trans>
                </Text>

                <Text fontSize="14px" fontWeight="700">
                  {getFullDisplayBalance(new BigNumber(row.original.amount), 0, 18)} HUSD
                </Text>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Text fontSize="12px" fontWeight="300">
                  {formatDisplayDateTime(new Date(row.original.createdAt))}
                </Text>

                {husdStatus ? <Text fontSize="12px">{t(husdStatus)}</Text> : null}
              </Flex>
            </Flex>
          )
        })}

        {rows?.length === 0 && isLoading && (
          <EmptyContent content="No transaction to show" py={3} justifyContent="center" />
        )}

        {isLoading && (
          <>
            <Skeleton minHeight="14px !important" mb="24px" />
            <Skeleton minHeight="14px !important" mb="24px" />
            <Skeleton minHeight="14px !important" mb="24px" />
            <Skeleton minHeight="14px !important" mb="24px" />
          </>
        )}
      </Box>

      <Flex justifyContent="flex-end">
        <Paging pagination={pagination} />
      </Flex>
    </Box>
  )
}

export default HistoryTable
