import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import Select, { StyledOptionsContainer } from 'UIKit/Select'
import Text from 'UIKit/Text'
import TransText from 'UIKit/Text/TransText'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'theme/colors'
import { buildPluralizeText } from 'utils'
import { StatisticsTimeFilterEnum, StatisticsTimeFilterList, StatisticsTimeFilterType } from './type'

const SelectItemComponent: React.FC<{
  item: {
    value: StatisticsTimeFilterEnum
    content: string
  }
}> = ({ item }) => {
  const { t } = useTranslation()

  return (
    <Text fontSize={['12px', '', '', '14px']} fontWeight={300} textAlign="left" color="textSubtle">
      {item.value
        ? t(
            buildPluralizeText('{{activationDuration}} {{day}}', [
              { number: item.value, key: 'day', word: ['day', 'days'] },
            ]),
            { activationDuration: item.value },
          )
        : t('All')}
    </Text>
  )
}

const StatisticsTimeSelect: React.FC<
  {
    handleChangeOption: (option: StatisticsTimeFilterType) => void
    timeSelected: StatisticsTimeFilterType
  } & BoxProps
> = ({ handleChangeOption, timeSelected, ...props }) => {
  const { t } = useTranslation()

  return (
    <StyledStaticsTimeSelect border={`1px solid ${colors.buttonBackgroundAlt2}`} {...props}>
      <Select
        options={StatisticsTimeFilterList.filter((item) => item.value !== timeSelected.value)}
        onSelect={handleChangeOption}
        OptionItemComponent={SelectItemComponent}
        dropdownContentProps={{
          style: {
            width: '100%',
          },
        }}
      >
        <TransText
          fontSize={['12px', '', '', '14px']}
          fontWeight={300}
          letterSpacing="-0.28px"
          color={colors.textTertiary}
          pl="12px"
        >
          {timeSelected.value
            ? t('{{activationDuration}} days', {
                activationDuration: timeSelected.value,
              })
            : t('All')}
        </TransText>
      </Select>
    </StyledStaticsTimeSelect>
  )
}

const StyledStaticsTimeSelect = styled(Box)`
  width: 120px;
  height: 27px;
  border-radius: 4px;

  ${StyledOptionsContainer} {
    background-color: ${({ theme }) => theme.colors.cardBackground};
  }
`

export default StatisticsTimeSelect
