import { FlexProps } from 'UIKit/Box/types'
import { Position } from 'UIKit/Popper/typpes'
import Text from 'UIKit/Text'
import Column from 'layout/Components/Column'
import { RowMiddle } from 'layout/Components/Row'
import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { WHEEL_SEGMENTS } from 'views/InhouseGame/Wheel/config'
import { WheelSegments } from 'views/InhouseGame/Wheel/config/types'
import { InHouseLabel, WrapperFilter } from 'views/InhouseGame/components/styled'

const WheelSegmentsSelect: React.FC<
  {
    segment: WheelSegments
    onSelect: (segment: WheelSegments) => void
    disabled?: boolean
    dropdownPosition?: Position
  } & Omit<FlexProps, 'onSelect'>
> = ({ segment, onSelect, disabled, height = '40px', dropdownPosition, ...props }) => {
  const selected = useMemo(() => WHEEL_SEGMENTS.find((s) => s.value === segment), [segment])
  const options = useMemo(
    () => (selected ? WHEEL_SEGMENTS.filter((item) => item !== selected) : WHEEL_SEGMENTS),
    [WHEEL_SEGMENTS, selected],
  )

  return (
    <Column {...props}>
      <InHouseLabel>
        <Trans>Segments</Trans>
      </InHouseLabel>
      <WrapperFilter
        disabled={disabled}
        options={options}
        onSelect={onSelect}
        OptionItemComponent={FilterOptionItem}
        height={height}
        dropdownPosition={dropdownPosition}
      >
        {selected && <FilterOptionItem item={selected} />}
      </WrapperFilter>
    </Column>
  )
}

const FilterOptionItem = ({ item }) => {
  return (
    <RowMiddle>
      <Text fontSize="1em">{item.name}</Text>
    </RowMiddle>
  )
}

export default WheelSegmentsSelect
