import BigNumber from 'bignumber.js'
import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import Text from 'UIKit/Text'
import { Trans } from 'react-i18next'
import USDAmountDisplay from 'views/Fiat/USDAmountDisplay'

export const ActivitiesStatisticsCard = ({
  title,
  value,
  ...props
}: {
  title: string
  value: number | string | BigNumber
} & BoxProps) => {
  return (
    <Box {...props}>
      <Text fontSize="14px" fontWeight={400} lineHeight="normal" letterSpacing={-0.28} color="textAlt1">
        <Trans>{title}</Trans>
      </Text>
      <Text fontSize="14px" fontWeight={400} lineHeight="normal" letterSpacing={-0.28} color="text" marginTop="4px">
        <USDAmountDisplay amount={value} />
      </Text>
    </Box>
  )
}
