import IconButton from 'UIKit/Button/IconButton'
import styled from 'styled-components'
import { Icons } from 'svgs'
import hunnyKeyframes from 'theme/keyframes'

export const CloseButtonModal: React.FC<{ onDismiss: () => void }> = ({ onDismiss }) => (
  <StyledButtonCloseModal className="modal-closebutton" id="modal-closebutton" onClick={onDismiss}>
    <Icons.CloseIcon width="24" height="24" />
  </StyledButtonCloseModal>
)

export const StyledButtonCloseModal = styled(IconButton)`
  position: absolute;
  background: transparent;
  z-index: 5;
  width: 24px;
  height: 24px;
  top: 18px;
  right: 25px;
  cursor: pointer;
  animation: ${hunnyKeyframes.fade} 1s ease-out forwards;

  > svg {
    margin-left: 0 !important;
    fill: rgba(235, 235, 245, 0.6);
  }
`
