import useModal from 'hooks/useModal'
import DailyCheckinModal from './DailyCheckinModal'

const DAILY_CHECKIN_MODAL_KEY = 'daily-checkin'

const useDailyCheckinModal = () => {
  const modal = useModal(DailyCheckinModal, DAILY_CHECKIN_MODAL_KEY)
  return modal
}

export default useDailyCheckinModal
