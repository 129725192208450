import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { BoxProps } from 'UIKit/Box/types'
import OpenEffect from 'UIKit/OpenEffect'
import Text from 'UIKit/Text'
import { ValidationError } from 'config/types/validator'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'

interface InputInstructionProps extends BoxProps {
  data?: {
    validationError: ValidationError
    message: string
  }[]
  errors?: ValidationError[]
}

const InputInstruction: React.FC<InputInstructionProps> = ({ data, errors, ...props }) => {
  return (
    <Box position="relative">
      <StyledInstructionContainer {...props}>
        {data.map((item) => (
          <Flex key={item.validationError} alignItems="center" mb="10px">
            <Box width="16px" height="16px">
              {errors.includes(item.validationError) ? (
                <Icons.CloseCircleIcon fill={colors.error} width="16px" height="16px" />
              ) : (
                <Icons.SuccessIcon fill={colors.success} width="16px" height="16px" />
              )}
            </Box>
            <Text fontSize="12px" color="textTertiary" fontWeight="300" ml="6px">
              {item.message}
            </Text>
          </Flex>
        ))}
      </StyledInstructionContainer>
    </Box>
  )
}

const StyledInstructionContainer = styled(OpenEffect)`
  position: absolute;
  margin-top: 8px;
  border-radius: ${({ theme }) => theme.radii.large};
  background: ${({ theme }) => theme.colors.backgroundAlt3};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  z-index: 2;

  padding: 16px 12px;
  padding-bottom: 6px;
`
export default InputInstruction
