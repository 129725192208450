import { useDailyRewardCampaign } from 'state/bonus/hooks/dailyReward'
import { ModalProps } from 'UIKit/Modal'
import DailyCheckinNonCampaign from './DailyCheckinNonCampaign'
import DailyCheckinCampaignModal from './DailyCheckinCampaignModal'

const DailyCheckinModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { campaign } = useDailyRewardCampaign()
  return (
    <>
      {campaign ? (
        <DailyCheckinCampaignModal onDismiss={onDismiss} />
      ) : (
        <DailyCheckinNonCampaign onDismiss={onDismiss} />
      )}
    </>
  )
}

export default DailyCheckinModal
