import BigNumber from 'bignumber.js'
import { ChainIdEnum } from 'config/constants/network'
import tokens from 'config/constants/tokens'
import { CashbackTransaction } from 'config/types/cashback'
import { Notification, NotificationStatusEnums } from 'config/types/notification'
import { ProfileTierInfo, WagerInfo } from 'config/types/profile'
import {
  CampaignRate,
  MAX_CAMPAIGN_RATE,
  Referee,
  ReferralCampaign,
  ReferralCampaignVersion,
} from 'config/types/referral'
import { StakingPoolStat } from 'config/types/staking'
import {
  BonusTransaction,
  RewardTransaction,
  SwapTransaction,
  TipTransaction,
  Transaction,
  TransactionTypeEnum,
} from 'config/types/transaction'
import { formatApiNetworkField } from 'utils'
import { isFiatCurrencyV2 } from 'utils/token'

export const parseTierInfos = (tierInfos) => {
  if (tierInfos)
    return {
      id: tierInfos.id,
      code: tierInfos.code,
      level: tierInfos.level,
      logoUrl: tierInfos.icon_url,
      logoWithTextUrl: tierInfos.logo_url,
      mainCashbackPercentage: tierInfos.main_cashback_percentage,
      minWagerUsd: tierInfos.min_wager_usd,
      otherCashbackPercentage: tierInfos.other_cashback_percentage,
      bonusUsd: tierInfos.bonus_usd,
    } as ProfileTierInfo

  return null
}

export const getUserLevelStats = (tiers: ProfileTierInfo[], myTier: ProfileTierInfo, totalWager: string): WagerInfo => {
  const score = new BigNumber(totalWager)

  const targetOfCurrentLevel = myTier?.minWagerUsd || new BigNumber(0)
  const targetOfNextLevel: BigNumber =
    new BigNumber(tiers?.find((tier) => tier.level === myTier.level + 1)?.minWagerUsd) || new BigNumber(0)

  const initPercentage = score
    .minus(targetOfCurrentLevel)
    .dividedBy(targetOfNextLevel.minus(targetOfCurrentLevel))
    .multipliedBy(100)
  const checkPercentage = initPercentage?.gt(0)
    ? initPercentage
    : score?.minus(targetOfNextLevel)?.gt(0)
    ? new BigNumber(1)
    : new BigNumber(0)
  const parseCheckPercentage = Number(checkPercentage.toString(10).split('.')[0])
  const percentage = parseCheckPercentage > 100 ? 100 : parseCheckPercentage

  return {
    score: score.toNumber(),
    percentage,
    target: targetOfNextLevel.toNumber(),
  }
}

export const parseCashbackTransaction = (transactions: any[]): CashbackTransaction[] => {
  if (transactions)
    return transactions.map((item) => {
      return {
        id: item.id,
        currency: item.currency,
        network: formatApiNetworkField(item.network) as any,
        status: item.status,
        fromTime: item.from_time * 1000,
        toTime: item.to_time * 1000,
        value: item.value,
        percent: item.tier_percentage,
        createTime: item.create_time * 1000,
      } as CashbackTransaction
    })

  return []
}

export const parseReferralCampaign = (campaign: any): ReferralCampaign => {
  const totalDepositUSD = campaign?.deposit_sum_usd || '0'
  const totalWagerUSD = campaign?.game_bet_sum_usd || '0'

  const commissionEarnedUSD = campaign?.game_commission_sum_usd || '0'
  const referralBonusUSD = campaign?.tier_reward_sum_usd || '0'
  const totalProfitUSD = Number(commissionEarnedUSD) + Number(referralBonusUSD)

  const refereePercentage = Number(campaign.referee_percentage)
  const referrerPercentage = Number(campaign.referrer_percentage)

  const shareCommission =
    campaign.version === ReferralCampaignVersion.Old
      ? Math.round((refereePercentage / (refereePercentage + referrerPercentage)) * 100 * 10) / 10
      : campaign.referee_percentage
  const userCommission =
    campaign.version === ReferralCampaignVersion.Old
      ? Math.round((referrerPercentage / (refereePercentage + referrerPercentage)) * 100 * 10) / 10
      : campaign.referrer_percentage

  return {
    id: Number(campaign.id),
    uid: Number(campaign.uid),
    code: campaign.code,
    name: campaign.name,

    commissionEarnedUSD,
    referralBonusUSD,
    refereeCount: campaign.referee_count,

    depositCount: campaign?.deposit_count || 0,
    depositFirstCount: campaign?.deposit_first_count || 0,
    depositFirstUSD: campaign?.deposit_first_sum_usd || '0',

    userCommission,
    shareCommission,
    userReferredCount: campaign.referee_count,
    commissionRate: campaign.version === ReferralCampaignVersion.Old ? CampaignRate.Old : MAX_CAMPAIGN_RATE,
    totalDepositUSD,
    totalWagerUSD,
    totalProfitUSD: totalProfitUSD.toString(),

    createTime: campaign.create_time * 1000,
    isDefault: campaign.is_default,
  }
}

export const parseRefereeList = (data: any): Referee[] => {
  const campaigns: ReferralCampaign[] = data.campaigns.map((campaign) => parseReferralCampaign(campaign)) || []

  return data.items?.map((item) => {
    return {
      user: {
        avatar: item.avatar_url,
        displayName: item.display_name,
        registeredAt: item.join_time * 1000,
        userCode: item.code,
        tierId: item.tier_id,
      },
      referrerUserCode: item.referral_referee_code,
      createTime: item.join_time * 1000,

      totalDepositUSD: Number(item.deposit_sum_usd),
      totalWagerUSD: Number(item.game_bet_sum_usd),
      totalProfitUSD: Number(item.game_commission_share_sum_usd) + Number(item.tier_reward_referer_sum_usd),
      commissionEarnedUSD: Number(item.game_commission_share_sum_usd),
      referralBonusUSD: Number(item.tier_reward_referer_sum_usd),

      campaign: campaigns.find((campaign) => Number(item?.referrer_campaign_id) === Number(campaign.id)),
    }
  })
}

export const parseStakingPoolStat = (data: any, valueField: string): StakingPoolStat[] => {
  return data.map(
    (item) =>
      ({
        currency: item.currency,
        network: ChainIdEnum[formatApiNetworkField(item.network)],
        value: item[valueField],
      } as StakingPoolStat),
  )
}

export const parseWithdrawalTransaction = (data: any): Transaction => ({
  id: data.id,
  code: data.code,
  status: data.status,
  txnHash: data.txn_hash,
  value: data.value,
  createTime: data.create_time * 1000,
  currency: data.currency,
  network: ChainIdEnum[formatApiNetworkField(data.network)],
  type: TransactionTypeEnum.Withdraw,
  fee: data.fee,
  fiat: isFiatCurrencyV2(tokens[ChainIdEnum[formatApiNetworkField(data.network)]]?.[data.currency]) &&
    data.extra_data && {
      bankName: data.extra_data.bank_name || 'Unknown',
    },
  instruction: data?.extra_data?.instruction,
})

export const parseDepositTransaction = (data: any): Transaction => ({
  id: data.id,
  status: data.status,
  txnHash: data.txn_hash,
  value: data.value,
  createTime: data.create_time * 1000,
  currency: data.currency,
  network: ChainIdEnum[formatApiNetworkField(data.network)],
  type: TransactionTypeEnum.Deposit,
  fee: data.fee,
  isFtd: !!data?.is_first_deposit,
  fiat: isFiatCurrencyV2(tokens[ChainIdEnum[formatApiNetworkField(data.network)]]?.[data.currency]) &&
    data.extra_data && {
      bankName: data.extra_data.bank_name || 'Unknown',
    },
})

export const parseRewardTransaction = (data: any): RewardTransaction => ({
  id: data.id,
  value: data.value,
  createTime: data.create_time * 1000,
  currency: data.currency,
  network: ChainIdEnum[formatApiNetworkField(data.network)],
  note: data.note,
  subject: data.subject,
})

export const parseBonusTransaction = (data: any): BonusTransaction => ({
  id: data.id,
  value: data.value,
  createTime: data.create_time * 1000,
  currency: data.currency,
  network: ChainIdEnum[formatApiNetworkField(data.network)],
  subject: data.subject,
  level: data.level,
})

export const parseSwapTransaction = (data: any): SwapTransaction => ({
  id: data.id,
  createTime: data.create_time * 1000,
  fromCurrency: data.from_currency,
  fromNetwork: ChainIdEnum[formatApiNetworkField(data.from_network)],
  fromValue: data.from_value,
  toCurrency: data.to_currency,
  toNetwork: ChainIdEnum[formatApiNetworkField(data.to_network)],
  toValue: data.to_value,
})

export const parseTipTransaction = (data: any): TipTransaction => {
  return {
    createTime: data.create_time * 1000,
    amount: data.amount,
    currency: data.currency,
    network: formatApiNetworkField(data.network),
    message: data.message,
    sender: {
      avatar: data.sender.avatar_url,
      code: data.sender.user_code,
      displayName: data.sender.display_name,
      levelId: data.sender.tier_id,
    },
    receiver: {
      avatar: data.receiver.avatar_url,
      code: data.receiver.user_code,
      displayName: data.receiver.display_name,
      levelId: data.receiver.tier_id,
    },
    id: data?.id,
  }
}

export const parseNotificationItem = (data: any): Omit<Notification, 'type'> => ({
  id: data.id,
  title: data.title,
  bannerUrl: data.banner_url,
  description: data.message || '',
  createTime: data.publish_time * 1000,
  external: data.open_in_new_tab,
  url: data.open_in_new_tab === true ? data.url : `/${data.url}`,
  anchorText: data.button_text,
  status:
    data.read_time && new Date(data.read_time * 1000) < new Date()
      ? NotificationStatusEnums.READ
      : NotificationStatusEnums.UNREAD,
})

export const parseToken = (network, currency) => tokens[ChainIdEnum[formatApiNetworkField(network)]]?.[currency]

export const parseFiatBankMethod = (data: any, methodTypeField: string) => {
  return {
    code: data.code,
    currency: data.currency,
    type: data[methodTypeField],
    name: data.name,
    thumbnail: data.thumb_url || '/example.png',
    isActive: data.status !== -1,
    minAmount: data.tx_min_amount,
    maxAmount: data.tx_max_amount,
  }
}
