import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { BoxProps } from 'UIKit/Box/types'
import Text from 'UIKit/Text'
import { useCountDown } from 'hooks/useCountdown'
import { useState } from 'react'
import { Trans } from 'react-i18next'
import { getHUSDBonus } from 'utils/signupBonus'

export interface BonusCountdownProps extends BoxProps {
  onExpired: () => void
}

const BonusCountdown: React.FC<BonusCountdownProps> = ({ onExpired, ...props }) => {
  const bonus = getHUSDBonus()
  const [isBonusExpired, setIsBonusExpired] = useState(bonus.isExpired)

  const shudownTimeTextRef = useCountDown(
    bonus?.expiredAt,
    (s, m, h) => {
      return `${h.toString().padStart(2, '0')} : ${m.toString().padStart(2, '0')} : ${s.toString().padStart(2, '0')}`
    },
    () => {
      setIsBonusExpired(true)
      onExpired()
    },
  )

  return (
    !isBonusExpired && (
      <Box {...props}>
        <Flex flexDirection="column" alignItems="center">
          <Flex alignItems="center">
            ✅
            <Text fontSize="12px" color="textTertiary" bold ml="2px">
              <Trans
                i18nKey="spinBonusSignUpText"
                components={{ highlight_text: <Text fontSize="12px" bold as="span" color="textTertiary" /> }}
                values={{
                  amount: bonus?.amount,
                }}
              />
            </Text>
          </Flex>
          <Text bold fontSize="12px" color="textTertiary">
            <Trans>Claim your prize now signing up within</Trans>
          </Text>
        </Flex>
        <Text ref={shudownTimeTextRef} fontSize={['16px']} color="text" fontWeight={600} textAlign="center" mt="4px" />
      </Box>
    )
  )
}

export default BonusCountdown
