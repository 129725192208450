export const sanitize = (input) => {
  const doc = new DOMParser().parseFromString(input, 'text/html')
  for (const elm of doc.querySelectorAll('*')) {
    for (const attrib of elm.attributes) {
      if (attrib.name.startsWith('on')) {
        elm.removeAttribute(attrib.name)
      }
    }
  }
  return doc.body.innerHTML
}

export const toggleHtmlScrollable = (scrollable: boolean) => {
  if (typeof window == 'undefined') return

  if (!scrollable) {
    const scrollTop = window.document.documentElement.scrollTop
    window.document.body.style.maxHeight = '0px'
    window.document.body.scrollTop = scrollTop
  } else {
    const scrollTop = window.document.body.scrollTop
    window.document.body.style.maxHeight = 'revert'
    window.document.documentElement.scrollTop = scrollTop
  }
}
