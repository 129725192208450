import { HunnyRequest } from 'services/types'

export enum ValidationError {
  Email = 'Email',
  EmailDuplicate = 'EmailDuplicate',
  EmailNotExist = 'EmailNotExist',
  EmailDomainBanned = 'EmailDomainBanned',
  UserNotExist = 'UserNotExist',
  WrongPassword = 'WrongPassword',
  Required = 'Required',
  RequiredNumber = 'RequiredNumber',
  RequiredInteger = 'RequiredInteger',
  RequiredUppercase = 'RequiredUppercase',
  Integer = 'Integer',
  IncorrectLength = 'IncorrectLength',
  IncorrectType = 'IncorrectType',
  DataExpired = 'DataExpired',
  NotEqual = 'NotEqual',
  Equal = 'Equal',
  TooShort = 'TooShort',
  TooLong = 'TooLong',
  NotEnough = 'NotEnough',
  Insufficient = 'Insufficient',
  IsZero = 'IsZero',
  InCorrect = 'InCorrect',
  InCorrect2FA = 'InCorrect2FA',
  NotChecked = 'NotChecked',
  GreatThanEqual = 'GreatThanEqual',
  GreatThan = 'GreatThan',
  LessThanEqual = 'LessThanEqual',
  LessThan = 'LessThan',
  WrongAddressFormat = 'WrongAddressFormat',
  IsEtherAddress = 'IsEtherAddress',
  ValueSubmited = 'ValueSubmited',
  BonusFullyClaimed = 'BonusFullyClaimed',
  BonusUserNotQualified = 'BonusUserNotQualified',
  Username = 'Username',
  UsernameDuplicated = 'UsernameDuplicated',
  TokenInBlackList = 'TokenInBlackList',
}

export type InputValidator = (value: any) => ValidationError | null | HunnyRequest<ValidationError>
export type GroupValidator = (formValues: { [fieldName: string]: any }) => InputValidator
