import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import { ModalProps } from 'UIKit/Modal'
import React, { MutableRefObject, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import { useAppSelector } from 'state'
import styled, { keyframes } from 'styled-components'
import { toggleHtmlScrollable } from 'utils/html'

interface ModalsContext {
  onPresent: (id: string, Component: React.FC, data?: any, disableCloseByBackdrop?: boolean) => void
  onDismiss: (id: string) => void
  closeModal: () => void
  contentsRef: MutableRefObject<{ id: string; content: any }[]>
  contentIds: string[]
}

export interface IModalNavigation {
  onBack?: (param: any) => void
}

export const Context = createContext<ModalsContext>({
  onPresent: () => {},
  onDismiss: () => {},
  closeModal: () => {},
  contentsRef: null,
  contentIds: [],
})

const animationDuration = 200

const OpenModalEffect: React.FC<{ open: boolean; onClose: () => void; display?: string; children: any }> = ({
  open,
  onClose,
  display,
  children,
}) => {
  const styleOpenModal = useSpring({
    from: { scale: open ? 0.8 : 1 },
    to: { scale: open ? 1 : 0.8, opacity: open ? 1 : 0 },
    config: {
      duration: animationDuration,
    },
    onResolve: () => {
      if (!open) {
        onClose()
      }
    },
  })

  return (
    <StyledResponsiveWrapper display={display} style={styleOpenModal}>
      {children}
    </StyledResponsiveWrapper>
  )
}

const Modals = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [contentId, setContentId] = useState<React.ReactNode>()
  // Make sure modal will open when metadata ready
  const serverTime = useAppSelector((state) => state.app.serverTime)
  const contentsRef = useRef([])
  const isClosingRef = useRef(false)

  const contentIds = useMemo(() => {
    return contentsRef.current.map((content) => content.id)
  }, [contentId])

  const currentContent = useMemo(() => {
    return contentsRef.current.find((content) => contentId === content.id)
  }, [contentId])

  const handlePresent = useCallback(
    (id: string, Component: React.FC<ModalProps>, data?: any, disableCloseByBackdrop = false) => {
      const present = () => {
        contentsRef.current.push({
          id,
          disableCloseByBackdrop,
          content: (
            <Component
              data={data}
              key={id}
              onDismiss={() => {
                handleDismiss(id)
              }}
            />
          ),
        })
        setContentId(id)
        setToggle(true)
        setIsOpen(true)

        toggleHtmlScrollable(false)
        document.body.classList.add('modal-open')
      }

      if (!isClosingRef.current) {
        present()
      } else {
        setTimeout(present, animationDuration + 1000)
      }
    },
    [setContentId, setToggle, toggle, isOpen],
  )

  const handleDismiss = useCallback(
    (id?: string) => {
      const contents = id
        ? contentsRef.current.filter((item) => item.id !== id)
        : contentsRef.current.slice(0, contentsRef.current.length - 1)

      if (contents.length === 0) {
        handleCloseAllModal()
        return
      }

      contentsRef.current = contents
      setContentId(contentsRef.current[contentsRef.current.length - 1].id)
    },
    [setContentId, setIsOpen],
  )

  const handleCloseAllModal = useCallback(() => {
    if (contentsRef.current.length > 0) {
      isClosingRef.current = true
      setToggle(false)
    }
  }, [setToggle])

  const closeModal = useCallback(() => {
    setIsOpen(false)
    contentsRef.current = []
    setContentId(undefined)
    toggleHtmlScrollable(true)
    document.body.classList.remove('modal-open')
    isClosingRef.current = false
  }, [setIsOpen])

  const value = useMemo(
    () => ({
      onPresent: handlePresent,
      onDismiss: handleDismiss,
      closeModal: handleCloseAllModal,
      contentsRef,
      contentIds,
    }),
    [handlePresent, handleDismiss, handleCloseAllModal],
  )

  return (
    <Context.Provider value={value}>
      {children}
      {isOpen && serverTime && (
        <StyledModalWrapper>
          <Backdrop
            id="modal-backdrop"
            onClick={() => !currentContent.disableCloseByBackdrop && handleDismiss()}
            style={{
              background: toggle ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.0)',
            }}
          />

          {contentsRef.current.map((item) => (
            <OpenModalEffect
              open={toggle}
              onClose={closeModal}
              display={item.id === contentId ? 'flex' : 'none'}
              key={item.id}
            >
              {item.content}
            </OpenModalEffect>
          ))}
        </StyledModalWrapper>
      )}
    </Context.Provider>
  )
}

const Backdrop: React.FC<BoxProps> = ({ onClick, ...props }) => {
  const isModalOpened = useRef(false)
  useEffect(() => {
    setTimeout(() => {
      isModalOpened.current = true
    }, 300)
  })

  const handleClick = () => {
    if (isModalOpened.current) {
      onClick(null)
    }
  }
  return <StyledModalBackdrop onClick={handleClick} {...props} />
}

const mobileKeyframes = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
`

const StyledResponsiveWrapper = styled(animated.div)<{ display: string }>`
  display: ${({ display }) => display};
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  animation: ${mobileKeyframes} 0.2s forwards ease-out;

  ${({ theme }) => theme.mediaQueries.sm} {
    position: relative;
    animation: none;
    top: 0%;
    justify-content: center;
  }
`

const StyledModalWrapper = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
`

const StyledModalBackdrop = styled.div`
  transition: 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export default Modals
