import Text from 'UIKit/Text'
import FormValidator from 'config/constants/formValidator'
import { INHOUSE_GAMES, InhouseGameType } from 'config/types/game'
import useForm from 'hooks/useForm'
import Column, { ColumnCenter } from 'layout/Components/Column'
import { useState } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import theme from 'theme'
import {
  InhouseGameProbilityFairBetInfo,
  MineProbilityFairBetInfo,
  PlinkoProbilityFairBetInfo,
} from 'views/InhouseGame/config/types'
import GameSelect from './components/GameSelect'
import InputControl from './components/InputControl'
import PlinkoVerifyForm from './PlinkoVerifyForm'
import LimboVerifyForm from './LimboVerifyForm'
import { DiceAnimation, LimboAnimation, MineAnimation, PlinkoAnimationBounce, WheelAnimation } from '../../styled'
import DiceVerifyForm from './DiceVerifyForm'
import WheelVerifyForm from './WheelVerifyForm'
import MineVerifyForm from './MineVerifyForm'

const VerifyForm: React.FC<{
  initialInhouseGameType: InhouseGameType
  initialBet: InhouseGameProbilityFairBetInfo
}> = ({ initialInhouseGameType, initialBet }) => {
  const [resultUI, setResultUI] = useState(null)
  const [inhouseGameType, setInhouseGameType] = useState<InhouseGameType>(
    initialInhouseGameType || initialBet.type || INHOUSE_GAMES[0].type,
  )

  const { states, controls } = useForm({
    clientSeed: {
      validators: [FormValidator.required],
      value: initialBet?.seed?.clientSeed || '',
    },
    serverSeed: {
      validators: [FormValidator.required],
      value: initialBet?.seed?.serverSeed || '',
    },
    nonce: {
      validators: [FormValidator.required],
      value: initialBet?.seed?.nonce || '0',
    },
  })
  return (
    <Column>
      <StyledBoxWarning mb="16px">
        {!resultUI ? (
          <>
            <Text ml="8px" color="textSubtle" small lineHeight={1.5}>
              <Trans>More inputs are required to verify result</Trans>
            </Text>
            {inhouseGameType === InhouseGameType.Plinko && (
              <PlinkoAnimationBounce key="plinko-animation" mt="12px" color={theme.colors.textSubtle} />
            )}
            {inhouseGameType === InhouseGameType.Dice && (
              <DiceAnimation key="dice-animation" mt="12px" color={theme.colors.textSubtle} />
            )}
            {inhouseGameType === InhouseGameType.Limbo && (
              <LimboAnimation key="limbo-animation" mt="12px" color={theme.colors.textSubtle} />
            )}
            {inhouseGameType === InhouseGameType.Wheel && (
              <WheelAnimation key="wheel-animation" mt="12px" color={theme.colors.textSubtle} />
            )}
            {inhouseGameType === InhouseGameType.Mine && (
              <MineAnimation key="wheel-animation" mt="12px" color={theme.colors.textSubtle} />
            )}
          </>
        ) : (
          <>{resultUI}</>
        )}
      </StyledBoxWarning>

      <GameSelect inhouseGameType={inhouseGameType} onSelect={setInhouseGameType} mb="16px" height="44px" />

      <InputControl
        label="Client Seed"
        state={states.clientSeed}
        value={states.clientSeed.value}
        validators={controls.clientSeed.validators}
        onErrorChanged={controls.clientSeed.onErrorChanged}
        onValueChanged={controls.clientSeed.onValueChanged}
        mb="16px"
      />

      <InputControl
        label="Server Seed"
        state={states.serverSeed}
        value={states.serverSeed.value}
        validators={controls.serverSeed.validators}
        onErrorChanged={controls.serverSeed.onErrorChanged}
        onValueChanged={controls.serverSeed.onValueChanged}
        mb="16px"
      />

      <InputControl
        label="Nonce"
        state={states.nonce}
        value={states.nonce.value}
        validators={controls.nonce.validators}
        onErrorChanged={controls.nonce.onErrorChanged}
        onValueChanged={controls.nonce.onValueChanged}
        type="number"
        mb="16px"
      />

      {inhouseGameType === InhouseGameType.Plinko && (
        <PlinkoVerifyForm
          initialPlinkoBet={initialBet as PlinkoProbilityFairBetInfo}
          clientSeed={states.clientSeed.value}
          serverSeed={states.serverSeed.value}
          nonce={Number(states.nonce.value)}
          onVerified={setResultUI}
        />
      )}

      {inhouseGameType === InhouseGameType.Limbo && (
        <LimboVerifyForm
          clientSeed={states.clientSeed.value}
          serverSeed={states.serverSeed.value}
          nonce={Number(states.nonce.value)}
          onVerified={setResultUI}
        />
      )}

      {inhouseGameType === InhouseGameType.Dice && (
        <DiceVerifyForm
          clientSeed={states.clientSeed.value}
          serverSeed={states.serverSeed.value}
          nonce={Number(states.nonce.value)}
          onVerified={setResultUI}
        />
      )}

      {inhouseGameType === InhouseGameType.Wheel && (
        <WheelVerifyForm
          clientSeed={states.clientSeed.value}
          serverSeed={states.serverSeed.value}
          nonce={Number(states.nonce.value)}
          onVerified={setResultUI}
        />
      )}

      {inhouseGameType === InhouseGameType.Mine && (
        <MineVerifyForm
          initialMineBet={initialBet as MineProbilityFairBetInfo}
          clientSeed={states.clientSeed.value}
          serverSeed={states.serverSeed.value}
          nonce={Number(states.nonce.value)}
          onVerified={setResultUI}
        />
      )}
    </Column>
  )
}

const StyledBoxWarning = styled(ColumnCenter)`
  justify-content: center;

  background: ${({ theme }) => theme.colors.backgroundAlt9};

  min-height: 200px;

  padding: 12px;
  border: 2px dotted ${({ theme }) => theme.colors.textSubtle};
  border-radius: ${({ theme }) => theme.radii.small};
  overflow: hidden;
`
export default VerifyForm
