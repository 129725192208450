import Box from 'UIKit/Box/Box'
import Button from 'UIKit/Button'
import FormControl from 'UIKit/FormControl'
import FormInput from 'UIKit/FormControl/FormInput'
import CircleLoader from 'UIKit/Loader/CircleLoader'
import Dots from 'UIKit/Loader/Dots'
import { ModalProps } from 'UIKit/Modal/Modal'
import Text from 'UIKit/Text'
import FormValidator from 'config/constants/formValidator'
import { ValidationError } from 'config/types/validator'
import { useCountDown } from 'hooks/useCountdown'
import useForm from 'hooks/useForm'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import { useRequest } from 'hooks/useRequest'
import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { HunnyRequest, VerificationResponse } from 'services/types'
import { useUserSettings } from 'state/profile/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { useAuthBy } from 'state/auth/hooks'
import ForgotPasswordModal from './ForgotPasswordModal'

export interface EmailVerificationModalProps {
  email: string
  initialVerifiedResult: VerificationResponse
  verifiedHunnyRequestFn: () => HunnyRequest<VerificationResponse>
  onSubmit: (verification: VerificationResponse, code: string, totpAuthCode?: string) => Promise<any>
  isUse2FA?: boolean
}

const EmailVerificationModal: React.FC<ModalProps<EmailVerificationModalProps>> = ({
  onDismiss,
  data: { email, initialVerifiedResult, verifiedHunnyRequestFn, onSubmit, isUse2FA = true },
}) => {
  const { t } = useTranslation()
  const { execute } = useRequest()
  const {
    userSettings: { isActive2FA },
  } = useUserSettings()

  const [submiting, setSubmiting] = useState(false)
  const [verification, setVerifiedResult] = useState(initialVerifiedResult)
  const [canResend, setCanResend] = useState(false)
  const [isExpireTimeEnd, setIsExpireTimeEnd] = useState(false)

  const [requestingResend, setRequestingResend] = useState(false)
  const { isAuthByTelegram } = useAuthBy()

  useIsomorphicEffect(() => {
    setCanResend(false)
    setRequestingResend(false)
  }, [verification])

  const resendCodeTextRef = useCountDown(
    verification?.nextResentTime,
    (s, m) => `${s + m * 60}`,
    () => {
      setCanResend(true)
    },
  )

  const expireTimeTextRef = useCountDown(
    verification?.expireTime,
    (s, m) => `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`,
    () => {
      setIsExpireTimeEnd(true)
    },
  )

  const { states, controls, validateAll, isValid, validate } = useForm({
    code: {
      validators: [FormValidator.required, FormValidator.requiredLength(6)],
      value: '',
    },
    totpCode: {
      validators: [FormValidator.required, FormValidator.requiredLength(6)],
      value: '',
    },
  })

  const handleResendCode = async () => {
    setRequestingResend(true)
    const verification = await execute(verifiedHunnyRequestFn())
    if (!verification) {
      // Show error
      return
    }

    setVerifiedResult(verification)
    setIsExpireTimeEnd(false)
  }

  const handleSubmit = async () => {
    setSubmiting(true)
    let isValid = false

    if (isActive2FA && isUse2FA) {
      isValid = await validateAll()
    } else {
      const otpErros = await validate('code')
      if (!otpErros.length) {
        isValid = true
      }
    }

    if (!isValid) {
      setSubmiting(false)
      return
    }

    const data = await onSubmit(verification, states.code.value, states.totpCode.value)
    if (data?.code === 'error_auth_input') {
      controls.code.onErrorChanged([ValidationError.InCorrect])
    }
    if (data?.code === 'error_auth_totp') {
      controls.totpCode.onErrorChanged([ValidationError.InCorrect2FA])
    }
    if (data?.code === 'error_data_expired') {
      controls.code.onErrorChanged([ValidationError.DataExpired])
    }

    if (data?.code === 'success') {
      onDismiss()
    }
    setSubmiting(false)
  }

  const verificationErrorMessages = useMemo(() => {
    return {
      [ValidationError.Required]: 'Please provide verification code',
      [ValidationError.IncorrectLength]: 'Please enter the 6-digits verification code',
      [ValidationError.InCorrect]: isAuthByTelegram
        ? 'Telegram Verification Code is incorrect, please check the code again'
        : 'Email Verification Code is incorrect, please check the code again',
      [ValidationError.InCorrect2FA]: 'Two-Factor Authentication Code is incorrect, please check the code again',
      [ValidationError.DataExpired]: 'Your OTP is expired. Please resend new OTP',
    }
  }, [isAuthByTelegram])

  return (
    <ForgotPasswordModal onDismiss={onDismiss} label={t('Verification')}>
      <StyledSubtleText fontWeight="300" lineHeight="20px" fontSize="14px">
        <Trans>Please enter the 6-digits verification code that was sent to</Trans>{' '}
        {isAuthByTelegram ? (
          <span>
            <strong>
              <Trans>your Telegram ID</Trans>: {email}
            </strong>
          </span>
        ) : (
          <span>
            <strong>{email}</strong>
          </span>
        )}
        . {t('The code is valid for 5 minutes')}
      </StyledSubtleText>

      <FormControl
        mt="24px"
        state={states.code}
        label={isAuthByTelegram ? t('Telegram Verification Code') : t('Email Verification Code')}
        formatErrorMessage={(e) => t(verificationErrorMessages[e[0]])}
      >
        <FormInput
          control={controls.code}
          placeholder={t('Enter Code')}
          type="text"
          name="verification_code"
          size={6}
          maxLength={6}
          icon={<Icons.PasswordIcon />}
          adornmentType="PasteFromClipboard"
        />
      </FormControl>

      {isActive2FA && isUse2FA && (
        <FormControl
          mt="24px"
          state={states.totpCode}
          label={t('Two-Factor Authentication Code')}
          formatErrorMessage={(e) => t(verificationErrorMessages[e[0]])}
        >
          <FormInput
            control={controls.totpCode}
            placeholder={t('Enter Code')}
            type="text"
            name="verification_code"
            size={6}
            maxLength={6}
            icon={<Icons.PasswordIcon />}
            adornmentType="PasteFromClipboard"
          />
        </FormControl>
      )}

      <Button minHeight="46px" mt="24px" disabled={!isValid || !states.code.value || submiting} onClick={handleSubmit}>
        {submiting && (
          <Box mr="12px">
            <CircleLoader />
          </Box>
        )}

        <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
          <Trans>Submit</Trans>
        </Text>
      </Button>

      <StyledSubtleText mt="24px" mb="20px" fontSize="14px" textAlign="center">
        {isAuthByTelegram ? (
          <Trans>Haven’t receives telegram verification code</Trans>
        ) : (
          <Trans>Haven’t receives email verification code</Trans>
        )}
        ?{' '}
        {canResend ? (
          <Button
            variant="text"
            p="0px !important"
            height="auto !important"
            onClick={handleResendCode}
            disabled={requestingResend}
          >
            {requestingResend ? (
              <Text fontSize="14px" color="textSubtle" bold>
                <Dots>
                  <Trans>Sending</Trans>
                </Dots>
              </Text>
            ) : (
              <Text fontSize="14px" color="primary" bold>
                <Trans>Resend now</Trans>
              </Text>
            )}
          </Button>
        ) : (
          <>
            <Trans>Resend in</Trans> <span ref={resendCodeTextRef}>...</span> <Trans>seconds</Trans>
          </>
        )}
      </StyledSubtleText>

      <Text fontSize="14px" color="textSubtle" textAlign="center" mb="16px">
        {!isExpireTimeEnd && (
          <>
            <Trans>Code will be expired in</Trans> <strong ref={expireTimeTextRef}>...</strong>
          </>
        )}
        {isExpireTimeEnd && <Trans>Code has been expired</Trans>}
      </Text>
    </ForgotPasswordModal>
  )
}

const StyledSubtleText = styled(Text).attrs({ color: 'textSubtle' })`
  strong {
    color: ${({ theme }) => theme.colors.text};
  }
`

export default EmailVerificationModal
