import Box from 'UIKit/Box/Box'
import Text from 'UIKit/Text'
import useDebounceCallback from 'hooks/useDebounceCallback'
import Column from 'layout/Components/Column'
import { RowCenter } from 'layout/Components/Row'
import { useEffect, useState } from 'react'
import PlinkoGameService from 'services/InhouseGameService/PlinkoGameService'
import { getPayouts } from 'views/InhouseGame/Plinko/components/GameBoard/components/PayoutGroup'
import { Risk, risks, rows } from 'views/InhouseGame/Plinko/components/GameBoard/config'
import RiskSelect from 'views/InhouseGame/components/Controls/RiskSelect'
import RowSelect from 'views/InhouseGame/components/Controls/RowSelect'
import { PlinkoProbilityFairBetInfo } from 'views/InhouseGame/config/types'

const PlinkoVerifyForm: React.FC<{
  clientSeed: string
  serverSeed: string
  nonce: number
  initialPlinkoBet?: PlinkoProbilityFairBetInfo
  onVerified: (resultText: any) => void
}> = ({ onVerified, clientSeed, serverSeed, nonce, initialPlinkoBet }) => {
  const [row, setRow] = useState<number>(initialPlinkoBet?.data.row || rows[rows.length - 1].value)
  const [risk, setRisk] = useState<Risk>(initialPlinkoBet?.data.risk || risks[2].value)

  const debounceCallback = useDebounceCallback()

  useEffect(() => {
    debounceCallback(async () => {
      if (risk && row && clientSeed && serverSeed && nonce >= 0) {
        const payoutMultiplier = await PlinkoGameService.verifyResult(risk, row, clientSeed, serverSeed, nonce)
        const payouts = getPayouts(row, risk)
        const selectedPayout = payouts.find((payout) => payout.multiplier === payoutMultiplier)

        onVerified(
          <Box
            height="36px"
            width="36px"
            borderRadius="2px"
            background={`rgb(${selectedPayout?.color.r}, ${selectedPayout?.color.g}, ${selectedPayout?.color.b})`}
            style={{
              boxShadow: `0 0.2em 0 0 rgba(${selectedPayout?.color.r}, ${selectedPayout?.color.g}, ${selectedPayout?.color.b}, 0.6)`,
            }}
          >
            <RowCenter height="100%">
              <Text fontSize="14px" textAlign="center" fontWeight={700} color="#000">
                {`${selectedPayout?.multiplier}x`}
              </Text>
            </RowCenter>
          </Box>,
        )
      } else onVerified(null)
    }, 300)
  }, [risk, row, clientSeed, serverSeed, nonce])

  return (
    <Column>
      <RiskSelect risk={risk} onSelect={setRisk} mb="16px" height="44px" />
      <RowSelect row={row} onSelect={setRow} mb="16px" dropdownPosition="top" height="44px" />
    </Column>
  )
}

export default PlinkoVerifyForm
