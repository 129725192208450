import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { FlexProps } from 'UIKit/Box/types'
import Button from 'UIKit/Button'
import Image from 'UIKit/Image'
import Text from 'UIKit/Text'
import { RouteConfig } from 'config/constants/route'
import { useCloseAllModal } from 'hooks/useModal'
import { useRouter } from 'hooks/useRouter'
import { RowCenter } from 'layout/Components/Row'
import { Trans } from 'react-i18next'

const TwoFactorWarning: React.FC<FlexProps> = ({ ...props }) => {
  const router = useRouter()
  const closeModal = useCloseAllModal()

  const handleRedirectToSettingSecurity = () => {
    router.push(RouteConfig.SettingSecurity)
    closeModal()
  }

  return (
    <Flex
      flexDirection={['row', 'row', 'column']}
      alignItems="center"
      px="12px"
      py={['0px', '', '12px']}
      justifyContent="space-between"
      background="rgba(35, 44, 61, 0.30)"
      {...props}
    >
      <RowCenter>
        <Box width={['40px', '', '25px']} minWidth={['40px', '', '25px']} height={['50px', '', '30px']}>
          <Image src="/images/security-thumbnail.png" width={80} height={50} />
        </Box>
        <Text fontSize="12px" fontWeight={400} letterSpacing="-0.24px" color="textSubtle" ml="8px" lineHeight="normal">
          <Trans>You currently do not have 2FA enabled</Trans>
        </Text>
      </RowCenter>
      <Button
        width={['85px', '', '100%']}
        height="31px"
        ml={['12px', '', '0px']}
        mt={['0px', '', '12px']}
        style={{ background: '#232C3D', borderRadius: '4px' }}
        onClick={handleRedirectToSettingSecurity}
        px="0px !important"
      >
        <Text color="textSubtle" fontSize="12px" fontWeight={600}>
          <Trans>Enable 2FA</Trans>
        </Text>
      </Button>
    </Flex>
  )
}

export default TwoFactorWarning
