import Flex from 'UIKit/Box/Flex'
import { BoxProps } from 'UIKit/Box/types'
import Text from 'UIKit/Text/Text'
import { useCountDown } from 'hooks/useCountdown'
import styled from 'styled-components'

interface ResumeProgressingDepositFiatCountdownProps {
  endDate: number
  onResumeEnd?: () => void
}

const ResumeProgressingDepositFiatCountdown: React.FC<ResumeProgressingDepositFiatCountdownProps & BoxProps> = ({
  endDate,
  onResumeEnd,
  ...props
}) => {
  const shudownTimeTextRef = useCountDown(
    endDate,
    (s, m, h, d) => {
      return `<div class='${d === 0 ? 'highlight' : ''}' >
      <div class='${h === 0 ? 'container' : ''}' > <div class='${m === 0 ? 'highlight' : ''}'>${m
        .toString()
        .padStart(2, '0')}</div> <span>:</span> <div>${s.toString().padStart(2, '0')}</div>`
    },
    onResumeEnd || (() => {}),
  )

  return (
    <StyledTimeoutBonus {...props}>
      <StyledCountdownContent ref={shudownTimeTextRef} />
    </StyledTimeoutBonus>
  )
}

const StyledTimeoutBonus = styled(Flex)`
  height: fit-content;
  max-width: 292px;
  border-radius: 8px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${Text} {
    color: ${({ theme: { colors } }) => colors.textTertiary};

    ${({ theme: { mediaQueries } }) => mediaQueries.md} {
      color: ${({ theme: { colors } }) => colors.textAlt1};
    }
  }
`

const StyledCountdownContent = styled(Flex)`
  color: ${({ theme: { colors } }) => colors.text};
  font-size: 14px;
  font-weight: 600;
  line-height: normal;

  .container {
    display: flex;
    align-items: center;
  }

  div {
    text-align: center;
  }

  span {
    width: 2px;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.text};
    margin: 0 4px;
  }
`

export default ResumeProgressingDepositFiatCountdown
