import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { FlexProps } from 'UIKit/Box/types'
import OpenEffect from 'UIKit/OpenEffect'
import Text from 'UIKit/Text'
import TokenLogo from 'UIKit/TokenLogo'
import { howlMapper } from 'config/constants/audio'
import { InhouseGameDetails } from 'config/types/game'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import { useCallback, useEffect, useMemo } from 'react'
import MineGameService from 'services/InhouseGameService/MineGameService'
import theme from 'theme'
import { colors } from 'theme/colors'
import { range } from 'utils'
import { MINE_DEFAULT_BLOCK, MINE_GAME_SIZE } from 'views/InhouseGame/Mine/config'
import { MineBet } from 'views/InhouseGame/Mine/config/types'
import { useMineAction } from 'views/InhouseGame/Mine/hooks'
import { delineate } from 'utils/formatBalance'
import MineGameBlock from './MineGameBlock'

const mineIds = range(0, MINE_GAME_SIZE * MINE_GAME_SIZE)

const MineGameGraphic: React.FC<
  FlexProps & {
    game?: InhouseGameDetails
    autoMode?: boolean
    disabled?: boolean
    setSelectedBlocks?: (ids: number[]) => void
    selectedBlocks?: number[]
    mines: number
    volumeEnable?: boolean
    currentBet: MineBet
    viewMode?: boolean
    hideMultiplierResult?: boolean
  }
> = ({
  game,
  currentBet,
  volumeEnable,
  autoMode,
  disabled,
  mines,
  viewMode,
  selectedBlocks = [],
  setSelectedBlocks,
  hideMultiplierResult,
  ...props
}) => {
  const { finishBet } = useMineAction()

  useIsomorphicEffect(() => {
    if (!autoMode || !currentBet || viewMode || !game) return
    const run = async () => {
      await MineGameService.openBlock(selectedBlocks, game.code, currentBet)
      finishBet()
    }

    run()
  }, [currentBet?.id, autoMode])

  const openBlock = useCallback(
    (id: number) => {
      if (!game) return
      return MineGameService.openBlock([id], game.code, currentBet)
    },
    [game, currentBet],
  )

  const onSelect = useCallback(
    (value) => {
      if (!setSelectedBlocks) return

      if (selectedBlocks.includes(value)) {
        setSelectedBlocks(selectedBlocks.filter((item) => item != value))
      } else if (selectedBlocks.length < MINE_GAME_SIZE * MINE_GAME_SIZE - mines) {
        setSelectedBlocks([...selectedBlocks, value])
      }

      if (volumeEnable) {
        howlMapper.MineBlockSelect.play()
      }
    },
    [setSelectedBlocks, selectedBlocks, mines, volumeEnable],
  )

  const isFinishAndWin = useMemo(() => {
    return currentBet && currentBet.isCompleted && currentBet.multiplier > 0
  }, [currentBet])

  useEffect(() => {
    if (isFinishAndWin && !viewMode) {
      howlMapper.MineWin.play()
    }
  }, [volumeEnable, isFinishAndWin, viewMode])

  return (
    <Flex
      alignItems="center"
      position="relative"
      justifyContent="center"
      height={['auto', '', '', '', '100%']}
      width={['100%', '', '', '', 'auto']}
      maxWidth={['500px', '', '', '', '100%']}
      style={{ aspectRatio: '1/1' }}
      overflow="hidden"
      borderRadius={theme.radii.small}
      flexWrap="wrap"
      {...props}
    >
      {isFinishAndWin && !hideMultiplierResult && (
        <OpenEffect
          openType="fade"
          padding="24px 32px"
          position="absolute"
          left="50%"
          top="50%"
          style={{ transform: 'translate(-50%, -50%)' }}
          background={colors.inputBackground}
          border={`2px solid ${colors.primary}`}
          borderRadius="10px"
          zIndex={2}
        >
          <Text fontSize="26px" fontWeight="700" letterSpacing="2px" color="primary" textAlign="center" width="100%">
            {delineate(currentBet.multiplier.toString(), 3)}x
          </Text>
          <Flex justifyContent="center">
            <Box height="3px" width="50px" background={colors.textDisable} borderRadius="10px" my="12px" />
          </Flex>
          <Flex justifyContent="center" alignItems="center">
            <Text small bold color="primary" textAlign="center" mr="4px">
              {delineate(currentBet.betAmount.amount.multipliedBy(currentBet.multiplier).toString(10), 2)}
            </Text>
            <TokenLogo
              token={currentBet.betAmount.token}
              size={13}
              minWidth={13}
              networkImageProps={{
                width: 10,
                height: 10,
              }}
            />
          </Flex>
        </OpenEffect>
      )}

      {mineIds.map((id) => (
        <MineGameBlock
          viewMode={viewMode}
          disabled={disabled}
          key={`${id}`}
          blockId={id}
          isSelected={selectedBlocks.includes(id)}
          toggleSelection={onSelect}
          block={currentBet ? currentBet.result[id] : MINE_DEFAULT_BLOCK}
          openBlock={openBlock}
          autoMode={autoMode}
          volumeEnable={volumeEnable}
        />
      ))}
    </Flex>
  )
}

export default MineGameGraphic
