import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  LTC: {
    coinGeckoInfo: {
      id: 'litecoin',
    },
    logo: `${path}/ltc.png`,
    name: 'LTC',
    code: 'LTC',
    network: ChainIdEnum.LTC_TESTNET,
    isNative: true,
    decimals: 9,
  },
}

export default tokens
