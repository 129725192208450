import Modal, { ModalProps } from 'UIKit/Modal/Modal'
import React from 'react'
import styled from 'styled-components'
import ModalBody from 'UIKit/Modal/components/ModalBody'
import { useTranslation } from 'react-i18next'
import Image from 'UIKit/Image'
import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Text from 'UIKit/Text'
import { BoxProps } from 'UIKit/Box/types'

const ConfirmationLayoutModal: React.FC<ModalProps & BoxProps & { title?: string }> = ({
  onDismiss,
  children,
  title = 'Confirmation',
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <StyledModal onDismiss={onDismiss} {...props}>
      <StyledModalBody borderRadius="0px" height="100%">
        <Flex flexDirection="column" alignItems="center" overflow="hidden">
          <Box width="120px" height="120px">
            <Image src="/images/confirmation.png" width={300} height={300} />
          </Box>
          <Text mt="20px" fontWeight="700" fontSize="20px">
            {t(title)}
          </Text>
        </Flex>
        {children}
      </StyledModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  padding: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 428px !important;
  }
`

const StyledModalBody = styled(ModalBody)`
  overflow-y: unset !important;
`

export default ConfirmationLayoutModal
