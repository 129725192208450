import { BoxProps } from 'UIKit/Box/types'
import Text from 'UIKit/Text'
import Column from 'layout/Components/Column'
import { RowMiddle } from 'layout/Components/Row'
import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { InHouseLabel, WrapperFilter } from 'views/InhouseGame/components/styled'
import { Position } from 'UIKit/Popper/typpes'
import { rows } from '../../Plinko/components/GameBoard/config'

const RowSelect: React.FC<
  {
    row: number
    onSelect: (row: number) => void
    disabled?: boolean
    dropdownPosition?: Position
  } & Omit<BoxProps, 'onSelect'>
> = ({ row, onSelect, disabled, height, dropdownPosition, ...props }) => {
  const selected = useMemo(() => rows.find((r) => r.value === row), [row])

  const options = useMemo(() => (selected ? rows.filter((item) => item !== selected) : rows), [rows, selected])

  return (
    <Column {...props}>
      <InHouseLabel>
        <Trans>Rows</Trans>
      </InHouseLabel>
      <WrapperFilter
        disabled={disabled}
        options={options}
        onSelect={onSelect}
        OptionItemComponent={FilterOptionItem}
        height={height}
        dropdownPosition={dropdownPosition}
      >
        {selected && <FilterOptionItem item={selected} />}
      </WrapperFilter>
    </Column>
  )
}

const FilterOptionItem = ({ item }) => {
  return (
    <RowMiddle>
      <Text fontSize="1em">{item.display}</Text>
    </RowMiddle>
  )
}

export default RowSelect
