import BigNumber from 'bignumber.js'
import Box from 'UIKit/Box/Box'
import { StyledControlContainer } from 'UIKit/FormControl/styled'
import { InputLabel } from 'UIKit/Input/styled'
import NetworkSelect from 'components/NetworkSelect'
import TokenSelect from 'UIKit/TokenSelect'
import { ChainIdEnum, SYSTEM_NETWORK_MAP } from 'config/constants/network'
import { Network, Token } from 'config/types'
import { useCallback, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useAppSelector } from 'state'
import { getBalanceTokenKey } from 'utils'

interface SelectReceiveTokenProps {
  value: Token
  onValueChanged: (token: Token) => void
  tokens: Token[]
  networkSelectTitle?: string
  tokenSelectTitle?: string
}

const SelectReceiveToken: React.FC<SelectReceiveTokenProps> = ({
  value,
  tokens,
  onValueChanged,
  networkSelectTitle,
  tokenSelectTitle,
}) => {
  const networks = useMemo(
    () => tokens.map((token) => SYSTEM_NETWORK_MAP[token.network]).filter((v, i, a) => a.indexOf(v) === i),
    [tokens],
  )

  const tokenBalances = useAppSelector((state) => state.profile.balances)
  const [chainId, setChainId] = useState<ChainIdEnum>(value.network)
  const network = useMemo(() => SYSTEM_NETWORK_MAP[chainId], [chainId])

  const handleSelectNetwork = (network: Network) => {
    setChainId(network.chainId)
    onValueChanged(tokens.find((token) => token.network === network.chainId))
  }

  const fetchTokenBalanceFn = useCallback(
    async (token: Token) => {
      return Promise.resolve(new BigNumber(tokenBalances[getBalanceTokenKey(token.network, token.code)] || 0))
    },
    [tokenBalances],
  )

  return (
    <Box>
      <StyledControlContainer state={null}>
        <InputLabel>
          <Trans>{networkSelectTitle || 'Select Network'}</Trans>
        </InputLabel>
        <NetworkSelect
          options={networks}
          value={network}
          onNetworkChange={handleSelectNetwork}
          dropdownContentProps={{
            maxHeight: '200px !important',
          }}
        />
      </StyledControlContainer>

      <StyledControlContainer state={null} mt="16px">
        <InputLabel>
          <Trans>{tokenSelectTitle || 'Select Coin'}</Trans>
        </InputLabel>
        <TokenSelect
          value={value}
          onTokenChange={onValueChanged}
          fetchTokenBalanceFn={fetchTokenBalanceFn}
          options={tokens.filter((token) => token.network === network.chainId)}
          dropdownContentProps={{
            maxHeight: '200px !important',
          }}
        />
      </StyledControlContainer>
    </Box>
  )
}

export default SelectReceiveToken
