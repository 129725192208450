import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import TableCell from 'UIKit/Table/TableCell'
import TableRow from 'UIKit/Table/TableRow'
import Text from 'UIKit/Text'
import { TimeSince } from 'UIKit/TimeSince'
import TokenLogo from 'UIKit/TokenLogo'
import { TokenAmount } from 'config/types'
import { RefUserProfile } from 'config/types/profile'
import { RowCenter } from 'layout/Components/Row'
import { Trans } from 'react-i18next'
import { Icons } from 'svgs'
import theme from 'theme'
import { formatDisplayDateTime } from 'utils/dateHelper'
import UserDisplay from 'views/Profile/components/UserDisplay'
import { parseToLockedHusd } from '../utils'

export interface RowProps {
  createTime: number
  prize: TokenAmount
  user: RefUserProfile
}

const PrizeWinnerRow: React.FC<RowProps> = ({ prize, createTime, user }) => {
  return (
    <TableRow>
      <TableCell
        height="52px"
        borderLeft="none !important"
        borderRight="none !important"
        borderBottom={`1px solid ${theme.colors.stroke}`}
      >
        <TimeSince fontSize={['12px', '', '14px']} date={new Date(createTime)} formatDisplay={formatDisplayDateTime} />
      </TableCell>

      <TableCell
        height="52px"
        borderLeft="none !important"
        borderRight="none !important"
        borderBottom={`1px solid ${theme.colors.stroke}`}
      >
        {user.displayName ? (
          <UserDisplay user={user} size={22.5} fontSize={['12px', '', '14px']} />
        ) : (
          <RowCenter>
            <Box width={22.5} height={22.5} mr="4px">
              <Icons.HiddenAvatar width={24} height={24} />
            </Box>
            <Text fontSize={['12px', '', '14px']} color="textAlt1" fontWeight={500}>
              <Trans>Hidden</Trans>
            </Text>
          </RowCenter>
        )}
      </TableCell>

      <TableCell
        height="52px"
        borderLeft="none !important"
        borderRight="none !important"
        borderBottom={`1px solid ${theme.colors.stroke}`}
      >
        <Flex alignItems="center">
          <TokenLogo token={parseToLockedHusd(prize.token)} width="24px" height="24px" />
          <Text fontSize={['12px', '', '14px']} ml="10px" bold>
            {prize.amount.toString()}
          </Text>
        </Flex>
      </TableCell>
    </TableRow>
  )
}

export default PrizeWinnerRow
