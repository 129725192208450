import { Trans } from 'react-i18next'
import Box from 'UIKit/Box/Box'
import Text from 'UIKit/Text'
import { colors } from 'theme/colors'
import Button from 'UIKit/Button'
import { Icons } from 'svgs'
import Image from 'UIKit/Image'
import useModal from 'hooks/useModal'
import AboutHUSDModal from 'components/AboutHUSDModal'

const HUSDInfo = () => {
  const [handlePresentHUSDInfo] = useModal(AboutHUSDModal)
  return (
    <Box borderRadius="20px" border={`1px solid ${colors.stroke}`} padding="20px" mt="24px" position="relative">
      <Text bold fontSize="24px" color="textSubtle" textAlign="center">
        1 HUSD = 1 USD ($)
      </Text>

      <Text small fontWeight="300" color="textSubtle" mt="20px" lineHeight="16px">
        <Trans>HUSD(HunnyPlay Dollar) is a crypto launched by HunnyPlay. You can play game with HUSD.</Trans>
      </Text>
      <Text small fontWeight="300" color="textSubtle" mt="20px" lineHeight="16px">
        <b>1 HUSD = 1 USD</b>{' '}
        <Trans>
          You can Swap function to swap HUSD into any other stable coins at any time and withdraw it to your wallet.
        </Trans>{' '}
      </Text>

      <Text small fontWeight="300" color="textSubtle" mt="20px">
        <Trans>Deposit to claim your HUSD bonus now</Trans>.
      </Text>

      <Button variant="text" p="0px !important" height="auto" mt="48px" onClick={handlePresentHUSDInfo}>
        <Text fontSize="14px" color="primary" mr="8px">
          <Trans>More about HUSD</Trans>
        </Text>
        <Icons.ChevronRightIcon width="16px" height="16px" fill={colors.primary} />
      </Button>

      <Box position="absolute" bottom="-12px" right="-12px">
        <Image width={100} height={100} src="/images/dollar-coin.png" alt="dollar coin" />
      </Box>
    </Box>
  )
}

export default HUSDInfo
