import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Text from 'UIKit/Text'
import styled from 'styled-components'
import theme from 'theme'
import StyledProgress, { ProgressBar } from './StyledProgress'
import { ProgressProps, scales, variants } from './types'

const stepGuard = (step: number) => {
  if (step < 0) return 0
  if (step > 100) return 100

  return step
}

const Progress: React.FC<React.PropsWithChildren<ProgressProps>> = ({
  variant = variants.ROUND,
  scale = scales.MD,
  primaryStep = 0,
  secondaryStep = null,
  showProgress,
  showPointer,
  pointerStyles,
  barStyles,
  children,
  ...props
}) => {
  return (
    <Flex alignItems="center" justifyContent="center" width="100%">
      <StyledProgress variant={variant} scale={scale} {...props}>
        {children || (
          <>
            {showPointer && (
              <ProgressContainer left={`${stepGuard(primaryStep)}%`}>
                <Box width={15} height={15} background={theme.colors.primary} {...pointerStyles} />
              </ProgressContainer>
            )}
            <ProgressBar width={`${stepGuard(primaryStep)}%`} {...barStyles} />
            {secondaryStep ? (
              <ProgressBar width={`${stepGuard(secondaryStep)}%`} background={theme.colors.textSubtle} />
            ) : null}
          </>
        )}
      </StyledProgress>
      {showProgress && (
        <Box ml={2}>
          <Text color="primary">{primaryStep}%</Text>
        </Box>
      )}
    </Flex>
  )
}

const ProgressContainer = styled(Flex)`
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: left 200ms ease-out;
`

export default Progress
