import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { BoxProps } from 'UIKit/Box/types'
import Text from 'UIKit/Text'
import { howlMapper } from 'config/constants/audio'
import { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import theme from 'theme'
import { colors } from 'theme/colors'
import { DiceGameTargetType, diceTargetSlideMaxValue, diceTargetSlideMinValue } from 'views/InhouseGame/Dice/config'
import { DiceBet } from 'views/InhouseGame/Dice/config/types'

const DiceTargetAdjuster: React.FC<
  {
    value: string
    setValue?: (value: string) => void
    targetType: DiceGameTargetType
    volumeEnable?: boolean
    betResult?: DiceBet
    disabledAnimation?: boolean
  } & BoxProps
> = ({ value, setValue, betResult, targetType, volumeEnable, disabledAnimation, ...props }) => {
  const [delayedResult, setDelayedResult] = useState('')
  const [isWinbet, setIsWinbet] = useState(false)

  useEffect(() => {
    if (!betResult) return
    if (!disabledAnimation) {
      const timeout = setTimeout(() => {
        setDelayedResult(betResult.result.toFixed(2))
        setIsWinbet(
          betResult &&
            (betResult.target.type === DiceGameTargetType.Over
              ? betResult.result >= betResult.target.value
              : betResult.result <= betResult.target.value),
        )
      }, 500)

      return () => clearTimeout(timeout)
    }
    setDelayedResult(betResult.result.toFixed(2))
    setIsWinbet(
      betResult &&
        (betResult.target.type === DiceGameTargetType.Over
          ? betResult.result > betResult.target.value
          : betResult.result < betResult.target.value),
    )
  }, [betResult, disabledAnimation])

  const parsedValue = useMemo(() => {
    const valueInNumber = Number(value)
    return valueInNumber < diceTargetSlideMinValue
      ? diceTargetSlideMinValue
      : valueInNumber > diceTargetSlideMaxValue
      ? diceTargetSlideMaxValue
      : valueInNumber
  }, [value])

  const handleChange = (event) => {
    if (setValue) {
      if (volumeEnable) howlMapper.DiceTick.play()
      setValue(event.target.value)
    }
  }

  return (
    <Box width="100%" {...props}>
      <Box padding="0px 32px">
        <Box position="relative" width="100%">
          <Box
            position="absolute"
            bottom="-28px"
            left={`${betResult ? betResult.result : 0}%`}
            style={{
              transform: 'translateX(-50%)',
              opacity: betResult ? '1' : 0,
              pointerEvents: betResult ? 'auto' : 'none',
              transition: disabledAnimation ? 'none' : '0.5s',
            }}
            zIndex={1}
          >
            <Box position="relative">
              {betResult && (
                <Box position="absolute" left="50%" top="50%" style={{ transform: 'translate(-50%, -50%)' }}>
                  <Text small color={isWinbet ? 'success' : 'error'} bold>
                    {delayedResult}
                  </Text>
                </Box>
              )}

              <img src="/images/cube.svg" width="64px" />
            </Box>
          </Box>
          <Box position="absolute" bottom="0" left="0" style={{ transform: 'translateX(-50%)' }}>
            <StyledSlidePoint small bold>
              0
            </StyledSlidePoint>
          </Box>
          <Box position="absolute" bottom="0" left="25%" style={{ transform: 'translateX(-50%)' }}>
            <StyledSlidePoint small bold>
              25
            </StyledSlidePoint>
          </Box>
          <Box position="absolute" bottom="0" left="50%" style={{ transform: 'translateX(-50%)' }}>
            <StyledSlidePoint small bold>
              50
            </StyledSlidePoint>
          </Box>
          <Box position="absolute" bottom="0" left="75%" style={{ transform: 'translateX(-50%)' }}>
            <StyledSlidePoint small bold>
              75
            </StyledSlidePoint>
          </Box>
          <Box position="absolute" bottom="0" left="100%" style={{ transform: 'translateX(-50%)' }}>
            <StyledSlidePoint small bold>
              100
            </StyledSlidePoint>
          </Box>
        </Box>
      </Box>
      <Box padding="14px" borderRadius="100px" background={colors.backgroundAlt8}>
        <Box padding="10px" borderRadius={theme.radii.large} background={colors.backgroundAlt}>
          <Flex position="relative" alignItems="center" width="100%" height="8px">
            <Flex
              position="absolute"
              height="8px"
              left="0"
              background={targetType === DiceGameTargetType.Over ? colors.success : colors.error}
              width="100%"
              zIndex={0}
              borderRadius={theme.radii.large}
              overflow="hidden"
            >
              <Box
                height="100%"
                width={`${parsedValue}%`}
                background={targetType === DiceGameTargetType.Under ? colors.success : colors.error}
              />
            </Flex>
            <StyledSlide
              type="range"
              height="8px !important"
              min="2"
              max="98"
              className="slider"
              onChange={handleChange}
              value={parsedValue}
              $disabled={!setValue}
            />
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

const StyledSlidePoint = styled(Text)`
  position: relative;
  padding-bottom: 12px;
  text-align: center;
  &::after {
    content: '';
    left: 50%;
    bottom: 0;
    position: absolute;
    transform: translate(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid ${({ theme }) => theme.colors.backgroundAlt8};
  }
`

const StyledSlide = styled.input<{ $disabled?: boolean }>`
  z-index: 1;
  margin: 0px !important;
  appearance: none;
  width: 100%;
  background: 'none' !important;
  outline: none;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 32px;
    height: 32px;
    background: ${({ theme }) => theme.colors.textTertiary};
    border-radius: 4px;
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'grab')};
    ${({ $disabled }) =>
      !$disabled &&
      css`
        &:active {
          cursor: grabbing;
          cursor: -moz-grabbing;
          cursor: -webkit-grabbing;
        }
      `};
  }

  &::-moz-range-thumb {
    width: 32px;
    height: 32px;
    background: ${({ theme }) => theme.colors.textTertiary};
    border-radius: 4px;
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'grab')};
    ${({ $disabled }) =>
      !$disabled &&
      css`
        &:active {
          cursor: grabbing;
          cursor: -moz-grabbing;
          cursor: -webkit-grabbing;
        }
      `};
  }
`
export default DiceTargetAdjuster
