import Skeleton from 'UIKit/Skeleton'
import Text from 'UIKit/Text'
import { TextProps } from 'UIKit/Text/types'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import BonusService from 'services/BonusService'
import { useActiveHUSDBoosterBonus } from 'state/bonus/hooks'
import { delineate } from 'utils/formatBalance'

const UnlockFormulaDisplay: React.FC<TextProps> = ({ ...props }) => {
  const { execute, cancelAllRequest } = useRequest()
  const [formulaValue, setFormulaValue] = useState(null)

  const husdBoosterBonus = useActiveHUSDBoosterBonus()

  useEffect(() => {
    const fetch = async () => {
      const formulaResponse = await execute(BonusService.getUnlockFormula())

      if (formulaResponse?.data) {
        setFormulaValue(formulaResponse?.data)
      }
    }

    fetch()
    return () => {
      cancelAllRequest()
    }
  }, [])

  if (!formulaValue) return <Skeleton minHeight="12px !important" width="200px" margin="auto" />

  return (
    <Text fontSize="12px" color="textSubtle" textAlign="center" {...props}>
      <Trans>Unlock</Trans> = <Trans>Wager</Trans> * {delineate(String(formulaValue.houseEdge * 100), 2)}% *{' '}
      {formulaValue.const * 100}%{' '}
      {husdBoosterBonus && (
        <Text display="inline-block" fontSize="12px" color="success" bold>
          * {husdBoosterBonus.extraUnlockPercent}%
        </Text>
      )}
    </Text>
  )
}

export default UnlockFormulaDisplay
