import BigNumber from 'bignumber.js'
import Flex from 'UIKit/Box/Flex'
import { BoxProps } from 'UIKit/Box/types'
import Image from 'UIKit/Image'
import { InputContainer } from 'UIKit/Input/styled'
import Select from 'UIKit/Select'
import Text from 'UIKit/Text'
import { Token } from 'config/types'
import useDebounceCallback from 'hooks/useDebounceCallback'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import { useCallback, useRef, useState } from 'react'
import { useTokenUsdPrices } from 'state/app/hooks'
import styled from 'styled-components'
import { forkjoinRequest } from 'utils/requestHelper'
import { filterTokenAmountSearch } from 'utils/token'
import TokenSelectItem, { TokenSelectItemProps } from './TokenSelectItem'

interface TokenSelectProps {
  options: Token[]
  fetchTokenBalanceFn?: (token: Token) => Promise<BigNumber>
  onTokenChange: (token: Token) => void
  value: Token
  disabled?: boolean
  dropdownContentProps?: BoxProps
}

const TokenSelect: React.FC<TokenSelectProps> = ({
  options,
  onTokenChange,
  fetchTokenBalanceFn,
  value,
  disabled,
  dropdownContentProps,
}) => {
  const [parsedOptions, setParsedOptions] = useState<TokenSelectItemProps[]>([])
  const isFetchedBalanceRef = useRef(null)
  const debounce = useDebounceCallback()
  const prices = useTokenUsdPrices()
  useIsomorphicEffect(() => {
    const parsedOptions = options.map((token) => ({
      token,
      amountRequest: null,
    }))

    setParsedOptions(parsedOptions)
  }, [options])

  const fetchBalance = useCallback(async () => {
    if (!fetchTokenBalanceFn) return

    debounce(() => {
      isFetchedBalanceRef.current = false
    }, 2000)
    if (isFetchedBalanceRef.current) return

    isFetchedBalanceRef.current = true
    const parsedOptions = options.map((token) => ({
      token,
      amountRequest: fetchTokenBalanceFn(token),
    }))
    setParsedOptions(parsedOptions)
    const amounts: BigNumber[] = await forkjoinRequest(parsedOptions.map((options) => options.amountRequest))
    const sortedOptions = parsedOptions
      .map((option, index) => ({ option, amount: amounts[index] }))
      .sort((option1, option2) =>
        option2.amount
          .times(prices[option2.option.token.code])
          .minus(option1.amount.times(prices[option1.option.token.code]))
          .toNumber(),
      )

    setParsedOptions(sortedOptions.map((option) => option.option))
  }, [options, fetchTokenBalanceFn])

  const handleSelectToken = (option: TokenSelectItemProps) => {
    onTokenChange(option.token)
  }

  return (
    <StyledSelectContainer width="100%" $disabled={disabled}>
      <Select
        disabled={disabled}
        options={parsedOptions}
        onSelect={handleSelectToken}
        OptionItemComponent={TokenSelectItem}
        dropdownContentProps={dropdownContentProps}
        onSelectOpen={(open) => {
          if (open) {
            fetchBalance()
          }
        }}
        onFilterSearch={filterTokenAmountSearch}
      >
        <StyledActiveToken id="token-selected">
          <Flex alignItems="center">
            <Image src={value.logo} width={24} height={24} />
            <Text fontSize="14px" fontWeight="bold" color="textSubtle" ml="10px">
              {value.name}
            </Text>
          </Flex>
        </StyledActiveToken>
      </Select>
    </StyledSelectContainer>
  )
}

const StyledSelectContainer = styled(InputContainer)`
  box-sizing: border-box;
  overflow: inherit;

  border-radius: ${({ theme }) => theme.radii.small};
  border: 1px solid ${({ theme }) => theme.colors.stroke};

  background-color: 'transparent';
`

const StyledActiveToken = styled(Flex)`
  padding: ${({ theme: { spacing } }) => `${spacing[1]}px`};

  align-items: center;
  justify-content: space-between;

  width: 100%;

  padding: 12px;
`

export default TokenSelect
