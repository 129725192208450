import { Adapter, WalletName } from '@solana/wallet-adapter-base'
import BigNumber from 'bignumber.js'
import { CountryEnum } from 'config/constants/country'
import { ChainIdEnum } from 'config/constants/network'
import React from 'react'

export type ProviderInfo = {
  code: string
  name: string
  logo: string
  icon: string
  slug: string
  desktopBanner: string
  mobileBanner: string
  blacklistCountry: CountryEnum[]
}

export type GameTag = {
  img: string
  content: string
  value: string
}

export type ScreenTag = {
  tag: string
  childTags: string[]
}

export interface DepositedTokenInfo extends RefToken {
  contractAddress?: string

  disabledDeposit?: boolean
  disabledWithdrawal?: boolean
  disabledPlay?: boolean
  disabledSwap?: boolean
}

export interface TimeDisplayValuesType {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export type Network = {
  code: string
  chainId: number
  rpcCollections?: string[]
  blockExplorerUrls?: string
  blockExplorerTxPath?: string
  blockExplorerName?: string
  blockExplorerTokenPath?: string
  order?: number
  networkInfo: {
    icon: string
    displayName: string
    shortName: string
    native: {
      name: string
      decimals: number
    }
  }
}

export interface SolWalletProvider {
  name: WalletName
  url: string
  adapter: Adapter
  extensionUrl?: string
  icon: () => string
}

export interface SolWalletGroup {
  [walletName: string]: SolWalletProvider
}

export interface MenuItem {
  icon?: string
  content: string
  route: string
  external?: boolean
  isNew?: boolean
  ExpandComponent?: React.FC
  id?: string
  onlySignedUser?: boolean
  subMenu?: MenuItem[]
  attributes?: {
    rel?: string
  }
}

export interface Token {
  coinGeckoInfo?: {
    id: string
  }
  name: string
  code: string
  address?: any
  isNative?: boolean
  isStable?: boolean
  decimals: number
  logo: string
  network?: ChainIdEnum
}

export interface TokenAmount {
  token: Token
  amount: BigNumber
}

export interface SerializeTokenAmount {
  token: Token
  amount: string
}

export interface RefToken {
  token: string
  network: ChainIdEnum
}

export interface DepositBonus {
  gteConditionAmount: number
  percent: number
}

export interface DepositBonusInfo {
  config: DepositBonus[][]
  userDepositTime: number
  endAt: number
}

export type TokenValidationResolve = {
  isBlackList: boolean
  isLogicLocked: boolean
  validate?: (token: Token) => boolean
}

export enum NetworkType {
  SOLANA,
  BTC,
  EVM,
  HPN,
  TRX,
  TON,
  XRP,
  LTC,
}

export enum PaymentMethod {
  TransferToken,
  CallContract,
}

export type AirdropBox = {
  code: string
  amount: string
}

export type ReceivedAirdropSummary = {
  totalReceivedAirdrop: number
  totalAmount: number
}

export type UnitNumberType = [signular: string, plural: string]
