import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import Image from 'UIKit/Image'
import Text from 'UIKit/Text'
import Column from 'layout/Components/Column'
import { RowMiddle } from 'layout/Components/Row'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import hunnyKeyframes from 'theme/keyframes'

const StyledWrapAnswerOption = styled(Column)<{ $answer?: number }>`
  flex: unset;
  align-items: center;

  border: 1px solid #adadad24;
  border-radius: ${({ theme }) => theme.radii.default};

  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0) 65.91%);
  backdrop-filter: blur(10px);
  padding: 8px 10px;

  position: relative;
  cursor: pointer;

  &:focus,
  &:active {
    animation: ${hunnyKeyframes.zoom} 2s;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-image: url('/images/survey/shadow-bg.png');
    background-size: cover;
    background-repeat: no-repeat;

    transform: translate(-50%, -50%);
  }

  flex-direction: row;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 8px 20px;
    height: 100%;
    justify-content: center;
    flex-direction: column;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    transition: 0.4s all;

    &:hover {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 10%, rgba(255, 255, 255, 0) 65.91%);
    }
  }
`

const StyledWrapperSurveyQuestion = styled(Box)`
  border-radius: 16px;
  background: linear-gradient(92deg, rgba(255, 255, 255, 0.26) -3.35%, rgba(255, 255, 255, 0.15) 129.77%);
  backdrop-filter: blur(41.29999923706055px);
  padding: 8px;
  width: max-content;

  color: ${({ theme }) => theme.colors.textAlt3};
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
`

export const QuestionTitle = styled(Text)`
  font-size: 20px;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: center;
`

export const QuestionDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.textSubtle};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  text-align: center;

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 14px;
  }
`

export const WrapperQuestion = styled(Column)`
  border: 1px solid ${({ theme }) => theme.colors.modalHeaderBackground};
  background: ${({ theme }) => theme.colors.cardBackground};
  padding: 12px;
  border-radius: ${({ theme }) => theme.radii.tiny};

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0;
    border-color: transparent;
    background: transparent;
  }
`
export const CountSurveyQuestion: React.FC<{ step: number; total: number }> = ({ total, step }) => {
  const { t } = useTranslation()

  return (
    <StyledWrapperSurveyQuestion>
      {t('Question {{step}}/{{total}}', {
        step,
        total,
      })}
    </StyledWrapperSurveyQuestion>
  )
}

export const AnswerOption: React.FC<BoxProps & { logo: string; text: string; index: number }> = ({
  logo,
  text,
  index,
  ...props
}) => {
  return (
    <StyledWrapAnswerOption $answer={index} {...props}>
      <Box
        my={['0', '', '13px']}
        maxWidth={[62, , 100]}
        maxHeight={[44, , 74]}
        minWidth={[62, , 100]}
        minHeight={[44, , 74]}
      >
        <Image src={logo} width={100} height={74} />
      </Box>
      <Text
        ml={['8px', '', '0']}
        fontSize={['14px', '', '', '16px']}
        lineHeight={1.4}
        small
        textAlign={['left', 'left', 'center']}
        fontWeight={500}
        color="textAlt3"
      >
        <Trans>{text}</Trans>
      </Text>
    </StyledWrapAnswerOption>
  )
}

const StyledWrapPlayerAnswerOption = styled(RowMiddle)<{ $answer?: number }>`
  border-radius: ${({ theme }) => theme.radii.small};
  border: 1px solid #adadad1f;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0) 65.91%);
  backdrop-filter: blur(10px);
  padding: 8px 20px 8px 8px;
  position: relative;
  cursor: pointer;
  animation: slide ${({ $answer = 1 }) => $answer * 0.2}s;
  transition: 0.3s all;

  @keyframes slide {
    0% {
      transform: translateY(14px);
      opacity: 0.7;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-image: url('/images/survey/shadow-bg.png');
    background-size: cover;
    background-repeat: no-repeat;

    transform: translate(-65%, -50%);
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 8px 20px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    &:hover {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 65.91%);
    }
  }
`

export const AnswerPlayerOption: React.FC<BoxProps & { logo: string; text: string; index: number }> = ({
  logo,
  text,
  index,
  ...props
}) => {
  return (
    <StyledWrapPlayerAnswerOption $answer={index} {...props}>
      <Box minWidth={62} minHeight={46}>
        <Image src={logo} width={62} height={46} />
      </Box>
      <Text fontWeight={500} ml="12px" fontSize={['14px', '', '', '16px']} lineHeight={1.4} color="textAlt3">
        <Trans>{text}</Trans>
      </Text>
    </StyledWrapPlayerAnswerOption>
  )
}
