import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { FlexProps } from 'UIKit/Box/types'
import useToggle from 'hooks/useToggle'
import { ColumnCenter } from 'layout/Components/Column'
import { RowBetween, RowCenter } from 'layout/Components/Row'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'

interface BonusDetailsStepCardProps extends PropsWithChildren {
  isActive: boolean
  isProcessing?: boolean
  isInvalid?: boolean
  disabledCollapse?: boolean
  cardTitle?: React.ReactNode
}

const BonusDetailsStepCard: React.FC<BonusDetailsStepCardProps & FlexProps> = ({
  isActive,
  isProcessing,
  isInvalid,
  cardTitle,
  disabledCollapse,
  children,
  ...props
}) => {
  const [isCollapsed, toggleCollapse] = useToggle(disabledCollapse ? false : isActive)

  return (
    <Flex justifyContent="space-between" {...props}>
      <ColumnCenter maxWidth="32px">
        <RowCenter width={32}>
          {isActive ? (
            <Icons.SuccessStatus strokeWidth="6px" width="32px" height="32px" />
          ) : isProcessing ? (
            <StyledInProgressIcon />
          ) : isInvalid ? (
            <Icons.CloseStrokeIcon fill={colors.error} />
          ) : (
            <StyledInactiveIcon />
          )}
        </RowCenter>
        <Box mt="8px" height="100%">
          <StyledLine
            width="1px"
            height="100%"
            $isActive={isActive}
            $inProgress={isProcessing}
            $isInvalid={isInvalid}
          />
        </Box>
      </ColumnCenter>
      <Flex
        flexDirection="column"
        width="100%"
        onClick={disabledCollapse ? () => {} : toggleCollapse}
        style={{ cursor: 'pointer' }}
      >
        <RowBetween flexGrow="0 !important">
          <Box width="100%">{cardTitle}</Box>
          {!disabledCollapse && (
            <StyledCollapseIconButton $isCollapsed={isCollapsed}>
              <Icons.ArrowDownIcon />
            </StyledCollapseIconButton>
          )}
        </RowBetween>
        <StyledContentWrapper width="100%" $isCollapsed={isCollapsed}>
          {children}
        </StyledContentWrapper>
      </Flex>
    </Flex>
  )
}

const StyledInactiveIcon = styled(Box)`
  width: 12px;
  height: 12px;

  border-radius: 12px;
  border: ${({ theme: { colors } }) => `1px solid ${colors.textDisable}`};
`

export const StyledInProgressIcon = styled(Box)`
  width: 32px;
  height: 32px;

  border-radius: 32px;
  border: ${({ theme: { colors } }) => `3px solid ${colors.warning}`};
`
const StyledCollapseIconButton = styled(Box)<{ $isCollapsed: boolean }>`
  transform: ${({ $isCollapsed }) => ($isCollapsed ? 'rotate(180deg)' : '')};
  transition: 0.3s;
  cursor: pointer;

  ${Icons.ArrowDownIcon} {
    path {
      fill: ${({ theme: { colors } }) => colors.textTertiary};
    }
  }
`

const StyledLine = styled(Box)<{ $isActive?: boolean; $inProgress?: boolean; $isInvalid?: boolean }>`
  height: 100%;
  width: 1px;
  background-image: ${({ theme, $isActive, $isInvalid, $inProgress }) =>
    $isActive
      ? `url("data:image/svg+xml,%3csvg width='2' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='2' height='100%25' fill='${theme.colors.success.replace(
          '#',
          '%23',
        )}'/%3e%3c/svg%3e")`
      : $inProgress
      ? `url("data:image/svg+xml,%3csvg width='2' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='2' height='100%25' fill='${theme.colors.warning.replace(
          '#',
          '%23',
        )}'/%3e%3c/svg%3e")`
      : $isInvalid
      ? `url("data:image/svg+xml,%3csvg width='2' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='2' height='100%25' fill='${theme.colors.error.replace(
          '#',
          '%23',
        )}'/%3e%3c/svg%3e")`
      : `url("data:image/svg+xml,%3csvg width='2' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='2' height='100%25' fill='none' stroke='${theme.colors.textDisable.replace(
          '#',
          '%23',
        )}'  stroke-dasharray='4 4'  stroke-linecap='round'/%3e%3c/svg%3e")`};
`

const StyledContentWrapper = styled(Box)<{ $isCollapsed: boolean }>`
  height: fit-content;
  max-height: ${({ $isCollapsed }) => ($isCollapsed ? '28px' : 'fit-content')};
  transition: 0.3s;
  overflow: hidden;

  * {
    opacity: ${({ $isCollapsed }) => ($isCollapsed ? '0' : '1')};
    transition: 0.3s;
  }
`
export default BonusDetailsStepCard
