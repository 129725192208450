import Box from 'UIKit/Box/Box'
import Modal, { ModalBody, ModalHeader, ModalProps } from 'UIKit/Modal'
import Tab from 'UIKit/Tab'
import Tabs, { StyledIndicator } from 'UIKit/Tabs'
import { useState } from 'react'
import { Trans } from 'react-i18next'
import { useAuth } from 'state/auth/hooks'
import styled from 'styled-components'
import MyWinTable from './MyWinTable'
import UserWinTable from './UserWinTable'

enum WinnerListTypeEnums {
  USER_WINS,
  MY_WINS,
}

const PrizeWinnerModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const [winnerListType, setWinnerListType] = useState<WinnerListTypeEnums>(WinnerListTypeEnums.USER_WINS)
  const { hasSession } = useAuth()

  return (
    <Modal onDismiss={onDismiss} height={715}>
      <ModalHeader>
        <Box width="100%" pl={30} pr={20} pt={26}>
          <Tabs
            value={winnerListType}
            indicator={<StyledIndicator width="70% !important" />}
            onTagChanged={setWinnerListType}
            width="fit-content !important"
          >
            <StyledTab
              value={WinnerListTypeEnums.USER_WINS}
              $isActive={winnerListType === WinnerListTypeEnums.USER_WINS}
              mb={3}
            >
              <Trans>Recent wins</Trans>
            </StyledTab>
            <StyledTab
              value={WinnerListTypeEnums.MY_WINS}
              $isActive={winnerListType === WinnerListTypeEnums.MY_WINS}
              mb={3}
              px={3}
              display={hasSession ? 'block' : 'none'}
            >
              <Trans>My wins</Trans>
            </StyledTab>
          </Tabs>
        </Box>
      </ModalHeader>

      <ModalBody position="relative" px={4} pt={2} pb={5} maxHeight={655} overflowY="auto">
        {winnerListType === WinnerListTypeEnums.USER_WINS && <UserWinTable />}
        {winnerListType === WinnerListTypeEnums.MY_WINS && hasSession && <MyWinTable />}
      </ModalBody>
    </Modal>
  )
}

const StyledTab = styled(Tab)<{ $isActive: boolean }>`
  font-size: 14px;
  font-weight: bold;

  color: ${({ $isActive, theme: { colors } }) => ($isActive ? colors.primary : colors.text)} !important;
`

export default PrizeWinnerModal
