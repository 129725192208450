import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Text from 'UIKit/Text'
import { TextProps } from 'UIKit/Text/types'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'

const GameInfoMessage: React.FC<TextProps & { message: string | ReactNode; variant: 'error' | 'info' }> = ({
  message,
  variant,
  ...props
}) => {
  return (
    <StyledWrapper {...props} alignItems="flex-start">
      <Box width="16px">
        {variant === 'error' && <Icons.CloseCircleIcon width="16px" fill={colors.error} />}
        {variant === 'info' && <Icons.InfoIcon width="16px" fill={colors.textSubtle} />}
      </Box>

      <Text ml="4px" fontSize="12px" lineHeight="normal" color="textSubtle" textAlign="left">
        {message}
      </Text>
    </StyledWrapper>
  )
}

const StyledWrapper = styled(Flex)`
  width: 100%;
  padding: 12px;
  border-radius: ${({ theme }) => theme.radii.small};
  background: rgba(255, 255, 255, 0.02);
  color: ${({ theme }) => theme.colors.error};
`

export default GameInfoMessage
