import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Button from 'UIKit/Button'
import Heading from 'UIKit/Heading'
import Image from 'UIKit/Image'
import Link from 'UIKit/Link'
import Modal, { ModalHeader, ModalProps } from 'UIKit/Modal'
import ModalBody from 'UIKit/Modal/components/ModalBody'

import Text from 'UIKit/Text'
import { RouteConfig } from 'config/constants/route'
import { ProfileTierInfo } from 'config/types/profile'
import { Trans, useTranslation } from 'react-i18next'
import { useActiveLevelUpBonus } from 'state/bonus/hooks'
import styled from 'styled-components'
import { revalidateHunnyLevel } from 'utils'

interface LevelUpProps {
  myTier: ProfileTierInfo
  bonusAmount: string
}

const LevelUp: React.FC<ModalProps<LevelUpProps>> = ({ onDismiss, data: { myTier, bonusAmount } }) => {
  const { t } = useTranslation()

  const displayMyTierLevel = `${revalidateHunnyLevel(myTier?.level || 0)}`

  const bonusUpgradeVIP = useActiveLevelUpBonus()

  return (
    <Modal onDismiss={onDismiss} minWidth={['100% !important', '420px !important']}>
      <ModalHeader>
        <Heading scale="xs" ml="12px">
          <Trans>Level Up</Trans>
        </Heading>
      </ModalHeader>

      <ModalBody>
        <Box position="relative" margin="auto">
          <Image src="/images/profile/levelup_background.png" width={250} height={200} />
          <StyledNextLevelTierLogo src={myTier?.logoUrl} width={130} height={150} />
        </Box>

        <Text fontWeight="bold" fontSize="18px" textAlign="center">
          {t('You become VIP {{levelProfile}}.', {
            levelProfile: displayMyTierLevel,
          })}
        </Text>
        <Flex alignItems="center" justifyContent="center" width="100%" mt="12px" mb="42px">
          <Text textAlign="center" mr="2.5px">
            <Trans>You received</Trans>
          </Text>
          <StyledBonusUsd>{bonusAmount} HUSD</StyledBonusUsd>
        </Flex>

        <Flex flexDirection="column" alignItems="center" justifyContent="center" width="100%" p="24px">
          <Link
            fontSize="14px"
            href={RouteConfig.RankingBonusTransactionHistory}
            onClick={() => {
              onDismiss()
            }}
          >
            <Trans>View transaction history</Trans>
          </Link>
          <Link
            mt="24px"
            href={RouteConfig.ProfileVipProgress}
            width="100%"
            onClick={() => {
              onDismiss()
            }}
          >
            <Button width="inherit">
              <Text fontSize="14px" fontWeight={600}>
                <Trans>View My Level</Trans>
              </Text>
            </Button>
          </Link>

          {bonusUpgradeVIP?.extraLevels && (
            <Text fontSize="12px" color="textSubtle" mt="16px">
              <Trans>The bonus VIP level is excluded for the ranking bonus reward.</Trans>
            </Text>
          )}
        </Flex>
      </ModalBody>
    </Modal>
  )
}

const StyledNextLevelTierLogo = styled(Image)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledBonusUsd = styled(Text)`
  color: ${({ theme: { colors } }) => colors.textHighlight};
  font-weight: 700;
  font-size: 18px;

  margin-left: 2.5px;

  text-shadow: ${({ theme: { colors } }) => `2px 2px 12px ${colors.textHighlight}`};
`

export default LevelUp
