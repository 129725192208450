import { BoxProps } from 'UIKit/Box/types'
import Button from 'UIKit/Button/Button'
import Text from 'UIKit/Text/Text'
import { WalletInfo } from 'config/types/wallet'
import Column from 'layout/Components/Column'
import { RowCenter } from 'layout/Components/Row'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Icons, getIcon } from 'svgs'
import getExternalLinkProps from 'utils/getExternalLinkProps'

const CardOptionBrowser: React.FC<{ wallet: WalletInfo } & BoxProps> = ({ wallet, ...props }) => {
  const { t } = useTranslation()

  return (
    <StyledCardWallet {...props}>
      <StyledWrapImage>
        <Icons.BrowserIcon width="40px" />
      </StyledWrapImage>
      <Column ml="16px">
        <Text small fontWeight={600}>
          {t('{{walletName}} for your Browser', {
            walletName: wallet.name,
          })}
        </Text>
        <Text mt="6px" small color="textSubtle" fontWeight={400} lineHeight={1.3}>
          <Trans>Access your wallet right from your favorite web browser.</Trans>
        </Text>
        <StyledButton as="a" href={wallet?.options?.downloadLink} {...getExternalLinkProps()}>
          <Trans>Add to Browser</Trans>
        </StyledButton>
      </Column>
    </StyledCardWallet>
  )
}

const CardOptionMobile: React.FC<{ wallet: WalletInfo; onInstall: () => void } & BoxProps> = ({
  wallet,
  onInstall,
  ...props
}) => {
  const Icon = getIcon(wallet.icon)
  const { t } = useTranslation()

  return (
    <StyledCardWallet {...props}>
      <StyledWrapImage>
        <Icon width="40px" />
      </StyledWrapImage>
      <Column ml="16px">
        <Text small fontWeight={600}>
          {t('{{walletName}} for Mobile', {
            walletName: wallet.name,
          })}
        </Text>
        <Text mt="6px" small color="textSubtle" fontWeight={400} lineHeight={1.3}>
          <Trans>Use the mobile wallet to explore the world of Ethereum</Trans>{' '}
        </Text>
        <StyledButton onClick={onInstall}>
          <Trans> Get the app</Trans>
        </StyledButton>
      </Column>
    </StyledCardWallet>
  )
}

const HowToGetWallet: React.FC<{ wallet: WalletInfo; onInstall: () => void }> = ({ wallet, onInstall }) => {
  return (
    <Column flex={1}>
      <Column justifyContent="center !important" alignItems="center !important" flex={1}>
        <CardOptionBrowser wallet={wallet} mb="20px" />
        <CardOptionMobile wallet={wallet} onInstall={onInstall} />
      </Column>
    </Column>
  )
}

const StyledCardWallet = styled(RowCenter)`
  align-items: flex-start;
  padding: 32px 24px;

  border-radius: ${({ theme }) => theme.radii.large};
  background: ${({ theme }) => theme.colors.backgroundAlt5};
`

const StyledWrapImage = styled(RowCenter)`
  background: #fff;
  border-radius: ${({ theme }) => theme.radii.default};

  min-width: 60px;
  height: 60px;
`

const StyledButton = styled(Button)`
  max-width: max-content;
  padding: 10px 16px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  height: 34px;

  margin-top: 12px;

  background: ${({ theme }) => theme.colors.primaryAlt};
  border-radius: ${({ theme }) => theme.radii.large};
`

export default HowToGetWallet
