import Flex from 'UIKit/Box/Flex'
import Image from 'UIKit/Image'
import Text from 'UIKit/Text'
import { ProfileStatisticGameDetailInfo } from 'config/types/profile'
import useOpenBetDetails from 'hooks/useOpenBetDetails'
import Column from 'layout/Components/Column'
import { RowBetween, RowCenter } from 'layout/Components/Row'
import { ReactElement } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import { formatDisplayDate } from 'utils/dateHelper'

interface BigRewardGameCardProps extends ProfileStatisticGameDetailInfo {
  logo: string
  title: string
  amountDisplay: string | ReactElement
}

const BigRewardGameCard: React.FC<BigRewardGameCardProps> = ({
  logo,
  title,
  gameName,
  amount,
  updateTime,
  betDetail,
  amountDisplay,
}) => {
  const { presentBetModal } = useOpenBetDetails()
  return (
    <StyledContainer
      p="12px"
      my="6px"
      mb="6px"
      onClick={() => {
        if (!betDetail || !betDetail.betId) return
        presentBetModal(betDetail.betId)
      }}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <RowCenter width="40px" height="40px" background="rgba(255, 255, 255, 0.04)" borderRadius="50%">
          <Image src={logo} width={20} height={20} />
        </RowCenter>

        <Column ml="8px">
          <Text fontSize="12px" fontWeight={400} color="textAlt1" lineHeight="normal" letterSpacing={-0.24}>
            <Trans>{title}</Trans>
          </Text>
          <Text fontSize="16px" fontWeight={700} mt="4px" color={amount ? 'text' : 'textAlt1'}>
            {amountDisplay}
          </Text>
        </Column>
      </Flex>

      <RowCenter>
        <Column alignItems="flex-end" mr="4px">
          <Text
            color="textTertiary"
            fontSize="12px"
            fontWeight={400}
            letterSpacing={-0.24}
            lineHeight="normal"
            textAlign="right"
          >
            {gameName}
          </Text>
          {updateTime && (
            <Text color="textAlt1" fontSize="12px" fontWeight={400} lineHeight="normal" letterSpacing={-0.24} mt="4px">
              {formatDisplayDate(new Date(updateTime))}
            </Text>
          )}
        </Column>

        {amount && <Icons.ChevronRightIcon fill={colors.textAlt1} width="10px" height="12px" />}
      </RowCenter>
    </StyledContainer>
  )
}

const StyledContainer = styled(RowBetween)`
  cursor: pointer;
`

export default BigRewardGameCard
