import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import Button from 'UIKit/Button/Button'
import { Game } from 'config/types/game'
import useModal from 'hooks/useModal'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import styled from 'styled-components'
import ShareGameModal from './ShareGameModal'

interface ShareGameButtonProps {
  game: Game
  size: string
}

const ShareGameButton: React.FC<ShareGameButtonProps & BoxProps> = ({ game, size, ...props }) => {
  const [handlePresentShareGameModal] = useModal(ShareGameModal)

  return (
    <Box {...props}>
      <StyledButton
        variant="text"
        width="fit-content!important"
        height="fit-content !important"
        px="0px !important"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          handlePresentShareGameModal({ gameSlug: game.slug })
        }}
      >
        <Icons.ShareArrowIcon fill={colors.textSubtle} width={size} height={size} />
      </StyledButton>
    </Box>
  )
}

const StyledButton = styled(Button)`
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.text};
    }
  }
`

export default ShareGameButton
