import Box from 'UIKit/Box/Box'
import Button from 'UIKit/Button'
import { InputProps } from 'UIKit/Input'
import InputAdornment from 'UIKit/Input/InputAdornment'
import RemoveValueInputAdornment from 'UIKit/Input/InputAdornment/RemoveValueInputAdornment'
import { InputContainer, StyledInput } from 'UIKit/Input/styled'
import Text from 'UIKit/Text'
import usePaste from 'hooks/usePaste'
import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

enum AdornmentType {
  ClearInput,
  PasteFromClipboard,
}

export interface FormInputProps {
  control: InputProps
  placeholder?: string
  type?: string
  name?: string
  adornment?: React.ReactElement
  icon?: React.ReactElement
  autoComplete?: string
  maxLength?: number
  size?: number
  min?: number
  max?: number
  pattern?: string
  tabIndex?: number
  adornmentType?: keyof typeof AdornmentType
}

export interface FormCheckboxProps {
  control: InputProps
}

const addAdornmentAction = (
  adornment: React.ReactElement,
  value: string,
  setValue: (value: string) => void,
  type: keyof typeof AdornmentType,
) => {
  const pasteContentFromClipboard = usePaste()
  const activeType = AdornmentType[type]

  return (
    <>
      {adornment}
      {activeType === AdornmentType.ClearInput && (
        <RemoveValueInputAdornment
          value={value}
          onRemove={() => {
            setValue('')
          }}
        />
      )}
      {activeType === AdornmentType.PasteFromClipboard && (
        <StyledPasteButton
          variant="text"
          onClick={() => pasteContentFromClipboard((value) => setValue(value))}
          type="button"
        >
          <Text fontSize="12px" color="warning" fontWeight={600} letterSpacing={-0.28} lineHeight="normal">
            <Trans>Paste</Trans>
          </Text>
        </StyledPasteButton>
      )}
    </>
  )
}

const FormInput: React.FC<FormInputProps> = ({ control, adornment, icon, adornmentType, ...props }) => {
  return (
    <InputContainer>
      <InputAdornment
        endAdornment={
          control.disabled
            ? adornment
            : addAdornmentAction(adornment, control.value, control.onValueChanged, adornmentType || 'ClearInput')
        }
        p="0px 12px"
      >
        {icon && (
          <Box width="20px" mr="6px">
            {icon}
          </Box>
        )}
        <StyledInput {...control} {...props} />
      </InputAdornment>
    </InputContainer>
  )
}

const StyledPasteButton = styled(Button)`
  width: 40px;
  font-size: 14px;
  font-weight: 600;
  height: fit-content;

  z-index: ${({ theme: { zIndices } }) => zIndices.modal};
`

export default FormInput
