// static UI
import Box from 'UIKit/Box/Box'
import Text from 'UIKit/Text/Text'
import { useCountDown } from 'hooks/useCountdown'
import React from 'react'
import styled from 'styled-components'
import { FlexProps } from 'styled-system'

interface WaitingDepositBonusCountdownProps {
  expiryDate: number
  color?: string
  onBonusExpired: () => void
}

const WaitingDepositBonusCountdown: React.FC<WaitingDepositBonusCountdownProps & FlexProps> = ({
  expiryDate,
  onBonusExpired,
  color,
  ...props
}) => {
  const shudownTimeTextRef = useCountDown(
    expiryDate,
    (s, m, h, d) => {
      return `<div>${d.toString().padStart(2, '0')}</div><span>:</span> <div>${h
        .toString()
        .padStart(2, '0')}</div> <span>:</span> <div>${m.toString().padStart(2, '0')}</div> <span>:</span> <div>${s
        .toString()
        .padStart(2, '0')}</div>`
    },
    () => {
      onBonusExpired()
    },
  )

  return (
    <StyledTimeoutBonus>
      <StyledCountdownContent ref={shudownTimeTextRef} {...props} color={color} />
    </StyledTimeoutBonus>
  )
}

const StyledTimeoutBonus = styled(Box)`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledCountdownContent = styled(Text)`
  display: flex;
  font-size: 10px;
  font-weight: bold;

  .expired {
    color: ${({ theme }) => theme.colors.error};
    width: 100%;
  }
  div {
    width: 15px;
    text-align: center;
  }

  span {
    color: ${({ theme: { colors } }) => colors.textAlt1};
    width: 10px;
    text-align: center;
  }
`

export default WaitingDepositBonusCountdown
