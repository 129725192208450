import { BoxProps } from 'UIKit/Box/types'
import Text from 'UIKit/Text'
import { PaymentMethodEnum } from 'config/constants/payment'
import { Token } from 'config/types'
import useModal from 'hooks/useModal'
import { RowMiddle } from 'layout/Components/Row'
import React from 'react'
import { Trans } from 'react-i18next'
import { Icons } from 'svgs'
import theme from 'theme'
import { shortedAddress } from 'utils'
import ContractInfoModal from './ContractInfoModal'

const ContractInfo: React.FC<BoxProps & { token: Token; from?: PaymentMethodEnum }> = ({ token, from, ...props }) => {
  const [onPresent] = useModal(ContractInfoModal)

  return (
    PaymentMethodEnum[from] && (
      <RowMiddle
        px="8px"
        style={{
          cursor: 'pointer',
        }}
        width="fit-content"
        onClick={() => {
          if (token?.address) {
            onPresent({
              token,
              from,
            })
          }
        }}
        {...props}
      >
        <Text as="li" color="textSubtle" small>
          <Trans>Contract Information</Trans>
        </Text>

        <Text color="textSubtle" bold mx="4px" small>
          {shortedAddress(token.address, 3, 4)}
        </Text>
        <Icons.InfoIcon width="16px" heigh="16px" fill={theme.colors.textSubtle} />
      </RowMiddle>
    )
  )
}

export default ContractInfo
