import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import InputAdornment from 'UIKit/Input/InputAdornment'
import RemoveValueInputAdornment from 'UIKit/Input/InputAdornment/RemoveValueInputAdornment'
import { InputContainer, StyledInput as Input } from 'UIKit/Input/styled'
import { InputValidator, ValidationError } from 'config/types/validator'
import React from 'react'
import styled from 'styled-components'
import { Icons } from 'svgs'

interface SearchProps extends BoxProps {
  value?: string
  validators?: InputValidator[]
  onErrorChanged?: (errors: ValidationError[]) => void
  onValueChanged?: (value: string) => void
  placeholder?: string
  disabled?: boolean
  autoFocus?: boolean
  hideAdornment?: boolean
}

const Search: React.FC<SearchProps> = ({
  value,
  placeholder,
  disabled,
  onValueChanged,
  autoFocus,
  onErrorChanged,
  validators,
  hideAdornment,
  id,
  title = 'search',
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <InputAdornment
        id={id}
        p="0px 12px"
        height="100%"
        endAdornment={
          hideAdornment ? null : <RemoveValueInputAdornment onRemove={() => onValueChanged('')} value={value} />
        }
      >
        <Box width="20px" height="20px">
          <Icons.SearchIcon />
        </Box>
        <StyledInput
          title={title}
          onValueChanged={onValueChanged}
          value={value}
          autoFocus={autoFocus}
          placeholder={placeholder}
          disabled={disabled}
          onErrorChanged={onErrorChanged}
          validators={validators}
          validateOnchange
        />
      </InputAdornment>
    </Wrapper>
  )
}

const Wrapper = styled(InputContainer)`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.inputAlt};
`

const StyledInput = styled(Input)`
  height: 100%;
  width: 100%;
  padding: 0 12px;
  background-color: transparent !important;
  font-size: 14px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.inputPlaceHolder};
    font-weight: 500;
  }
`
export default Search
