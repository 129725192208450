import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Button from 'UIKit/Button'
import Avatar from 'UIKit/Image/Avatar'
import { ModalProps } from 'UIKit/Modal'
import Text from 'UIKit/Text'
import { FetchingStatus } from 'config/constants'
import { ProfileStatisticBonusInfoes, PublicUserProfile, TipStatistic } from 'config/types/profile'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useModal from 'hooks/useModal'
import { useRequest } from 'hooks/useRequest'
import { ColumnCenter } from 'layout/Components/Column'
import { RowCenter } from 'layout/Components/Row'
import { useRouter } from 'next/router'
import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ProfileService from 'services/ProfileService'
import { useTier } from 'state/app/hooks'
import { useAuth, useUserInfo } from 'state/auth/hooks'
import styled from 'styled-components'
import { buildPluralizeText, revalidateHunnyLevel } from 'utils'
import Payment from 'views/Payment'
import { PaymentPageEnums } from 'views/Payment/Menu'
import ActivitiesStatistics from '../Dashboard/components/ActivitiesStatistics'
import Bonuses from '../Dashboard/components/Bonuses'
import PlayedGames from '../Dashboard/components/PlayedGames'
import Statistics from '../Dashboard/components/Statistics'
import { TransactionWrapperModal } from '../Transactions/components/styled'

type UserDetails = PublicUserProfile & {
  totalWager?: number
  totalBet?: number
  bonusesInfo?: ProfileStatisticBonusInfoes
  tipStatistics?: TipStatistic
}

const UserProfileModal: React.FC<
  ModalProps<{
    code: string
  }>
> = ({ data, onDismiss }) => {
  const router = useRouter()
  const { code } = data
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null)
  const [fetchingStatus, setFetchingStatus] = useState<FetchingStatus>(FetchingStatus.Fetching)
  const { displayName } = useUserInfo()
  const { isMobile } = useMatchBreakpoints()
  const tier = useTier(userDetails?.levelId)
  const { execute } = useRequest()
  const { t } = useTranslation()
  const [onPresentModalPayment] = useModal(Payment)

  const { hasSession } = useAuth()

  useLayoutEffect(() => {
    const fetch = async () => {
      setFetchingStatus(FetchingStatus.Fetching)
      const result = await execute(ProfileService.getUserProfile(code))
      if (result?.code === 'success') {
        setUserDetails(result?.data)
        setFetchingStatus(FetchingStatus.Fetched)
        return
      }
      setFetchingStatus(FetchingStatus.Failed)
    }

    fetch()
  }, [])

  useEffect(() => {
    return () => {
      onDismiss()
    }
  }, [router])

  const userAgeInMonths = useMemo(
    () =>
      userDetails
        ? Math.floor(0 + (Date.now() - userDetails.registerDate.getTime()) / (1000 * 60 * 60 * 24 * 30))
        : null,
    [userDetails],
  )

  return (
    <TransactionWrapperModal
      title=""
      onDismiss={onDismiss}
      p="24px 0px"
      minWidth={['', '', '408px !important']}
      maxWidth={['', '', '1000px !important']}
    >
      <ColumnCenter
        maxHeight={['calc(var(--screen-height) * 0.8)', '', '', '700px']}
        overflowY="scroll"
        p={isMobile ? '0px 24px 24px 24px' : '0px 24px'}
      >
        <RowCenter width="100%">
          <StyledInfoUser
            mt="24px"
            width={isMobile ? '100%' : userDetails?.settings?.isHideStatistic ? '360px' : '480px'}
          >
            <ColumnCenter position="relative" width="100%">
              <Avatar
                src={userDetails?.avatar}
                width={80}
                height={80}
                style={!userDetails?.avatar && { minWidth: '80px' }}
              />
              <StyledLevel>VIP {String(revalidateHunnyLevel(tier?.level))}</StyledLevel>
            </ColumnCenter>
            <Text fontWeight={[600]} fontSize={['14px', '', '', '16px']} mt="22px">
              {userDetails?.displayName}
            </Text>
            {!userDetails?.settings?.isHideStatistic && (
              <Text fontWeight={400} fontSize="12px" mt="6px" color="textSubtle">
                {(!!userAgeInMonths || userAgeInMonths === 0) && (
                  <>
                    {userAgeInMonths < 1 && <Trans>Less than 1 month on the platform</Trans>}

                    {userAgeInMonths >= 1 && userAgeInMonths <= 11 && (
                      <>
                        {t(
                          buildPluralizeText('{{month}} {{monthUnit}} on the platform', [
                            { number: userAgeInMonths, key: 'monthUnit', word: ['month', 'months'] },
                          ]),
                          { month: userAgeInMonths },
                        )}
                      </>
                    )}

                    {userAgeInMonths > 11 &&
                      (userAgeInMonths % 12 === 0
                        ? t(
                            buildPluralizeText('{{year}} {{yearUnit}}', [
                              {
                                number: userAgeInMonths / 12,
                                key: 'yearUnit',
                                word: ['year', 'years'],
                              },
                            ]),
                            { year: userAgeInMonths / 12 },
                          )
                        : t(
                            buildPluralizeText('{{year}} {{yearUnit}} {{month}} {{monthUnit}}', [
                              {
                                number: Math.floor(userAgeInMonths / 12),
                                key: 'yearUnit',
                                word: ['year', 'years'],
                              },
                              {
                                number: userAgeInMonths % 12,
                                key: 'monthUnit',
                                word: ['month', 'months'],
                              },
                            ]),
                            { year: Math.floor(userAgeInMonths / 12), month: userAgeInMonths % 12 },
                          ))}
                  </>
                )}
              </Text>
            )}
          </StyledInfoUser>
        </RowCenter>

        {userDetails?.settings?.isHideStatistic ? (
          <ColumnCenter my="24px">
            <Text fontSize={['12px', '', '14px']} color="textTertiary" mt="12px" textAlign="center">
              <Trans>Statistics have been hidden because of this user's setting</Trans>
            </Text>
          </ColumnCenter>
        ) : (
          <Box my="24px" width="100%">
            <StyledLayout isMobile={isMobile}>
              <StyledDetail width={['100%', '100%', '350px', '350px']}>
                <Statistics
                  bigWinGameInfo={userDetails?.bigWinGameInfo}
                  luckyWinGameInfo={userDetails?.luckyWinGameInfo}
                  score={Number(userDetails?.totalWager ?? 0)}
                  totalBet={Number(userDetails?.totalBet ?? 0)}
                  isStatisticTagColumn
                />
              </StyledDetail>
              <StyledDetail
                width={['100%', '100%', '350px', '350px']}
                ml={['0px', '0px', '20px', '20px']}
                mt={['24px', '24px', '0px', '0px']}
              >
                <PlayedGames
                  isOtherUser={displayName !== userDetails?.displayName}
                  playedGameCount={Number(userDetails?.playedGameCount ?? 0)}
                  mostWageredGameInfoes={userDetails?.mostWageredGameInfoes}
                  mostWinGameInfoes={userDetails?.mostWinGameInfoes}
                />
              </StyledDetail>
            </StyledLayout>
            <StyledLayout mt="20px" width="100%" isMobile={isMobile}>
              <StyledDetail width={['100%', '100%', '350px', '350px']}>
                <Bonuses bonusInfoes={userDetails?.bonusesInfo} />
              </StyledDetail>
              <StyledDetail
                ml={['0px', '0px', '20px', '20px']}
                width={['100%', '100%', '350px', '350px']}
                mt={['24px', '24px', '0px', '0px']}
              >
                <ActivitiesStatistics
                  userSentTipAmount={userDetails?.tipStatistics.sendAmountInUsd}
                  userReceivedTipAmount={userDetails?.tipStatistics.receiveAmountInUsd}
                />
              </StyledDetail>
            </StyledLayout>
          </Box>
        )}

        {hasSession && fetchingStatus === FetchingStatus.Fetched && userDetails?.displayName !== displayName && (
          <Button
            width={['100%', '100%', '360px', '360px']}
            mb="10px"
            minHeight="40px"
            onClick={() =>
              onPresentModalPayment({
                defaultPage: PaymentPageEnums.Tip,
                pageData: { tipUserName: userDetails.displayName },
              })
            }
          >
            <Text fontSize="14px" fontWeight="600">
              Tip
            </Text>
          </Button>
        )}
      </ColumnCenter>
    </TransactionWrapperModal>
  )
}

const StyledInfoUser = styled(Flex)`
  background: ${({ theme }) => theme.colors.backgroundAlt7};
  padding: 20px 12px;
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
`

const StyledLayout = styled(Flex)<{ isMobile: boolean }>`
  justify-content: space-between;
  flex: 1;
  align-items: center;
  position: relative;
  align-self: stretch;

  ${(props) =>
    props.isMobile &&
    `
    flex-direction: column;
    alignItem: center;
  `}
`

const StyledDetail = styled(Box)`
  border: ${({ theme: { colors } }) => `1px solid ${colors.modalHeaderBackground}`};
  border-radius: 12px;
  padding: 20px;
  align-self: stretch;
  align-items: stretch;
  flex: 1;
`

const StyledLevel = styled(Box)`
  position: absolute;
  border-radius: 40px;
  bottom: -10px;
  border: 2px solid var(--dark-dark-2, #1d2533);
  background: linear-gradient(250deg, #f953c6 -15.74%, #b91d73 124.92%);
  color: #fff;
  padding: 2px 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.28px;
`

export default UserProfileModal
