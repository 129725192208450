import { BoxProps } from 'UIKit/Box/types'
import HunnyTooltip from 'UIKit/Tooltip/Tooltip'
import { TokenAmount } from 'config/types'
import theme from 'theme'
import DisplayTokenAmount from '.'

type TokenAmountTooltipProps = {
  tokenAmount: TokenAmount
  tooltipId: string
} & BoxProps

const TokenAmountTooltip: React.FC<TokenAmountTooltipProps> = ({ tokenAmount, tooltipId, ...props }) => {
  return (
    <HunnyTooltip
      id={tooltipId}
      place="top"
      className="custom-tip"
      classNameArrow="arrow__display__amount__tip"
      offset={5}
      style={{
        padding: '20px',
        background: theme.colors.modalHeaderBackground,
        borderRadius: theme.radii.small,
        border: `1px solid ${theme.colors.modalHeaderBackground}`,
        zIndex: 1000,
      }}
    >
      <DisplayTokenAmount
        tokenAmount={tokenAmount}
        textStyle={{ color: 'text', fontSize: '14px', fontWeight: '400' }}
        p="4px 12px"
        {...props}
      />
    </HunnyTooltip>
  )
}
export default TokenAmountTooltip
