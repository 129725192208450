import Box from 'UIKit/Box/Box'
import { RewardTypeEnum } from 'config/types/bonus/DailyReward'
import styled from 'styled-components'
import BonusRewardThumbnail from './BonusRewardThumbnail'
import CashRewardThumbnail from './CashRewardThumbnail'
import HUSDLockRewardThumbnail from './HUSDLockRewardThumbnail'

const RewardThumbnail = ({
  rewardDetail,
  isClaimed,
  size,
}: {
  rewardDetail: any
  isClaimed?: boolean
  size?: number
}) => {
  return (
    <StyledRewardThumbnail isClaimed={isClaimed}>
      {rewardDetail.rewardType === RewardTypeEnum.Bonus ? (
        <BonusRewardThumbnail bonusImage={rewardDetail.reward.bonusImage} size={size} />
      ) : rewardDetail.rewardType === RewardTypeEnum.HUSDLock ? (
        <HUSDLockRewardThumbnail size={size} />
      ) : (
        <CashRewardThumbnail token={rewardDetail.reward.token} size={size} />
      )}
    </StyledRewardThumbnail>
  )
}

const StyledRewardThumbnail = styled(Box)<{ isClaimed: boolean }>`
  -webkit-filter: ${({ isClaimed }) => (isClaimed ? 'grayscale(100%)' : 'unset')};
  filter: ${({ isClaimed }) => (isClaimed ? 'grayscale(100%)' : 'unset')};
`
export default RewardThumbnail
